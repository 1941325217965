import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AutoUnsubscribe } from '@nx-bundesliga/ngx-auto-unsubscribe-decorator';
import { combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { Competition, Language } from '@nx-bundesliga/models';
import { getWorkingCompetition, getWorkingLanguage } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';

@Component({
	selector: 'FooterComponent',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	language = 'de';
	competition = 'bundesliga';
	public footerPartnerlogoJson: any;
	public footerNavigationJson: any;
	public footerSocialsJson: any;
	public footerPartnerlogo: any;
	public footerNavigation: any;
	public footerSocials: any;
	public footerAppLinkOutApp = 'https://buli.page.link/qaf';
	public footerAppLinkOutFantasy = 'https://blf.page.link/footerblcom';
	@AutoUnsubscribe() private subscription: Subscription;

	constructor(private readonly cstore: Store<Competition>, private readonly lstore: Store<Language>, private readonly config: ConfigService) {
		this.footerPartnerlogoJson = this.config.getSettings('footerPartnerlogo');
		this.footerNavigationJson = this.config.getSettings('footerNavigation');
		this.footerSocialsJson = this.config.getSettings('footerSocials');
	}

	ngOnInit() {
		this.subscription = combineLatest([
			this.lstore.pipe(
				select(getWorkingLanguage),
				filter((lang: Language) => lang.code !== '')
			),
			this.cstore.pipe(select(getWorkingCompetition))
		])
			.pipe(debounceTime(0), distinctUntilChanged())
			.subscribe(([lang, comp]: [Language, Competition]) => {
				this.language = lang.code;
				this.competition = comp.name;
				this.footerNavigation = this.footerNavigationJson[this.language][this.competition];
				this.footerPartnerlogo = this.footerPartnerlogoJson[this.language];
				this.footerSocials = this.footerSocialsJson[this.language];
				if (this.language === 'de') {
					this.footerAppLinkOutApp = 'https://buli.page.link/qaf-de';
				}
			});
	}
}
