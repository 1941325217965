import { Inject, Injectable, Optional } from '@angular/core';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';

export interface IServerResponseService {
	getHeader(key: string): string;
	setHeader(key: string, value: string): this;
	setHeaders(dictionary: { [key: string]: string }): this;
	appendHeader(key: string, value: string, delimiter?: string): this;
	setStatus(code: number, message?: string): this;
	setNotFound(message?: string): this;
	setError(message?: string): this;
}

@Injectable()
export class ServerResponseService implements IServerResponseService {
	private response: Response;

	constructor(@Optional() @Inject(RESPONSE) response: any) {
		this.response = response;
	}

	getHeader(key: string): string {
		return this.response.getHeader(key) as string;
	}

	setHeader(key: string, value: string): this {
		if (this.response) {
			this.response.header(key, value);
		}
		return this;
	}

	appendHeader(key: string, value: string, delimiter = ','): this {
		if (this.response) {
			const current = this.getHeader(key);
			if (!current) {
				return this.setHeader(key, value);
			}

			const newValue = [...current.split(delimiter), value].filter((el, i, a) => i === a.indexOf(el)).join(delimiter);

			this.response.header(key, newValue);
		}
		return this;
	}

	setHeaders(dictionary: { [key: string]: string }): this {
		if (this.response) {
			Object.keys(dictionary).forEach((key) => this.setHeader(key, dictionary[key]));
		}
		return this;
	}

	setStatus(code: number, message?: string): this {
		if (this.response) {
			this.response.statusCode = code;
			if (message) {
				this.response.statusMessage = message;
			}
		}
		return this;
	}

	setNotFound(message = 'not found'): this {
		if (this.response) {
			this.response.statusCode = 404;
			this.response.statusMessage = message;
		}
		return this;
	}

	setUnauthorized(message = 'Unauthorized'): this {
		if (this.response) {
			this.response.statusCode = 401;
			this.response.statusMessage = message;
		}
		return this;
	}

	setCachePrivate(): this {
		if (this.response) {
			this.setCache('private');
		}
		return this;
	}

	setCacheNone(): this {
		if (this.response) {
			this.setHeader('Cache-Control', 'no-cache, no-store, must-revalidate');
			this.setHeader('Pragma', 'no-cache');
		}
		return this;
	}

	setCache(directive: HttpCacheDirective, maxAge?: string, smaxAge?: string): this {
		if (this.response) {
			// eslint-disable-next-line max-len
			if (smaxAge) {
				this.setHeader('Cache-Control', `${directive}, max-age=${maxAge ? ms(maxAge) / 1000 : 0}, s-maxage=${ms(smaxAge) / 1000}`);
			} else {
				this.setHeader('Cache-Control', `${directive}, max-age=${maxAge ? ms(maxAge) / 1000 : 0}`);
			}

			this.setHeader('Expires', maxAge ? new Date(Date.now() + ms(maxAge)).toUTCString() : new Date(Date.now()).toUTCString());
		}
		return this;
	}

	setError(message = 'internal server error'): this {
		if (this.response) {
			this.response.statusCode = 500;
			this.response.statusMessage = message;
		}
		return this;
	}
}

export type HttpCacheDirective = 'public' | 'private' | 'no-store' | 'no-cache' | 'must-revalidate' | 'no-transform' | 'proxy-revalidate';

// SOURCE: https://github.com/zeit/ms/blob/master/index.js
/**
 * Parse or format the given `val`.
 *
 * @param {String|Number} val
 * @throws {Error} throw an error if val is not a non-empty string or a number
 * @return {String|Number}
 * @api public
 */
export function ms(val: string): number {
	/**
	 * Helpers.
	 */
	const s = 1000;
	const m = s * 60;
	const h = m * 60;
	const d = h * 24;
	const w = d * 7;
	const y = d * 365.25;

	/**
	 * Parse the given `str` and return milliseconds.
	 *
	 * @param {String} str
	 * @return {Number}
	 * @api private
	 */

	function parse(str) {
		str = String(str);
		if (str.length > 100) {
			return;
		}
		const match = /^((?:\d+)?-?\d?\.?\d+) *(milliseconds?|msecs?|ms|seconds?|secs?|s|minutes?|mins?|m|hours?|hrs?|h|days?|d|weeks?|w|years?|yrs?|y)?$/i.exec(str);
		if (!match) {
			return;
		}
		const n = parseFloat(match[1]);
		const type = (match[2] || 'ms').toLowerCase();
		switch (type) {
			case 'years':
			case 'year':
			case 'yrs':
			case 'yr':
			case 'y':
				return n * y;
			case 'weeks':
			case 'week':
			case 'w':
				return n * w;
			case 'days':
			case 'day':
			case 'd':
				return n * d;
			case 'hours':
			case 'hour':
			case 'hrs':
			case 'hr':
			case 'h':
				return n * h;
			case 'minutes':
			case 'minute':
			case 'mins':
			case 'min':
			case 'm':
				return n * m;
			case 'seconds':
			case 'second':
			case 'secs':
			case 'sec':
			case 's':
				return n * s;
			case 'milliseconds':
			case 'millisecond':
			case 'msecs':
			case 'msec':
			case 'ms':
				return n;
			default:
				return undefined;
		}
	}

	const type = typeof val;
	if (type === 'string' && val.length > 0) {
		return parse(val);
	}
	throw new Error('val is not a non-empty string or a valid number. val=' + JSON.stringify(val));
}
