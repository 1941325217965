import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'dfl-loader-modern',
	templateUrl: './dfl-loader-modern.component.html',
	styleUrls: ['./dfl-loader-modern.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DflLoaderModernComponent {
	@Input() loading = false;
	@Input() size: 'small' | 'medium' | 'large' = 'large';
	constructor() {}
}
