import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'bundesliga-footer-socials',
	templateUrl: './footer-socials.component.html',
	styleUrls: ['./footer-socials.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterSocialsComponent {
	@Input() language = 'de';
	@Input() competition = 'bundesliga';
	@Input() naviStructure: any;

	constructor() {}

	trackByLabel(index, item) {
		return item.label;
	}
}
