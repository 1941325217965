import { BUNDESLIGA_SEASONS, Popup } from '@nx-bundesliga/models';
import { UserSubscriptionSource } from '@nx-bundesliga/models';

/**
 * Executes a given parameter if it's a function, otherwise just returns the parameter.
 * If fn is a function and payload is set, then is it spreaded into fn (i.e. fn(...payload)).
 *
 * @param fn {Function}
 * @param payload
 * @returns {any}
 */
export function executeIfFunction(fn: Function, ...payload: any[]) {
	return typeof fn === 'function' ? fn(...(payload ? payload : [])) : fn;
}

/**
 *
 * @param cases
 * @returns {(defaultCase?: any) => (key: any) => any}
 */
function switchCaseBase(cases: any): (defaultCase?: any) => (key: any) => any {
	return (defaultCase?: any) => (key: any) => cases.hasOwnProperty(key) ? cases[key] : defaultCase;
}

/**
 *
 * @param cases
 * @returns {(defaultCase?: any) => (key: any) => any}
 */
export function switchCase(cases: any): (defaultCase?: any) => (key: any) => any {
	return (defaultCase?: any) => (key: any) => executeIfFunction(switchCaseBase(cases)(defaultCase)(key));
}

/**
 * Opens the given URL as popup. Should never be called on the server, obviously.
 *
 * @param {Popup} p has width, height, title and url as props. Only title and url are required.
 */
export function openAsPopup(p: Popup) {
	const features = [];
	if (p.width) {
		features.push(`width=${p.width}`);
	}

	if (p.height) {
		features.push(`height=${p.height}`);
	}

	window.open(p.url, p.title, features.join(','));
}

/**
 * Transforms a ISO-369-1 language code to a ISO-3166 code.
 * That is "en" to "en_US".
 *
 * @param {string} language ISO-369-1
 * @returns {string}
 */
export function iso369to3166(language: string): string {
	switch (language) {
		case 'de':
			return 'de_DE';
		case 'es':
			return 'es_ES';
		case 'fr':
			return 'fr_FR';
		case 'ja':
			return 'ja_JP';
		case 'en':
		default:
			return 'en_US';
	}
}

/***
 * @param {string} seasonName
 * @returns {string}
 */
export function mapSeasonToDatalibraryId(seasonName: string): string {
	switch (seasonName) {
		case '2021-2022':
			return 'DFL-SEA-0001K5';
		case '2020-2021':
			return 'DFL-SEA-0001K4';
		case '2019-2020':
			return 'DFL-SEA-0001K3';
		case '2018-2019':
			return 'DFL-SEA-0001K2';
		case '2017-2018':
			return 'DFL-SEA-0001K1';
		case '2016-2017':
			return 'DFL-SEA-0001K0';
	}

	return getCurrentDatalibrarySeasonId();
}

/**
 * @todo/dfl should get the current season from config node in firebase
 * @returns {string}
 */
export function getCurrentDatalibrarySeasonId(): string {
	return BUNDESLIGA_SEASONS.CURRENT as string;
}

/**
 * Returns true only if n is of type number and greater than zero.
 *
 * @param n:number
 */
export function isNotNull(n: number): boolean {
	return !isNaN(n) && n > 0;
}

/**
 * Returns a compare function of type "(any, any) => number" over key.
 *
 * @param key
 */
export function getCompareFn(key: string): (a: {}, b: {}) => number {
	return (a: {}, b: {}): number => {
		if (a[key] < b[key]) {
			return -1;
		}

		if (a[key] > b[key]) {
			return 1;
		}

		return 0;
	};
}

/*
export function waitUntilAvailable(property) {
  let interval, count = 0;

  return new Promise<any>((resolve, reject) => {
    interval = setInterval(() => {
      if (count++ >= 100) {
        clearInterval(interval);
        reject(`request to load ${property} has timed out !!`);
      }

      if (isWindowDefined() && window.hasOwnProperty(property)) {
        clearInterval(interval);
        resolve(window[property]);
      }
    }, 100);
  });
}
*/

/**
 * Decodes the article slug for a given EditorialArticle.
 * Required by WEB-811 to display kanji characters in the url bar, because CIS is encoding all values and neither
 * API nor CIS teams have time/budget to change this.
 *
 * @param article
 */
export function decodeArticleSlug(article) {
	if (article.type !== 'custom_tile') {
		article.slug = decodeURIComponent(article.slug);
	}

	return article;
}

/**
 * provides a isoDate by timestamp in seconds
 *
 * @param {number} timestamp in seconds
 */
export function getISO8601DateFromTimestamp(timestampInSeconds: number): string {
	const date = new Date(timestampInSeconds * 1000);
	// Get ISO 8601 formatted date and time
	const isoDateTime = date.toISOString();
	return isoDateTime;
}

/**
 * provides a ISO8601 duration
 * @param {number} seconds
 */
export function getDurationISO8601(seconds: number): string {
	const h = Math.floor(seconds / 3600);
	const m = Math.floor((seconds % 3600) / 60);
	const s = Math.floor((seconds % 3600) % 60);
	let durationISO8601 = 'PT';
	if (h) {
		durationISO8601 += h + 'H';
	}
	if (m) {
		durationISO8601 += m + 'M';
	}
	if ((!h && !m) || s) {
		durationISO8601 += s + 'S';
	}
	return durationISO8601;
}

/**
 * provides the subscription source
 * @param {string} product
 */
export function subscriptionSourceFromProduct(product: string, isProfile = false, isBarfinder = false): string {
	switch (product) {
		case 'fantasy':
			return isProfile ? UserSubscriptionSource.FANTASY_APP_OKTA_PROFILE : UserSubscriptionSource.FANTASY_APP_OKTA_SIGNUP;
		case 'bundesliga-app':
			return isProfile ? UserSubscriptionSource.BL_APP_OKTA_PROFILE : UserSubscriptionSource.BL_APP_OKTA_SIGNUP;
		default:
			'web';
			return isProfile ? UserSubscriptionSource.BL_OKTA_PROFILE : isBarfinder ? UserSubscriptionSource.BL_BARFINDER : UserSubscriptionSource.BL_OKTA_SIGNUP;
	}
}
