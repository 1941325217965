// module
import { Action, createReducer, on } from '@ngrx/store';
import { clubInit, getClubs, getClubsError, getClubsSuccess } from '@nx-bundesliga/bundesliga-com/framework/store-actions';
import { ClubState, initialAppstateClub } from '@nx-bundesliga/models';

const featureReducer = createReducer(
	initialAppstateClub,
	on(clubInit, (state) => initialAppstateClub),
	on(getClubs, (state, payload) =>
		state.hasOwnProperty(payload.season)
			? state
			: Object.assign(
					{},
					{
						...state,
						[payload.season]: {
							loaded: false,
							loading: true,
							error: null,
							data: {
								'bundesliga': [],
								'2bundesliga': []
							}
						}
					}
			  )
	),
	on(getClubsSuccess, (state, payload) =>
		Object.assign(
			{},
			{
				...state,
				[payload.season]: { loaded: true, loading: false, error: null, data: payload.clubs }
			}
		)
	),
	on(getClubsError, (state, payload) =>
		Object.assign(
			{},
			{
				...state,
				[payload.season]: {
					data: {
						'bundesliga': [],
						'2bundesliga': []
					},
					loaded: false,
					loading: false,
					error: payload.errorMessage
				}
			}
		)
	)
);

export function reducer(state: ClubState | undefined, action: Action) {
	return featureReducer(state, action);
}
