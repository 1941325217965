import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Language } from '@nx-bundesliga/models';
import { getWorkingLanguage } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { WINDOW } from '@nx-bundesliga/bundesliga-com/framework/window';

export interface ConsentCategories {
	C0001: boolean;
	C0002: boolean;
	C0003: boolean;
	C0004: boolean;
	C0005: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class CookieConsentService {
	public consentCategoriesDefault: ConsentCategories = {
		'C0001': true,
		'C0002': false,
		'C0003': false,
		'C0004': false,
		'C0005': false
	};
	private consentCategories: ConsentCategories = this.consentCategoriesDefault;
	private language: string;
	private isBrowser: boolean;
	public consents$: BehaviorSubject<ConsentCategories> = new BehaviorSubject<ConsentCategories>(this.consentCategories);

	constructor(@Inject(PLATFORM_ID) platformId: Object, private readonly lstore: Store<Language>, @Inject(DOCUMENT) private document, @Inject(WINDOW) private window, private configService: ConfigService) {
		this.isBrowser = isPlatformBrowser(platformId);
		this.lstore
			.pipe(
				select(getWorkingLanguage),
				filter((lang: Language) => lang.code !== '')
			)
			.subscribe((langstate: Language) => {
				this.language = langstate.code;
			});
		this.Init();
	}

	Init(): void {
		if (this.isBrowser) {
			this.registerConsentChanged();
			const cookie = this.getCookie('OptanonConsent');
			if (this.getCookie('OptanonConsent')) {
				// cookie usually looks like this
				// "isIABGlobal=false&datestamp=Mon+Mar+23+2020+11:34:48+GMT+0100+(Central+European+Standard+Time)&version=5.13.0&landingPath=NotLandingPage&groups=C0001:1,C0003:1,C0002:0,C0004:1&hosts=&geolocation="
				const consentCookie = cookie.split('&').reduce((acc, cur) => {
					const keyValuePair = cur.split('=');
					acc[keyValuePair[0]] = keyValuePair[1] || null;
					return acc;
				}, {});
				const categories = consentCookie.hasOwnProperty('groups') && consentCookie['groups'] && consentCookie['groups'].length > 0 ? consentCookie['groups'].split(',') : [];
				categories.forEach((catItem) => {
					const catItemConsented = catItem.split(':');
					this.consentCategories[catItemConsented[0]] = catItemConsented[1] === '1';
				});
			}
			this.consents$.next(this.consentCategories);
		}
	}

	private getCookie(cname) {
		const name = cname + '=';
		const allPageCookies = this.document.cookie;
		const ca = allPageCookies.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = decodeURIComponent(ca[i]);
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return null;
	}

	public registerConsentChanged() {
		if (this.isBrowser) {
			window.addEventListener('consent.onetrust', (event) => this.consentChanged(event));
		}
	}
	public consentChanged(consentData: any) {
		if (consentData.detail) {
			Object.keys(this.consentCategories).forEach((cat) => {
				this.consentCategories[cat] = !!consentData.detail.includes(cat);
			});
			this.consents$.next(this.consentCategories);
		}
	}
}
