export type MatchAssociation = 'home' | 'away' | 'neutral';
export type XCardTypes = 'summary' | 'summary_large_image';
export type BookingType = 'yellow' | 'yellowred' | 'red' | 'verbal';
export type MatchStatTypes = 'goalshot' | 'distance';

export type TYPE_BUNDESLIGA_COMPETITIONS = 'DFL-COM-000001' | 'DFL-COM-000002' | 'DFL-COM-000003' | 'DFL-COM-000004' | 'DFL-COM-000005';
export type TYPE_BUNDESLIGA_SEASONS = 'DFL-SEA-0001K1' | 'DFL-SEA-0001K2' | 'DFL-SEA-0001K3' | 'DFL-SEA-0001K4' | 'DFL-SEA-0001K5' | 'DFL-SEA-0001K6' | 'DFL-SEA-0001K7' | 'DFL-SEA-0001K8';

export enum BUNDESLIGA_COMPETITIONS {
	BUNDESLIGA = 'DFL-COM-000001',
	BUNDESLIGA2 = 'DFL-COM-000002',
	BUNDESLIGA_RELEGATION = 'DFL-COM-000004',
	BUNDESLIGA2_RELEGATION = 'DFL-COM-000005',
	SUPERCUP = 'DFL-COM-000003'
}

export enum NON_BUNDESLIGA_COMPETITIONS {
	'EURO' = 'DFL-COM-J0002E'
}

export enum BUNDESLIGA_SEASONS {
	S1718 = 'DFL-SEA-0001K1',
	S1819 = 'DFL-SEA-0001K2',
	S1920 = 'DFL-SEA-0001K3',
	S2021 = 'DFL-SEA-0001K4',
	S2122 = 'DFL-SEA-0001K5',
	S2223 = 'DFL-SEA-0001K6',
	S2324 = 'DFL-SEA-0001K7',
	S2425 = 'DFL-SEA-0001K8',
	S2526 = 'DFL-SEA-0001K9',
	PREVIOUS = 'DFL-SEA-0001K7', // Todo: This is temporary and needs to be adjusted AFTER season switch
	CURRENT = 'DFL-SEA-0001K8'
}

export enum BUNDESLIGA_SEASONNAMES {
	SEA0001K1 = '2017-2018',
	SEA0001K2 = '2018-2019',
	SEA0001K3 = '2019-2020',
	SEA0001K4 = '2020-2021',
	SEA0001K5 = '2021-2022',
	SEA0001K6 = '2022-2023',
	SEA0001K7 = '2023-2024',
	SEA0001K8 = '2024-2025',
	PREVIOUS = '2023-2024', // Todo: This is temporary and needs to be adjusted AFTER season switch
	CURRENT = '2024-2025'
}

export interface Playtime {
	minute: number;
	injuryTime?: number;
}

export interface Logo {
	src: string;
	width?: number;
	height?: number;
}

export interface OpenGraphTags {
	title: string;
	url: string;
	description: string;
	image: string;
}

export interface XCardTags {
	site: string;
	card: XCardTypes;
	creator: string;
	description: string;
	image: string;

	url?: string; // api is currently not returning this field. bug? use openGraph url instead for the time being.
	title?: string;
}

export interface MetaData {
	title: string;
	description: string;

	canonical?: string;
	keywords?: string[];
}

export interface EditorialMetaData extends MetaData {
	openGraph?: OpenGraphTags;
	x?: XCardTags;
}

export interface BundesligaEmbedPlaceholder {
	html?: string;
	text?: string;
	author?: string;
}

export type EmbedPlatforms = 'apester' | 'facebook' | 'youtube' | 'instagram' | 'solive' | 'twitter' | 'x';

/**
 * replaced BundesligaEmbed above
 */
export interface BundesligaEmbed {
	// not set in liveticker events, because its available as entryType on LiveBlogEntryEditorialEmbed
	type?: 'embed';

	embedId?: string;
	embedPlatform: EmbedPlatforms;
	embedUrl?: string;
	snipped?: string;
	url?: string;
	embedType?: string;
	headline?: string;
	text?: string;
	autoplay?: boolean | 'true' | 'false';
	placeholder?: BundesligaEmbedPlaceholder;
	theme?: string; // only used by scribble live and x
}

/**
 * Minimal interface to open url's as popup via utils.openAsPopup
 */
export interface Popup {
	url: string;
	title: string;
	description?: string;
	width?: number;
	height?: number;
}

export interface LanguageStrings {
	en: string;
	de?: string;
	es?: string;
	jp?: string;
}

export interface PagedApiResponse<T> {
	currentPageNumber: number;
	elementsOnPage: number;
	elementsPerPage: number;
	totalElementCount: number;
	totalPageCount: number;

	elements: T[];
}
