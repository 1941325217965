import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { getProfile, login, logout, logoutSilent, renewToken, setProfile, setProfileError } from '@nx-bundesliga/bundesliga-com/framework/store-actions';
import { getWorkingProfile } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { ProfileAccount, ProfileNewsletter, ProfileState } from '@nx-bundesliga/models';
import { forkJoin, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { OktaApiService } from '@nx-bundesliga/bundesliga-com/services/okta-api';
import { OktaAuthService, OktaAuthGuard } from '@nx-bundesliga/bundesliga-com/services/okta-auth';
import { Action, select, Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class ProfileEffects {
	constructor(private actions$: Actions, private store$: Store<ProfileState>, private activatedRoute: ActivatedRoute, private oktaAuthGuard: OktaAuthGuard, private oktaAuth: OktaAuthService, private oktaApi: OktaApiService) {}
	public login$ = createEffect(() =>
		this.actions$.pipe(
			ofType(login),
			withLatestFrom(this.store$.pipe(select(getWorkingProfile))),
			map(([action, profile]: [Action, ProfileAccount]) =>
				getProfile({
					profile: true,
					newsletter: profile.registrationComplete
				})
			)
		)
	);

	public getProfile$ = createEffect(() =>
		this.actions$.pipe(
			ofType(getProfile),
			withLatestFrom(this.store$.pipe(select(getWorkingProfile))),
			filter(([action, profile]: [Action, ProfileAccount]) => profile.authorized === true),
			switchMap(([action, profile]: [Action, ProfileAccount]) => {
				return forkJoin([action['payload']['profile'] === true ? this.oktaAuth.getProfileInfo() : of(false), action['payload']['newsletter'] === true && profile.registrationComplete && profile.isVerified ? this.oktaApi.getNewsletters().pipe(catchError((err) => of(false))) : of(false)]).pipe(
					map(([fetchedProfile, fetchedNewsletters]: [any, ProfileNewsletter]) => {
						return setProfile({
							...(fetchedProfile && {
								user: {
									uid: fetchedProfile?.uid,
									email: fetchedProfile?.email,
									login: fetchedProfile?.login,
									firstName: fetchedProfile?.firstName,
									lastName: fetchedProfile?.lastName,
									country: fetchedProfile?.countryCode,
									gender: fetchedProfile?.DFL_gender,
									birthDate: fetchedProfile?.DFL_birthdate,
									locale: fetchedProfile?.locale,
									favorite: {
										teams: fetchedProfile?.DFL_favoriteTeams,
										players: []
									},
									crossAccountProfileOptout: fetchedProfile?.DFL_crossProductProfileBuildingOptout
								}
							}),
							...(fetchedNewsletters && { newsletter: fetchedNewsletters }),
							isVerified: !!fetchedProfile?.DFL_isVerified,
							registrationComplete: !!fetchedProfile?.DFL_registrationComplete
						});
					}),
					catchError((err) => {
						if (err?.error === 'access token is not active') {
							return of(logoutSilent());
						}
						return of(setProfileError({ error: err?.message || 'ERROR' }));
					})
				);
			})
		)
	);

	public logout$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(logout),
				tap(() => {
					this.oktaAuth.logout();
				})
			),
		{ dispatch: false }
	);

	public renewToken$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(renewToken),
				switchMap(() => this.oktaAuth.renewToken())
			),
		{ dispatch: false }
	);

	public logoutSilent$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(logoutSilent),
				tap(() => {
					this.oktaAuth.logoutSilent();
				}),
				switchMap(() => {
					let accountRoute = this.activatedRoute.snapshot;
					while (accountRoute.firstChild && accountRoute?.parent?.routeConfig?.path !== 'account') {
						accountRoute = accountRoute.firstChild;
					}
					// do not run routerguard if not on profile route
					if (accountRoute?.parent?.routeConfig?.path !== 'account') {
						return of(true);
					}
					return this.oktaAuthGuard.canActivate(accountRoute, null);
				})
			),
		{ dispatch: false }
	);
}
