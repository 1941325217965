import { NgModule } from '@angular/core';
import { SharedModule } from '@nx-bundesliga/bundesliga-com/framework/core';
import { SwitchersModule } from '../switchers/switchers.module';
import { FooterCloserComponent } from './component/footer-closer/footer-closer.component';
import { FooterNavigationComponent } from './component/footer-navigation/footer-navigation.component';
import { FooterPartnerlogoComponent } from './component/footer-partnerlogo/footer-partnerlogo.component';
import { FooterComponent } from './component/footer/footer.component';
import { FooterSocialsComponent } from './component/footer-socials/footer-socials.component';
import { NgOptimizedImage } from '@angular/common';

@NgModule({
	imports: [SharedModule, SwitchersModule, NgOptimizedImage],
	exports: [FooterComponent, FooterNavigationComponent, FooterPartnerlogoComponent, FooterCloserComponent],
	providers: [],
	declarations: [FooterComponent, FooterNavigationComponent, FooterPartnerlogoComponent, FooterCloserComponent, FooterSocialsComponent]
})
export class FooterModule {}
