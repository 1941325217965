import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AVAILABLE_MODES, Modes, ThemingService } from '../../../services/theming-service/theming.service';

@Component({
	selector: 'theme-switcher',
	templateUrl: './theme-switcher.component.html',
	styleUrls: ['../style/switcher.component.scss']
})
export class ThemeSwitcherComponent {
	public themes = AVAILABLE_MODES;
	public currentThemingMode$: Observable<Modes>;
	constructor(private themingService: ThemingService) {
		this.currentThemingMode$ = this.themingService.mode$;
	}

	public changeTheme(e) {
		this.themingService.setMode(e.value);
	}
}
