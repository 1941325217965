import { Action, createReducer, on } from '@ngrx/store';
import { getProfile, login, logout, logoutSilent, profileInit, renewToken, setProfile, setProfileError } from '@nx-bundesliga/bundesliga-com/framework/store-actions';
import { initialAppstateProfile, ProfileState } from '@nx-bundesliga/models';

const featureReducer = createReducer(
	initialAppstateProfile,
	on(profileInit, (state) => {
		return state ? state : initialAppstateProfile;
	}),
	on(login, (state, payload) => ({
		...state,
		profile: {
			...state.profile,
			tokens: payload.tokens,
			registrationComplete: !!payload?.claims?.DFL_registrationComplete,
			isVerified: !!payload?.claims?.DFL_isVerified,
			authorized: true,
			error: false,
			pending: false,
			socialProvider: {
				socialLogin: (payload?.claims?.socialProvider || []).includes('app_social_all'),
				apple: (payload?.claims?.socialProvider || []).includes('app_social_logins_apple'),
				facebook: (payload?.claims?.socialProvider || []).includes('app_social_logins_facebook'),
				google: (payload?.claims?.socialProvider || []).includes('app_social_logins_google'),
				x: (payload?.claims?.socialProvider || []).includes('app_social_logins_x')
			}
		}
	})),
	on(getProfile, (state) => ({
		...state,
		profile: {
			...state.profile,
			pending: true
		}
	})),
	on(setProfile, (state, payload) => ({
		...state,
		profile: {
			...state.profile,
			pending: false,
			error: false,
			...(payload.user && { user: payload.user }),
			...(payload.newsletter && { newsletter: payload.newsletter }),
			...(payload.isVerified && { isVerified: payload.isVerified }),
			...(payload.registrationComplete && {
				registrationComplete: payload.registrationComplete
			})
		}
	})),
	on(setProfileError, (state, payload) => ({
		...state,
		error: payload.error,
		pending: false
	})),
	on(renewToken, (state) => ({
		...state,
		profile: {
			...state.profile,
			pending: true
		}
	})),
	on(logout, (state) => initialAppstateProfile),
	on(logoutSilent, (state) => initialAppstateProfile)
);

export function reducer(state: ProfileState | undefined, action: Action) {
	return featureReducer(state, action);
}
