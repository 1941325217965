<div class="container-fluid">
	<div class="loader" *ngIf="(loadingService?.loading$ | async) || false">
		<dfl-loader-modern [loading]="true" size="large"></dfl-loader-modern>
	</div>
	<div class="row no-gutters">
		<div class="col-12">
			<div id="default" class="default">
				<div class="main">
					<router-outlet></router-outlet>
				</div>
			</div>
		</div>
	</div>
</div>
