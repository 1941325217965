import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Language } from '@nx-bundesliga/models';
import { getWorkingLanguage } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { ClubPersons, ClubPersonsPlayerName, ClubPlayer, ClubPlayers } from '@nx-bundesliga/models';
import { BundesligaRestService } from '@nx-bundesliga/bundesliga-com/services/common';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class PersonService extends BundesligaRestService {
	constructor(private http: HttpClient, private config: ConfigService, private readonly langStore: Store<Language>) {
		super(http, config, langStore);
		this.langStore.pipe(select(getWorkingLanguage)).subscribe((language: Language) => {
			this.language = language;
		});
	}
	// A bug in angular requires us to overwrite ngInjectableDef in all child services, because this sevice inherits ngInjectableDef from
	// BundesligaRestService and therefore Angular can't construct the child service. See:
	// - https://github.com/angular/angular/pull/25736
	// - https://stackoverflow.com/questions/50263722/angular-6-services-and-class-inheritance
	// static ngInjectableDef = undefined;

	public language: Language;

	static getPlayerAliasFirstName(names: ClubPersonsPlayerName): string {
		if (names) {
			if (names.alias) {
				const indexOf = names.alias.indexOf(' ');
				if (indexOf === -1) {
					return '';
				}

				return names.alias.slice(0, indexOf);
			}

			return names.first;
		}
		return '';
	}

	static getPlayerAliasLastName(names: ClubPersonsPlayerName): string {
		if (names.alias) {
			const indexOf = names.alias.indexOf(' ');
			if (indexOf === -1) {
				return names.alias;
			}

			return names.alias.slice(indexOf).trim();
		}

		return names.last;
	}

	static getPlayerAge(birthdate: string): number {
		const birthday = +new Date(birthdate);
		return Math.floor((Date.now() - birthday) / 31557600000);
	}

	public getPersonLastNameByFullname(playerFullname: string): string {
		const playerName = {
			'alias': playerFullname
		};
		return PersonService.getPlayerAliasLastName(playerName);
	}

	public getPersonFirstNameByFullname(playerFullname: string): string {
		const playerName = {
			'alias': playerFullname
		};
		return PersonService.getPlayerAliasFirstName(playerName);
	}

	public getPersonAliasTruncatedByFullname(playerFullname: string, maxLength: number, delimiter: string): string {
		let playerName = playerFullname;
		if (!delimiter) {
			delimiter = '...';
		}
		if (!maxLength || maxLength <= 0) {
			maxLength = 255;
		}
		if (playerName.length > maxLength) {
			playerName = playerName.slice(0, maxLength) + delimiter;
		}
		return playerName;
	}

	public getPersonBySlug(slugifiedNameSmall: string): Observable<ClubPlayer> {
		this.settings = this.config.getSettings('endpoints.person', 'person');
		return this.get<ClubPlayer>(`personbyslug/${slugifiedNameSmall}`, false);
	}

	public getPersonById(id: string): Observable<ClubPlayer> {
		this.settings = this.config.getSettings('endpoints.person', 'person');
		return this.get<ClubPlayer>(`personbydflid/${id}`, false);
	}

	public getPersonsByClub(clubId: string): Observable<ClubPersons> {
		this.settings = this.config.getSettings('endpoints.person', 'person');
		return this.get<ClubPersons>(`personsbyclub/${clubId}`, false);
	}

	public getAllPersonsForCompetition(competitionName = 'bundesliga'): Observable<ClubPlayers> {
		this.settings = this.config.getSettings('endpoints.player', 'player');
		return this.get<{ [competition: string]: ClubPlayers }>(competitionName === 'bundesliga2' || competitionName === '2bundesliga' ? '2bundesliga' : 'bundesliga').pipe(map((data) => (data.hasOwnProperty(competitionName) ? data[competitionName] : {})));
	}

	public getAllPersons(): Observable<{
		'bundesliga': ClubPlayers;
		'2bundesliga': ClubPlayers;
	}> {
		this.settings = this.config.getSettings('endpoints.player', 'player');
		return this.get<{ 'bundesliga': ClubPlayers; '2bundesliga': ClubPlayers }>('');
	}
}
