import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'slugify' })
export class SlugifyPipe implements PipeTransform {
	transform(input: string, isClubName = false): string {
		// Use hash map for special characters
		const specialCharsCommon = {
			'à': 'a',
			'ä': 'a',
			'á': 'a',
			'â': 'a',
			'æ': 'a',
			'å': 'a',
			'ë': 'e',
			'è': 'e',
			'é': 'e',
			'ê': 'e',
			'î': 'i',
			'ï': 'i',
			'ì': 'i',
			'í': 'i',
			'ò': 'o',
			'ó': 'o',
			'ö': 'o',
			'ô': 'o',
			'ø': 'o',
			'ù': 'u',
			'ú': 'u',
			'ü': 'u',
			'û': 'u',
			'ñ': 'n',
			'ç': 'c',
			'ß': 's',
			'ÿ': 'y',
			'œ': 'o',
			'ŕ': 'r',
			'ś': 's',
			'ń': 'n',
			'ṕ': 'p',
			'ẃ': 'w',
			'ǵ': 'g',
			'ǹ': 'n',
			'ḿ': 'm',
			'ǘ': 'u',
			'ẍ': 'x',
			'ź': 'z',
			'ḧ': 'h',
			'·': '-',
			'/': '-',
			'_': '-',
			',': '-',
			':': '-',
			';': '-',
			'ð': 'd',
			'ć': 'c',
			'š': 's',
			'ş': 's',
			'ł': 'l',
			'ğ': 'g',
			'ą': 'a',
			'Č': 'C',
			'ý': 'y',
			'č': 'c',
			'ř': 'r',
			'ž': 'z',
			'Ł': 'L',
			'ō': 'o',
			'ā': 'a',
			'ľ': 'l',
			"'": '-'
		};
		const specialCharsClubs = {
			'à': 'a',
			'ä': 'ae',
			'á': 'a',
			'â': 'a',
			'æ': 'a',
			'å': 'a',
			'ë': 'e',
			'è': 'e',
			'é': 'e',
			'ê': 'e',
			'î': 'i',
			'ï': 'i',
			'ì': 'i',
			'í': 'i',
			'ò': 'o',
			'ó': 'o',
			'ö': 'oe',
			'ô': 'o',
			'ø': 'o',
			'ù': 'u',
			'ú': 'u',
			'ü': 'ue',
			'û': 'u',
			'ñ': 'n',
			'ç': 'c',
			'ß': 's',
			'ÿ': 'y',
			'œ': 'o',
			'ŕ': 'r',
			'ś': 's',
			'ń': 'n',
			'ṕ': 'p',
			'ẃ': 'w',
			'ǵ': 'g',
			'ǹ': 'n',
			'ḿ': 'm',
			'ǘ': 'u',
			'ẍ': 'x',
			'ź': 'z',
			'ḧ': 'h',
			'·': '-',
			'/': '-',
			'_': '-',
			',': '-',
			':': '-',
			';': '-',
			'ð': 'd',
			'ć': 'c',
			'š': 's',
			'ş': 's',
			'ł': 'l',
			'ğ': 'g',
			'ą': 'a',
			'Č': 'C',
			'ý': 'y',
			'č': 'c',
			'ř': 'r',
			'ž': 'z',
			'Ł': 'L',
			'ō': 'o',
			'ā': 'a',
			'ľ': 'l',
			"'": '-'
		};

		const specialChars = isClubName ? specialCharsClubs : specialCharsCommon;

		return input
			.toString()
			.toLowerCase()
			.replace(/\s+/g, '-') // Replace spaces with -
			.replace(/./g, (target, index, str) => specialChars[target] || target) // Replace special characters using the hash map
			.replace(/&/g, '-and-') // Replace & with 'and'
			.replace(/[^\w\-]+/g, '') // Remove all non-word chars
			.replace(/--+/g, '-') // Replace multiple - with single -
			.replace(/^-+/, '') // Trim - from start of text
			.replace(/-+$/, ''); // Trim - from end of text
	}
}
