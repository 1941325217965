import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { initialLanguage, Language } from '@nx-bundesliga/models';
import { Observable, of } from 'rxjs';

@Injectable()
export class I18NService {
	defaultLanguage: Language;
	availableLanguages: Array<Language>;

	constructor(
		private translate: TranslateService,
		// TODO: ngx-i18n-router
		// private readonly i18nRouter: I18NRouterService,
		@Inject(PLATFORM_ID) private readonly platformId: any
	) {}

	init(settings: any): void {
		this.defaultLanguage = settings.defaultLanguage;
		this.availableLanguages = settings.availableLanguages;

		// add available languages
		this.translate.addLangs(this.availableLanguages.map((cur) => cur.code));
	}

	getLanguageByCode(languageCode: string): Observable<Language> {
		const defaultLanguage = {
			...(this.defaultLanguage || {
				...initialLanguage,
				code: languageCode
			})
		};

		return of(this.availableLanguages && Array.isArray(this.availableLanguages) ? this.availableLanguages.find((cur) => cur.code === languageCode) || defaultLanguage : defaultLanguage);
	}

	/* NOTE: reenable if we want to detect browserlanguage
  // @Inject(WINDOW) private win: Window,
  private getLanguageFromBrowser(): string {
    return isPlatformBrowser(this.platformId)
      ? this.win.navigator.language && this.win.navigator.language.split('-')[0] || this.defaultLanguage.code
      : this.defaultLanguage.code;
  }
  */
}
