import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { WINDOW } from '@nx-bundesliga/bundesliga-com/framework/window';

@Component({
	selector: 'header-navigation',
	templateUrl: './header-navigation.component.html',
	styleUrls: ['./header-navigation.component.scss']
})
export class HeaderNavigationComponent implements AfterViewInit {
	@Input() language = 'de';
	@Input() competition = 'bundesliga';
	@Input() naviStructure = [];
	@Output() navListRef: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();
	@ViewChild('navList') navList: ElementRef;
	public isBrowser = false;
	@Input() visibleNavItems = 99;

	constructor(@Inject(WINDOW) private window: Window, @Inject(PLATFORM_ID) platformId: Object) {
		this.isBrowser = isPlatformBrowser(platformId);
	}

	trackByLabel(index, item) {
		return item.label;
	}

	ngAfterViewInit() {
		if (this.isBrowser) {
			this.navListRef.emit(this.navList);
		}
	}
}
