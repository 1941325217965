import { AfterViewInit, Component, ComponentFactoryResolver, Inject, Injector, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, takeWhile, tap } from 'rxjs/operators';
import { AnalyticsService } from '@nx-bundesliga/bundesliga-com/framework/analytics';
import { WINDOW } from '@nx-bundesliga/bundesliga-com/framework/window';
import { APP_BANNER_PROMOS, AppBannerService } from '../../../../services/app-banner/app-banner.service';
import { AppBanner, AppBannerPlatform } from '../../../../models/app-banner/app-banner.model';
import { AppBannerBottomComponent } from '../app-banner-bottom/app-banner-bottom.component';
import { AppBannerComponent } from '../app-banner/app-banner.component';

@Component({
	selector: 'app-banner-wrapper',
	templateUrl: 'app-banner-wrapper.component.html',
	styleUrls: ['app-banner-wrapper.component.scss']
})
export class AppBannerWrapperComponent implements AfterViewInit {
	@ViewChild('appBannerContainer', { static: false, read: ViewContainerRef })
	banner;
	private model: AppBanner;
	private componentRef = null;
	private bannerCloseSubscription: Subscription;
	constructor(private readonly appBannerService: AppBannerService, private readonly componentResolver: ComponentFactoryResolver, private route: ActivatedRoute, private router: Router, private analyticsService: AnalyticsService, @Inject(WINDOW) private window: Window) {}

	ngAfterViewInit(): void {
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				takeWhile(() => this.appBannerService.isAppBannerEnabled()),
				map(() => this.route),
				map((route) => {
					while (route.firstChild) {
						route = route.firstChild;
					}
					return route;
				}),
				filter((route) => route.outlet === 'primary'),
				mergeMap((route) => route.data),
				map((data) => (data && data.hasOwnProperty('promo') ? data.promo : 'blweb-smart-banner')),
				tap((promo: string) => {
					this.analyticsService.eventTrack.next({
						event: 'interaction',
						category: 'Promo Banner APP',
						action: 'Promo Banner APP Impression',
						label: this.router.url
					});
				}),
				distinctUntilChanged()
			)
			.subscribe((promo) => {
				this.model = this.getModel(promo);
				let componentFactory = null;
				if (APP_BANNER_PROMOS.includes(promo) && this.appBannerService.isAppBannerEnabled(promo)) {
					componentFactory = this.componentResolver.resolveComponentFactory(AppBannerBottomComponent);
				}
				if (promo === 'blweb-smart-banner' && this.appBannerService.isAppBannerEnabled(promo)) {
					componentFactory = this.componentResolver.resolveComponentFactory(AppBannerComponent);
				}
				if (this.bannerCloseSubscription) {
					this.bannerCloseSubscription.unsubscribe();
				}
				if (this.componentRef) {
					this.componentRef.destroy();
				}
				if (componentFactory) {
					const injector = Injector.create({
						providers: [
							{
								provide: 'bannerModel',
								useValue: this.model
							}
						]
					});
					this.componentRef = this.banner.createComponent(componentFactory, 0, injector);
					const component = this.componentRef.instance;

					this.bannerCloseSubscription = component.close.subscribe((days: number) => {
						this.appBannerService.hideAppBanner(promo, days);
						this.componentRef.destroy();
					});
				}
			});
	}

	private getModel(promo: string): AppBanner {
		const model = {
			promo: promo,
			rating: this.appBannerService.getAppBannerPlatform() === AppBannerPlatform.IOS ? 4.7 : 4.4,
			url: ''
		};
		if (this.appBannerService.getAppBannerPlatform() === AppBannerPlatform.IOS) {
			model.url = `https://apps.apple.com/app/apple-store/id1005632836?pt=117842217&ct=${promo}&mt=8`;
		}
		if (this.appBannerService.getAppBannerPlatform() === AppBannerPlatform.ANDROID) {
			model.url = `market://details?id=com.bundesliga&referrer=utm_source%3Dproducts%26utm_medium%3Dsmart-banner%26utm_campaign%3D${promo}`;
		}
		return model;
	}
}
