import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AutoUnsubscribe } from '@nx-bundesliga/ngx-auto-unsubscribe-decorator';
import { combineLatest, fromEvent, mergeWith, Observable, of, pairwise, ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, share, startWith, tap } from 'rxjs/operators';
import { WINDOW } from '@nx-bundesliga/bundesliga-com/framework/window';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { Competition, Language } from '@nx-bundesliga/models';
import { getWorkingCompetition, getWorkingLanguage } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { ProfileUser } from '@nx-bundesliga/models';
import { logout } from '@nx-bundesliga/bundesliga-com/framework/store-actions';
import { getAuthenticated } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { HeaderService } from '../service/header.service';

enum StickyHeaderState {
	Active = 'active',
	Inactive = 'inactive'
}

@Component({
	selector: 'HeaderComponent',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
	public mobileNavigationOpen = false;
	stickyNavigationEnabled = StickyHeaderState.Inactive;
	public metaNavigationJson: any;
	public mainNavigationJson: any;
	public isWebview: boolean;
	public isBrowser = false;
	public navList: ElementRef;
	private navChangesMO: MutationObserver;
	public navChangesMO$: ReplaySubject<any> = new ReplaySubject<any>(1);
	public visibleNavItems$: Observable<number> = of(99);
	public userAuthorized$: Observable<boolean>;
	public language$: Observable<Language>;
	public competition$: Observable<Competition>;
	@AutoUnsubscribe() public resizeSubscription: Subscription;
	@AutoUnsubscribe() public stickyNavigationEnabled$: Observable<StickyHeaderState>;
	@ViewChild('headerContainer') headerContainer: ElementRef;
	@ViewChild('headerBottomContainer') headerBottomContainer: ElementRef;

	constructor(
		private readonly cstore: Store<Competition>,
		private readonly lstore: Store<Language>,
		private readonly config: ConfigService,
		@Inject(WINDOW) private window: Window,
		@Inject(PLATFORM_ID) platformId: Object,
		private renderer: Renderer2,
		private pstore: Store<ProfileUser>,
		private headerService: HeaderService
	) {
		this.isBrowser = isPlatformBrowser(platformId);
		this.mainNavigationJson = this.config.getSettings('navigation');
		this.metaNavigationJson = this.config.getSettings('metanavigation');
		this.isWebview = this.config.getSettings('webview', false);
	}

	ngOnInit() {
		this.userAuthorized$ = this.pstore.pipe(select(getAuthenticated));
		this.language$ = this.lstore.pipe(
			select(getWorkingLanguage),
			filter((lang: Language) => lang.code !== '')
		);
		this.competition$ = this.cstore.pipe(select(getWorkingCompetition));
	}

	ngAfterViewInit() {
		if (this.isBrowser) {
			const trackHeaderOffset = () => {
				const headerHeight = this.headerContainer.nativeElement.getBoundingClientRect().bottom || 0;
				this.headerService.setOffset(headerHeight);
			};
			// run header offset tracking once even if not scrolled yet
			trackHeaderOffset();
			this.stickyNavigationEnabled$ = fromEvent(this.window, 'scroll').pipe(
				tap(() => trackHeaderOffset()),
				map(() => this.getYOffset()),
				pairwise(),
				map(([offset1, offset2]) => {
					if (offset1 > offset2) {
						return StickyHeaderState.Active;
					} else {
						return StickyHeaderState.Inactive;
					}
				}),
				distinctUntilChanged(),
				share()
			);

			this.navChangesMO = new MutationObserver((mutations: MutationRecord[]) => {
				this.navChangesMO$.next(mutations);
				for (const mutation of mutations.filter((m) => m.type === 'childList')) {
					mutation.addedNodes.forEach((addedNode) => {
						// it might be text node or comment node which don't have querySelectorAll
						addedNode.parentElement.querySelectorAll &&
							addedNode.parentElement.querySelectorAll('img').forEach((child) => {
								child.addEventListener(
									'load',
									() => {
										this.navChangesMO$.next(true);
									},
									false
								);
							});
					});
				}
			});
			this.navChangesMO.observe(this.headerBottomContainer.nativeElement, {
				attributes: false,
				childList: true,
				characterData: false,
				subtree: true
			});
			this.visibleNavItems$ = this.navChangesMO$.pipe(
				mergeWith(fromEvent(this.window, 'resize')),
				map(() => this.navList.nativeElement),
				map((element: HTMLElement) => element.scrollHeight > element.clientHeight),
				map((overflow: boolean) => (overflow ? [...this.navList.nativeElement.querySelectorAll('.navItem')].filter((li) => li && li.offsetTop <= 36).length : 99))
			);
		}
	}

	ngOnDestroy() {
		if (this.navChangesMO) {
			this.navChangesMO.disconnect();
		}
	}

	/**
	 *
	 * @returns {number}
	 */
	private getYOffset() {
		const supportPageOffset = this.window.pageYOffset !== undefined;
		const isCSS1Compat = (this.window.document.compatMode || '') === 'CSS1Compat';

		return supportPageOffset ? this.window.pageYOffset : isCSS1Compat ? this.window.document.documentElement.scrollTop : this.window.document.scrollingElement.scrollTop || this.window.document.body.scrollTop;
	}

	public logout() {
		this.pstore.dispatch(logout());
	}

	toggleMobileMenu() {
		this.mobileNavigationOpen = !this.mobileNavigationOpen;
		this.makeMobileMenuStatic();
	}

	makeMobileMenuStatic() {
		if (this.mobileNavigationOpen) {
			this.renderer.addClass(document.body, 'overflow-hidden');
		} else {
			this.renderer.removeClass(document.body, 'overflow-hidden');
		}
	}
}
