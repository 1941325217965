import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
import { Language } from '@nx-bundesliga/models';
import { getWorkingLanguage } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { Observable, of, ReplaySubject } from 'rxjs';
import { filter, map, takeWhile } from 'rxjs/operators';
import { executeIfFunction } from '@nx-bundesliga/bundesliga-com/framework/common';

import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { ConsentCategories, CookieConsentService } from '@nx-bundesliga/bundesliga-com/services/cookie-consent';
import { ScriptLoaderStore } from './script-loader.store';

export type ScriptLoaderServiceStatus = 'loaded' | 'alreadyloaded' | 'notloaded';
const scriptStoreCacheKey = 'SCRIPT_STORE_LOADED';

/**
 * Minimal interface to request scripts.
 * @todo/dfl/model/unnecessary: Not sure if there's really any value in using this.
 */
export interface Script {
	src?: any;
	body?: any;
	preconnect?: string[];
	name: string;
	consentCategory?: string;
	loaded?: ReplaySubject<ScriptLoaderServiceStatus>;
	async?: boolean;
	defer?: boolean;
	crossorigin?: string;
	integrity?: string;
	nomodule?: boolean;
	nonce?: any;
	referrerpolicy?: any;
	type?: string;
	charset?: string;
	'data-document-language'?: string;
	'data-domain-script'?: any;
}

@Injectable({
	providedIn: 'root'
})
export class ScriptLoaderService {
	private scripts: Script[] = [];
	private language: string;
	public gtagId: string;
	public instanaApikey: string;
	public gmapsApiKey: string;
	public jwPlayerId: string;
	public onetrustKey: string;
	private readonly isBrowser: boolean;
	private renderer: Renderer2;

	constructor(
		@Inject(PLATFORM_ID) platformId: Object,
		private readonly lstore: Store<Language>,
		private configService: ConfigService,
		@Inject(DOCUMENT) private document,
		private cookieConsent: CookieConsentService,
		private transferState: TransferState,
		private ngZone: NgZone,
		private rendererFactory: RendererFactory2
	) {
		this.renderer = rendererFactory.createRenderer(null, null);
		this.isBrowser = isPlatformBrowser(platformId);

		this.lstore
			.pipe(
				select(getWorkingLanguage),
				filter((lang: Language) => lang.code !== '')
			)
			.subscribe((langstate: Language) => {
				this.language = langstate.code;
			});

		ScriptLoaderStore.forEach((script: Script) => {
			const ssrLoadedScripts = this.transferState.get(makeStateKey<ScriptLoaderServiceStatus>(scriptStoreCacheKey + script.name), null);
			this.scripts[script.name] = {
				loaded: new ReplaySubject<ScriptLoaderServiceStatus>(1),
				...script
			} as Script;
			if (ssrLoadedScripts !== null) {
				this.scripts[script.name].loaded.next('loaded');
				this.scripts[script.name].loaded.complete();
			}
		});
	}

	/**
	 *
	 * @param key
	 * @param property
	 * @param resolveConfig
	 */
	public setConfigKey(key: string, property: string, resolveConfig = true, language?: string) {
		let lang = this.language;
		if (language && language !== '') {
			lang = language;
		}
		if (resolveConfig) {
			const response = this.configService.getSettings(key.replace(':language', lang), '');
			this[property] = response;
		} else {
			this[property] = key;
		}
	}

	/**
	 * Requires a script only once and returns a promise, which fulfills once the script's been loaded.
	 * Always returns null on the server.
	 *
	 * @param {Script | string[]} scripts
	 * @param {boolean} forceLoad boolean which will be force the script to load regardless of consent-category
	 * @returns {Observable<ScriptLoaderServiceStatus[]>}
	 */
	public load(scripts?: Script | string, forceLoad = false): Observable<ScriptLoaderServiceStatus>[] {
		const observables = [];
		const scriptNames = [];
		if (typeof scripts === 'object' && scripts.name && (scripts.src || scripts.body)) {
			if (!this.scripts.hasOwnProperty(scripts.name)) {
				const ssrLoadedScripts = this.transferState.get(makeStateKey<ScriptLoaderServiceStatus>(scriptStoreCacheKey + scripts.name), null);
				this.scripts[scripts.name] = {
					loaded: new ReplaySubject<ScriptLoaderServiceStatus>(1),
					...scripts
				} as Script;
				if (ssrLoadedScripts !== null) {
					this.scripts[scripts.name].loaded.next('loaded');
					this.scripts[scripts.name].loaded.complete();
				}
			}
			scriptNames.push(scripts.name);
		} else if (typeof scripts === 'string') {
			scriptNames.push(scripts);
		}

		// continue loading the scripts as usual
		scriptNames.forEach((script) => {
			if (this.scripts.hasOwnProperty(script)) {
				/* this.scripts[script].loaded = new BehaviorSubject<ScriptLoaderServiceStatus>('notloaded'); */
				observables.push(this.scripts[script].loaded);
				this.loadScript(script, forceLoad);
			}
		});

		return observables;
	}

	/**
	 * Actually loads a script from the whitelist in ./script-loader.store.ts.
	 *
	 * @param {string} script
	 * @param {boolean} forceLoad boolean which will be force the script to load regardless of consent-category
	 * @returns {Promise<ScriptLoaderServiceStatus>} Fulfills once the native onload or onreadystatechange function has been called.
	 */
	private loadScript(script: string, forceLoad = false) {
		const prefix = 'sls--';
		const scriptTagName = prefix + script;
		const scriptTagInDOM = this.document.getElementById(scriptTagName);

		if (scriptTagInDOM !== null) {
			// check if the global script node has this script already registered.
			// if not the service was propably lazy loaded and needs to patch the object.
			if (!this.scripts.hasOwnProperty(script)) {
				this.scripts[script] = {
					loaded: new ReplaySubject<ScriptLoaderServiceStatus>(1),
					name: script,
					src: scriptTagInDOM.src,
					defer: !!this.scripts[script].defer,
					type: this.scripts[script].type ? this.scripts[script].typ : 'text/javascript',
					nomodule: this.scripts[script].nomodule ? this.scripts[script].nomodule : false,
					charset: this.scripts[script].charset ? this.scripts[script].charset : 'UTF-8',
					'data-document-language': this.scripts[script]['data-document-language'] ? this.scripts[script]['data-document-language'] : false,
					'data-domain-script': this.scripts[script]['data-domain-script'] ? this.scripts[script]['data-domain-script'] : false
				} as Script;
				this.scripts[script].loaded.next('loaded');
				this.scripts[script].loaded.complete();
			}
		} else {
			// If this script never's been requested before, we load it now and store the promise for future request.
			const scriptTag = this.renderer.createElement('script');
			scriptTag.defer = !!this.scripts[script].defer;
			scriptTag.type = this.scripts[script].type ? this.scripts[script].type : 'text/javascript';
			scriptTag.nomodule = this.scripts[script].nomodule ? this.scripts[script].nomodule : false;
			scriptTag.id = scriptTagName;

			// Prepare payload to be given to all scripts as constructor.
			const payload = {
				lang: this.language,
				gtag: this.gtagId,
				instanaApikey: this.instanaApikey,
				jwPlayerId: this.jwPlayerId,
				onetrustKey: this.onetrustKey,
				gmapsApiKey: this.gmapsApiKey
			};

			if (this.scripts[script].async) {
				this.renderer.setAttribute(scriptTag, 'async', this.scripts[script]['async']);
			}
			if (this.scripts[script].crossorigin) {
				this.renderer.setAttribute(scriptTag, 'crossorigin', this.scripts[script]['crossorigin']);
			}
			if (this.scripts[script].integrity) {
				this.renderer.setAttribute(scriptTag, 'integrity', this.scripts[script]['integrity']);
			}
			if (this.scripts[script].referrerpolicy) {
				this.renderer.setAttribute(scriptTag, 'referrerpolicy', this.scripts[script]['referrerpolicy']);
			}
			if (this.scripts[script].charset) {
				this.renderer.setAttribute(scriptTag, 'charset', this.scripts[script]['charset']);
			}
			if (this.scripts[script]['data-document-language']) {
				this.renderer.setAttribute(scriptTag, 'data-document-language', this.scripts[script]['data-document-language']);
			}
			if (this.scripts[script]['data-domain-script']) {
				this.renderer.setAttribute(scriptTag, 'data-domain-script', executeIfFunction(this.scripts[script]['data-domain-script'], payload));
			}

			if (this.scripts[script].src && this.scripts[script].body) {
				scriptTag.src = executeIfFunction(this.scripts[script].src, payload);
				scriptTag.text = executeIfFunction(this.scripts[script].body, payload);
			} else if (this.scripts[script].src && !this.scripts[script].body) {
				scriptTag.src = executeIfFunction(this.scripts[script].src, payload);
			} else if (this.scripts[script].body && !this.scripts[script].src) {
				scriptTag.text = executeIfFunction(this.scripts[script].body, payload);
			}

			this.loadLinkPreconnect(this.scripts[script].preconnect);

			// assess category of script and load it as soon as cookie category is consented to
			// in case script runs on the server we only load essential scripts of category C0001
			const consentCategories$ = this.isBrowser ? this.cookieConsent.consents$ : of(this.cookieConsent.consentCategoriesDefault);
			consentCategories$
				.pipe(
					map((val: ConsentCategories) => forceLoad === true || (this.scripts[script].hasOwnProperty('consentCategory') ? val[this.scripts[script]['consentCategory']] : true)),
					takeWhile((val: boolean) => val === false, true)
				)
				.subscribe((consented: boolean) => {
					if (consented === true) {
						this.renderer.appendChild(this.document.head, scriptTag);
						this.checkScriptLoaded(scriptTag, script, 'loaded');
					}
				});
		}
	}

	/**
	 * Actually loads a script from the whitelist in ./script-loader.store.ts.
	 *
	 * @param {string[]} urls the array of urls to append to head preconnect link tags
	 */
	public loadLinkPreconnect(urls: string[]): void {
		if (urls && urls.length > 0) {
			urls.forEach((url) => {
				const linkTag = this.renderer.createElement('link');
				linkTag.rel = 'preconnect';
				linkTag.href = url;
				this.renderer.appendChild(this.document.head, linkTag);
			});
		}
	}

	/**
	 *
	 * @param scriptTag
	 * @param scriptName
	 * @param message
	 */
	private checkScriptLoaded(scriptTag, scriptName, message: ScriptLoaderServiceStatus) {
		if (this.isBrowser) {
			this.ngZone.runOutsideAngular(() => {
				// Hook onto loading events and resolve the promise once the script's been loaded.
				if (scriptTag.src) {
					if (scriptTag.readyState) {
						// Internet explorer exclusive path.
						// So sad.
						scriptTag.onreadystatechange = () => {
							if (scriptTag.readyState === 'loaded' || scriptTag.readyState === 'complete') {
								if (this.scripts[scriptName].loaded.closed === false) {
									this.scripts[scriptName].loaded.next(message);
									this.scripts[scriptName].loaded.complete();
								}
							}
						};
					} else {
						// all the cool guys.
						scriptTag.onload = () => {
							if (this.scripts[scriptName].loaded.closed === false) {
								this.scripts[scriptName].loaded.next(message);
								this.scripts[scriptName].loaded.complete();
							}
						};
					}

					scriptTag.onerror = (error: any) => {
						console.warn(`ScriptLoaderService.Error.${scriptName}`);
						if (this.scripts[scriptName].loaded.closed === false) {
							this.scripts[scriptName].loaded.error('notloaded');
							//this.scripts[scriptName].loaded.complete();
						}
					};
				} else {
					if (this.scripts[scriptName].loaded.closed === false) {
						this.scripts[scriptName].loaded.next(message);
						this.scripts[scriptName].loaded.complete();
					}
				}
			});
		} else {
			this.transferState.set(makeStateKey<string>(scriptStoreCacheKey + scriptName), 'ssrLoaded');
			this.scripts[scriptName].loaded.next(message);
			this.scripts[scriptName].loaded.complete();
		}
	}
}
