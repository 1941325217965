import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterModule } from '../../components/footer/footer.module';
import { HeaderModule } from '../../components/header/header.module';
import { SharedModule } from '@nx-bundesliga/bundesliga-com/framework/core';
import { WrapperPageComponent } from './wrapper.component';
import { MatchbarModule } from '../../components/matchbar/matchbar.module';

@NgModule({
	imports: [RouterModule, HeaderModule, FooterModule, SharedModule, MatchbarModule],
	exports: [WrapperPageComponent],
	declarations: [WrapperPageComponent]
})
export class WrapperPageModule {}
