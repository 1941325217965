import { EditorialBase, EditorialHeadlines } from './editorial.model';
import { EditorialSpecialPage } from './specialpage.model';

export interface TeaserAbstract {
	teaserAbstractDefault: string;
	teaserAbstractShort?: string;
}

/**
 * Interface for editorial wiki, e.g.: https://bundesliga.com/wiki/article-123
 */
export interface EditorialWiki extends EditorialBase {
	type: 'wiki';
	wikiCategory: {
		title: string;
		speakingUrl: string;
		relatedWikiCategories: WikiCategoryTeaser[];
	};
}
export interface EditorialWikiCategory extends EditorialBase {
	type: 'wiki_category';
	articles: EditorialWikiCategoryArticles;
	mostReadArticles: WikiTeaser[];
	relatedWikiCategories: WikiTeaser[];
}

export interface EditorialWikiCategoryArticles {
	elements: WikiTeaser[];
	totalElementCount: number;
	totalPageCount: number;
	currentPageNumber: number;
	elementsPerPage: number;
	elementsOnPage: number;
}

export interface WikiHome {
	editorial: EditorialSpecialPage;
	categories: WikiCategoryTeaser[];
}

export interface WikiTeaserBase {
	cisId: string;
	publishedAt: string;
	type: string;
	slug: string;
	teaser: string;
	speakingUrl: string;
	isExclusive?: boolean;
	isVideo?: boolean;
	categories?: string[];
	headlines: EditorialHeadlines;
	editorialAbstract?: string;
	teaserAbstract?: TeaserAbstract;
}

export interface WikiTeaser extends WikiTeaserBase {
	type: 'wiki';
	isExclusive?: boolean;
	isVideo?: boolean;
}

export interface WikiCategoryTeaser extends WikiTeaserBase {
	type: 'wiki_category';
	articles?: WikiTeaser[];
}
