import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { BUNDESLIGA_SEASONS } from '@nx-bundesliga/models';

@Component({
	selector: 'clublogo',
	templateUrl: './clublogo.component.html',
	styleUrls: ['./clublogo.component.scss']
})
export class ClublogoComponent implements OnInit, OnChanges {
	@Input() clubId = '';
	@Input() competitionId = BUNDESLIGA_SEASONS.CURRENT;
	@Input() clubLogoUrl = '';
	@Input() title = '';
	@Input() dimension: number;
	@Input() width: number;
	@Input() height: number;
	@Input() legacy = false;
	@Input() lazyload = false; // only in combination with legacy
	@Input() addBorder = false; // only in combination with legacy

	public src = '';

	public readonly lazyLoadErrorImage = '/assets/clublogo/default_clublogo.svg';
	public readonly lazyLoadPlaceholderImage = '/assets/clublogo/default_clublogo.svg';

	constructor() {}

	ngOnInit() {
		this.setClubLogo();
	}

	ngOnChanges() {
		this.setClubLogo();
	}

	setClubLogo() {
		const localeClubLogoPath = '/assets/clublogo/';
		if (this.clubLogoUrl && this.clubLogoUrl !== '') {
			this.src = this.clubLogoUrl.replace('https://s.bundesliga.com/images/clublogos/', localeClubLogoPath);
			if (this.src.startsWith(localeClubLogoPath)) {
				this.src = this.src.replace('png', 'svg');
			}
		} else {
			if (this.clubId && this.clubId !== '') {
				this.src = `${localeClubLogoPath}${this.clubId.toUpperCase()}.svg`;
			} else {
				this.src = this.lazyLoadErrorImage;
			}
		}
	}
}
