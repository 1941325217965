import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DfpAdsComponent } from './component/dfp-ads.component';

@NgModule({
	imports: [CommonModule, TranslateModule.forChild()],
	exports: [DfpAdsComponent],
	providers: [],
	declarations: [DfpAdsComponent]
})
export class DfpAdsModule {}
