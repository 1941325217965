import { Pipe, PipeTransform } from '@angular/core';
import { Club, LiveBlogMatch, Match } from '@nx-bundesliga/models';
import { BundesligaJsonLdSportEvent } from '@nx-bundesliga/models';
import { MatchesService } from '@nx-bundesliga/bundesliga-com/services/matches';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { LinkHttpPipe } from '@nx-bundesliga/commons';

@Pipe({
	name: 'fixturesMatchJsonld',
	pure: true
})
export class FixturesMatchJsonLdPipe implements PipeTransform {
	private baseUrl = '';
	constructor(private matchesService: MatchesService, private translate: TranslateService, private config: ConfigService, private linkHttpPipe: LinkHttpPipe) {
		this.baseUrl = this.config.getSettings('system.applicationUrl');
	}
	transform(match: Match | LiveBlogMatch, language: string, clubs: Club[]): BundesligaJsonLdSportEvent | boolean {
		let matchdayLabel = `${this.translate.instant('MATCHCENTER.MATCHDAY')} ${match.matchday}: ${match.teams.home.threeLetterCode} - ${match.teams.home.threeLetterCode}`;

		let stadiumAddress = '';
		let stadiumImage = '';
		let stadiumName = '';
		let ticketShopUrl = '';

		if (clubs && clubs.length > 0) {
			const clubHome = clubs.find((club: Club) => club.threeLetterCode === match.teams.home.threeLetterCode);
			const clubAway = clubs.find((club: Club) => club.threeLetterCode === match.teams.away.threeLetterCode);
			if (clubHome?.stadium) {
				if (clubHome['stadium']['mapsUrl']) {
					stadiumAddress = clubHome['stadium']['mapsUrl'];
				}
				if (clubHome['stadium']['imageUrl']) {
					stadiumImage = clubHome['stadium']['imageUrl'];
				}
				if (clubHome['stadium']['name']) {
					stadiumName = clubHome['stadium']['name'];
					matchdayLabel += ', ' + stadiumName;
				}
			}

			if (clubHome?.contact) {
				if (clubHome['contact']['tickets']) {
					ticketShopUrl = clubHome['contact']['tickets'];
				}
			}

			const matchEventStatus = this._getEventStatus(match);
			let matchPlannedKickoff = '';
			if (match['plannedKickOff'] && match['plannedKickOff'] !== '') {
				matchPlannedKickoff = match['plannedKickOff'];
			} else if (matchEventStatus !== 'https://schema.org/EventScheduled' && (!match['plannedKickOff'] || match['plannedKickOff'] === '')) {
				matchPlannedKickoff = match.matchdayRange.start.split('T')[0] || '';
			}

			if (matchPlannedKickoff && stadiumAddress !== '' && stadiumImage !== '') {
				const matchcenterUrl = this._transformMatchCenterRouterLinkToUrl(match, language);
				const jsonLd: BundesligaJsonLdSportEvent = {
					'@context': 'https://schema.org',
					'@type': 'SportsEvent',
					'eventStatus': matchEventStatus,
					'url': matchcenterUrl,
					'name': matchdayLabel,
					'description': matchdayLabel,
					'image': {
						'@type': 'ImageObject',
						'url': stadiumImage
					},
					'startDate': matchPlannedKickoff,
					'location': {
						'@type': 'StadiumOrArena',
						'name': stadiumName,
						'address': stadiumAddress,
						'sameAs': '',
						'image': stadiumImage
					},
					'homeTeam': {
						'@type': 'SportsTeam',
						'name': clubHome?.name?.full,
						'sameAs': this.linkHttpPipe.transform(clubHome?.contact?.homepage),
						'logo': match?.teams?.home?.logoUrl ? match?.teams?.home?.logoUrl : clubHome?.logos[0]?.uri
					},
					'awayTeam': {
						'@type': 'SportsTeam',
						'name': clubAway?.name?.full,
						'sameAs': this.linkHttpPipe.transform(clubAway?.contact?.homepage),
						'logo': match?.teams?.away?.logoUrl ? match?.teams?.away?.logoUrl : clubAway?.logos[0]?.uri
					},
					'offers': {
						'@type': 'Offer',
						'category': 'primary',
						'url': this.linkHttpPipe.transform(clubHome?.contact?.tickets)
					}
				};
				return jsonLd;
			}
		} else {
			return false;
		}
	}
	private _getEventStatus(match: Match | LiveBlogMatch): string {
		if (match.dateQuality === 'POSTPONED') {
			return 'https://schema.org/EventPostponed';
		}

		if (['CANCELED', 'ABANDONED'].includes(match.dateQuality)) {
			return 'https://schema.org/EventCancelled';
		}

		if (['REPLAY', 'RESCHEDULED'].includes(match.dateQuality)) {
			return 'https://schema.org/EventRescheduled';
		}

		return 'https://schema.org/EventScheduled';
	}
	private _getMatchCenterRouterLink(match: Match, language: string): string[] {
		return this.matchesService.getMatchCenterRouterLink(match, language);
	}

	private _transformMatchCenterRouterLinkToUrl(match, language): string {
		const matchCenterRouterLink = this._getMatchCenterRouterLink(match, language);
		const routeMatchdayTranslated = this.translate.instant('ROUTES.route-matchday');
		let matchcenterUrl = matchCenterRouterLink.join('/');
		matchcenterUrl = matchcenterUrl.replace('route-matchday', routeMatchdayTranslated);
		matchcenterUrl = matchcenterUrl.replace('//', '/');
		matchcenterUrl = this.baseUrl + matchcenterUrl;
		return matchcenterUrl;
	}
}
