import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatchClub } from '@nx-bundesliga/models';

@Component({
	selector: 'match-team',
	templateUrl: './match-team.component.html',
	styleUrls: ['./match-team.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatchTeamComponent implements OnInit {
	@Input() club: MatchClub;
	@Input() side: 'home' | 'away';
	@Input() competitionId: string;
	@Input() isEuroCompetition?: boolean;

	constructor() {}

	ngOnInit(): void {
		this.determineCompetition();
	}

	determineCompetition(): void {
		this.isEuroCompetition = this.competitionId === 'DFL-COM-J0002E';
	}
}
