import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'header-logo',
	templateUrl: './headerlogo.component.html',
	styleUrls: ['./headerlogo.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderlogoComponent {
	@Input() competition: string;
	@Input() language: string;

	constructor() {}
}
