import { Pipe, PipeTransform } from '@angular/core';
import { BUNDESLIGA_SEASONNAMES, Club } from '@nx-bundesliga/models';
import { Observable, of } from 'rxjs';
import { ClubService } from '@nx-bundesliga/bundesliga-com/services/club';
import { catchError } from 'rxjs/operators';
import { DflDatalibraryService } from '@nx-bundesliga/shared/data-access/dfl-data-library';

@Pipe({
	name: 'toClub',
	pure: true
})
export class ToClubPipe implements PipeTransform {
	constructor(private clubs: ClubService, private dataLibrary: DflDatalibraryService) {}

	transform(clubId: string, season = BUNDESLIGA_SEASONNAMES.CURRENT.toString()): Observable<Club> {
		const seasonId = season.startsWith('DFL-SEA') ? season : this.dataLibrary.getSeasonIdBySeasonName(season);
		return this.clubs.getClubById(clubId, seasonId).pipe(catchError(() => of(null)));
	}
}
@Pipe({
	name: 'competitionClubs',
	pure: true
})
export class CompetitionClubsPipe implements PipeTransform {
	constructor(private clubs: ClubService, private dataLibrary: DflDatalibraryService) {}

	transform(competition: string, season = BUNDESLIGA_SEASONNAMES.CURRENT.toString()): Observable<Club[]> {
		const competitionName = competition && !competition.startsWith('DFL-COM') ? competition : competition === 'DFL-COM-000002' ? '2bundesliga' : 'bundesliga';
		const seasonId = season.startsWith('DFL-SEA') ? season : this.dataLibrary.getSeasonIdBySeasonName(season);
		return this.clubs.getAllClubsByCompetitionAndSeason(competitionName, 'editorialorder', seasonId).pipe(catchError(() => of([])));
	}
}
@Pipe({
	name: 'slugToClub',
	pure: true
})
export class SlugToClubPipe implements PipeTransform {
	constructor(private clubs: ClubService, private dataLibrary: DflDatalibraryService) {}

	transform(clubName: string, season = BUNDESLIGA_SEASONNAMES.CURRENT.toString()): Observable<Club> {
		const seasonId = season.startsWith('DFL-SEA') ? season : this.dataLibrary.getSeasonIdBySeasonName(season);
		return this.clubs.getClub(clubName, seasonId).pipe(catchError(() => of(null)));
	}
}
