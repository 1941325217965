import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
// libs
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from '@nx-bundesliga/ngx-auto-unsubscribe-decorator';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';
import { MetaService } from '@nx-bundesliga/bundesliga-com/framework/meta';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';

// module
import { LoadingService } from '../../services/loading/loading.service';
import { useCompetition, useLanguage } from '@nx-bundesliga/bundesliga-com/framework/store-actions';
import { BUNDESLIGA_SEASONS, Competition, Language } from '@nx-bundesliga/models';
import { getWorkingCompetition, getWorkingLanguage } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { DOCUMENT } from '@angular/common';

@Component({
	selector: 'wrapper-page',
	templateUrl: './wrapper.component.html',
	styleUrls: ['./wrapper.component.scss']
})
export class WrapperPageComponent implements OnInit {
	@AutoUnsubscribe() private routeSubscription: Subscription;
	@AutoUnsubscribe() private compstoreSubscription: Subscription;
	@AutoUnsubscribe() private langstoreSubscription: Subscription;

	public seasonId = BUNDESLIGA_SEASONS.CURRENT;
	public displayMatchdayMatchbar = false;
	public language = 'en';
	public competitionId = 'DFL-COM-000001';

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		public translate: TranslateService,
		private metaService: MetaService,
		private readonly config: ConfigService,
		private readonly i18nStore: Store<Language>,
		private readonly competitionStore: Store<Competition>,
		public loadingService: LoadingService,
		private renderer: Renderer2,
		@Inject(DOCUMENT) private document: Document
	) {}

	ngOnInit(): void {
		this.setDocumentDir('ltr'); // Set default direction
		this.langstoreSubscription = this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				mergeMap(() =>
					this.i18nStore.pipe(
						select(getWorkingLanguage),
						filter((lang) => lang.code !== '')
					)
				),
				map(() => this.router.url)
			)
			.subscribe((url: string) => {
				this.metaService.update(url);
			});

		this.routeSubscription = this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map((nav: NavigationEnd) => {
					const url = nav.urlAfterRedirects.split('/').filter((val) => val !== '');
					const urlParams = {
						language: this.config.getSettings('i18n.defaultLanguage.code'),
						competition: 'bundesliga'
					};
					if (url.length >= 1) {
						urlParams.language = url[0];
						if (url.length >= 2 && ['bundesliga', '2bundesliga', 'euro'].includes(url[1])) {
							urlParams.competition = url[1];
						}
						this.displayMatchdayMatchbar = this.canDisplayMatchbar(url);
						return urlParams;
					}
				}),
				distinctUntilChanged((p, c) => p.language === c.language && p.competition === c.competition)
			)
			.subscribe((params: { language: string; competition: string }) => {
				this.i18nStore.dispatch(useLanguage({ language: params.language }));
				this.competitionStore.dispatch(useCompetition({ competition: params.competition }));

				this.language = params.language;
				this.setDocumentDir(this.language === 'ar' ? 'rtl' : 'ltr');

				this.competitionId = 'DFL-COM-000001';
				if (params.competition === '2bundesliga') {
					this.competitionId = 'DFL-COM-000002';
				} else if (params.competition === 'euro') {
					this.competitionId = 'DFL-COM-J0002E';
				}
				this.seasonId = this.config.getSettings(`${this.competitionId}.season.dflDatalibrarySeasonId`, BUNDESLIGA_SEASONS.CURRENT);
			});

		this.compstoreSubscription = this.competitionStore.pipe(select(getWorkingCompetition), distinctUntilChanged()).subscribe((competition: Competition) => {
			// the markup twitter:xyz needs to stay for the moment due x did not change it https://developer.twitter.com/en/docs/twitter-for-websites/cards/overview/markup
			this.metaService.setTag('twitter:image', this.config.getSettings('system.applicationUrl') + '/assets/share/fallback-' + competition.name + '-2024-2025.jpg');
			this.metaService.setTag('og:image', this.config.getSettings('system.applicationUrl') + '/assets/share/fallback-' + competition.name + '-2024-2025.jpg');
		});
	}

	private setDocumentDir(dir: 'ltr' | 'rtl') {
		this.renderer.setAttribute(this.document.documentElement, 'dir', dir);
	}

	private canDisplayMatchbar(urlChunks: string[]): boolean {
		let canDisplayMatchbar = false;
		// no matchbar on euro and wm, cl and so on
		if (this.competitionId === 'DFL-COM-J00028' || this.competitionId === 'DFL-COM-J00020' || this.competitionId === 'DFL-COM-J00022' || this.competitionId === 'DFL-COM-J0002E') {
			return false;
		}

		const ignoreUrlSlugs = this.config.getSettings('globalMatchbar.ignoreUrlSlugs', ['', 'info', 'account']);

		// home page, live blogs or info pages like imprint don't have a matchbar
		if (!urlChunks[2] || (urlChunks[2] && ignoreUrlSlugs.includes(urlChunks[2]))) {
			return false;
		}

		const currentMatchdayStart = this.config.getSettings(this.competitionId + '.matchday.matchdayRange.start', null);
		const matchdayStartTime = new Date(currentMatchdayStart);
		const currentMatchdayEnd = this.config.getSettings(this.competitionId + '.matchday.matchdayRange.end', null);
		const matchdayEndTime = new Date(currentMatchdayEnd); // time is wrong in STS data. it's always 00:00:00 but the day date is correct
		const matchdayEndTimeMS = matchdayEndTime.getTime() + 86400000; // add 1 day as offset
		const currentTimeTS = new Date().getTime();

		if (matchdayStartTime.getTime() <= currentTimeTS && matchdayEndTimeMS > currentTimeTS) {
			canDisplayMatchbar = true;
		}
		return canDisplayMatchbar;
	}
}
