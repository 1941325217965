import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BUNDESLIGA_SEASONS, ClubState } from '@nx-bundesliga/models';

export const _getClubState = (state: ClubState, season: string = BUNDESLIGA_SEASONS.CURRENT) => state[season];
export const _getWorkingClubs = (state: ClubState, season: string = BUNDESLIGA_SEASONS.CURRENT) => state[season].data;
export const _getClubStateLoaded = (state: ClubState, season: string = BUNDESLIGA_SEASONS.CURRENT) => state[season]?.loaded || false;
export const _getClubStateLoading = (state: ClubState, season: string = BUNDESLIGA_SEASONS.CURRENT) => state[season]?.loading || false;

const selectClubsAppState = createFeatureSelector<ClubState>('clubs');
const selectClubsState = createSelector(selectClubsAppState, (cur) => cur);

export const getWorkingClubState = createSelector(selectClubsState, _getClubState);
export const getWorkingClubs = (season: string = BUNDESLIGA_SEASONS.CURRENT) => createSelector(selectClubsState, (state) => _getWorkingClubs(state, season));
export const getWorkingClubStateLoaded = (season: string = BUNDESLIGA_SEASONS.CURRENT) => createSelector(selectClubsState, (state) => _getClubStateLoaded(state, season));
export const getWorkingClubStateLoading = (season: string = BUNDESLIGA_SEASONS.CURRENT) => createSelector(selectClubsState, (state) => _getClubStateLoading(state, season));
