import { EditorialFeature } from './feature.model';
import { EditorialNews } from './news.model';
import { EditorialWiki } from './wiki.model';

/**
 * Union type for all editorial content. Contains:
 * - MatchdayReport
 * - EditorialNews
 * - EditorialWiki
 * - EditorialFeature
 */
export type EditorialArticle = EditorialNews | EditorialWiki | EditorialFeature;
