import { Action, createReducer, on } from '@ngrx/store';
import { competitionInit, useCompetition } from '@nx-bundesliga/bundesliga-com/framework/store-actions';
import { CompetitionState, initialAppstateCompetition } from '@nx-bundesliga/models';

// map of all competitions to their respective DFL IDs
const competitionIds = {
	'bundesliga': 'DFL-COM-000001',
	'2bundesliga': 'DFL-COM-000002',
	'supercup': 'DFL-COM-000003',
	'relegation-bundesliga': 'DFL-COM-000005',
	'relegation-2bundesliga': 'DFL-COM-000004',
	'euro': 'DFL-COM-J0002E'
};

const featureReducer = createReducer(
	initialAppstateCompetition,
	on(competitionInit, (state) => initialAppstateCompetition),
	on(useCompetition, (state, competitionPayload) => {
		if (Object.keys(competitionIds).includes(competitionPayload.competition)) {
			return {
				...state,
				competition: {
					...state.competition,
					name: competitionPayload.competition,
					id: competitionIds[competitionPayload.competition]
				}
			};
		} else {
			return state;
		}
	})
);

export function reducer(state: CompetitionState | undefined, action: Action) {
	return featureReducer(state, action);
}
