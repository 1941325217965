// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ClubStats {
	// Enter regular club Stats here as soon as available
}

export interface StatsClubEntry {
	value: number;
}

export interface ClubStatsExtended<T> {
	ballPossessionRatio?: T;
	cards?: T;
	cardsYellow?: T;
	crossesFromPlay?: T;
	distanceCovered?: T;
	foulsAgainstOpponent?: T;
	goals?: T;
	intensiveRuns?: T;
	ownGoals?: T;
	passesFromPlayRatio?: T;
	penalties?: T;
	penaltiesSuccessful?: T;
	shotsAtGoal?: T;
	shotsAtGoalWoodWork?: T;
	sprints?: T;
	tacklingGamesAirWon?: T;
	tacklingGamesWon?: T;
}
