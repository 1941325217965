import { StatsPlayerRankingsEntry } from './player-stats-rankings.model';

export const mockStatsPlayerRankingsEntry: StatsPlayerRankingsEntry = {
	club: {
		color: '#ff0000',
		dflDatalibraryClubId: 'DFL-CLU-000010',
		logoUrl: 'https://www.bundesliga.com/assets/clublogo/fca.png',
		textColor: '#000000'
	},
	dflDatalibraryObjectId: 'DFL-OBJ-0026NZ',
	index: 1,
	name: 'Lukas Klünter',
	rank: 1,
	value: 15
};
