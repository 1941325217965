// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  width: auto;
  height: 100%;
  display: inline-block;
  -webkit-user-select: none;
          user-select: none;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBsYXllci1pbWFnZS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNDLFdBQUE7RUFDQSxZQUFBO0VBQ0EscUJBQUE7RUFDQSx5QkFBQTtVQUFBLGlCQUFBO0FBQ0QiLCJmaWxlIjoicGxheWVyLWltYWdlLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiaW1nIHtcblx0d2lkdGg6IGF1dG87XG5cdGhlaWdodDogMTAwJTtcblx0ZGlzcGxheTogaW5saW5lLWJsb2NrO1xuXHR1c2VyLXNlbGVjdDogbm9uZTtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/commons/src/lib/component/player-image/player-image.component.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,YAAA;EACA,qBAAA;EACA,yBAAA;UAAA,iBAAA;AACD;AAEA,4bAA4b","sourcesContent":["img {\n\twidth: auto;\n\theight: 100%;\n\tdisplay: inline-block;\n\tuser-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
