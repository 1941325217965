import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { zip } from 'rxjs';
import { ScriptLoaderService, ScriptLoaderServiceStatus } from '@nx-bundesliga/bundesliga-com/services/script-loader';
import { WINDOW } from '@nx-bundesliga/bundesliga-com/framework/window';

@Injectable({
	providedIn: 'root'
})
export class InstanaScriptsLoaderService {
	isBrowser: boolean;
	constructor(private loader: ScriptLoaderService, private router: Router, private activatedRoute: ActivatedRoute, @Inject(WINDOW) private window: Window, @Inject(PLATFORM_ID) private platformId: Object) {
		this.loader.setConfigKey('instana.apikey', 'instanaApikey');
		this.isBrowser = isPlatformBrowser(this.platformId);
	}

	init() {
		zip(this.loader.load('instana')[0], this.loader.load('instana-sdk')[0]).subscribe(
			([loaded, sdkLoaded]) => {
				if (this.isBrowser === true) {
					// enable instana router listener when successfully loaded
					if (sdkLoaded === 'loaded' || sdkLoaded === 'alreadyloaded') {
						this.router.events.subscribe((event) => {
							const route = this.router.url !== null ? this.router.url : 'notset';

							if (event instanceof NavigationStart) {
								(<any>this.window).ineum('page', route);
								(<any>this.window).ineum('startSpaPageTransition');
							} else if (event instanceof NavigationEnd) {
								(<any>this.window).ineum('meta', 'component', route);
								(<any>this.window).ineum('endSpaPageTransition', {
									status: 'completed'
								});
							} else if (event instanceof NavigationError) {
								(<any>this.window).ineum('endSpaPageTransition', {
									status: 'error',
									url: (<any>this.window).location.href,
									explanation: event.error.toString()
								});
							} else if (event instanceof NavigationCancel) {
								(<any>this.window).ineum('endSpaPageTransition', {
									status: 'aborted',
									url: (<any>this.window).location.href,
									explanation: event.reason
								});
							}
						});
					}
				}
			},
			() => {
				// do nothing if rejected. Meaning script loading was blocked
			}
		);
	}
}
