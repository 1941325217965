/**
 * Interface for editorial special page, e.g.: https://bundesliga.com/en/partner/tagheuer
 */
import { EditorialMetaData } from '../bundesliga/common.model';
import { BundesligaEditorialMedia, EditorialImage, EditorialVideo, Languages } from './editorial.model';

/**
 * @todo uhm.. this is a flat out lie!
 */
export interface EditorialSpecialPageTeaser {
	type: 'teaser';
	url: string;
	headline: string;
	headlineShort?: string;
	text?: string;
	textShort?: string;
	desktop: any;
	mobile: any;
	imageCaption?: string;
	imageAltText?: string;
	description?: string;
	parameters?: string;
	target?: '_self' | '_blank';
	relationship?: 'follow' | 'nofollow';
	hasVideo?: boolean;
	isPinned?: boolean;
}

// wtf is this?
export interface SpecialPageAwards {
	votingEnabled?: string;
	votingUrl?: string;
	headline?: string;
	description?: string;
	image?: any;
	teasers?: EditorialSpecialPageTeaser[];
}

// and this?
export interface EditorialSpecialPage {
	type: 'special_page';
	content: BundesligaEditorialSpecialPageMedia[];
	template?: any;
	uuid: string;
	slug: string;
	speakingUrl?: string;
	cisId?: string;
	author: string;
	publishedAt: string;
	meta: EditorialMetaData;
	language: Languages;
	categories?: string[];
	tracking?: any;
}

export type BundesligaEditorialSpecialPageMedia = BundesligaEditorialMedia | EditorialSpecialPageSingleLine | EditorialSpecialPageMultiLine | EditorialSpecialPageHidden | EditorialSpecialPageTeaser;

export interface EditorialSpecialPageSingleLine {
	type: 'singleline';
	singleLine: string;
}

export interface EditorialSpecialPageMultiLine {
	type: 'multiline';
	multiLine: string;
}

export interface EditorialSpecialPageHidden {
	type: 'hidden';
	multiLine: string;
}

/**
 * sweepstake pages
 */
export interface Sweepstake extends EditorialSpecialPage {
	sweepstakeStart?: string;
	sweepstakeEnd?: string;
	sweepstakeStartLabel?: string;
	sweepstakeEndLabel?: string;
	groupIdOkta?: string;
	hero?: EditorialImage | EditorialVideo;
	pageTitle?: string;
	pageAbstract?: string;
	partner?: EditorialImage;
	legalText?: string;
}

/**
 * voting pages
 */
export interface Voting extends EditorialSpecialPage {
	backgroundUrlPortait?: string;
	backgroundUrlLandScape?: string;
	apesterEnabled?: boolean;
	apesterId?: string;
}
