import { Component, Input, OnChanges } from '@angular/core';
import { Playtime } from '@nx-bundesliga/models';

type LiveAnimationLayouts = 'xsmall' | 'regular' | 'large';

@Component({
	selector: 'live-animation',
	templateUrl: './live-animation.component.html',
	styleUrls: ['./styles/live-animation.component.scss', './styles/animations.scss']
})
export class LiveAnimationComponent implements OnChanges {
	@Input() playtime: Playtime;
	@Input() layout: LiveAnimationLayouts = 'regular';
	@Input() matchStatus: string;
	@Input() live = true;

	constructor() {}

	ngOnChanges() {
		if (this.matchStatus && this.matchStatus !== '') {
			this.live = ['FIRST_HALF', 'SECOND_HALF', 'PRE_EXTRA', 'FIRST_HALF_EXTRA', 'SECOND_HALF_EXTRA', 'PRE_PENALTY', 'PENALTY'].includes(this.matchStatus);
		}
	}
}
