import { AfterViewInit, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Themes, ThemingService } from '../../../../services/theming-service/theming.service';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';

@Component({
	selector: 'header-competitionnavigation',
	templateUrl: './competitionnavigation.component.html',
	styleUrls: ['./competitionnavigation.component.scss']
})
export class CompetitionnavigationComponent implements AfterViewInit {
	@Input() language = 'de';
	@Input() competition = 'bundesliga';
	@Input() naviStructure = [];
	public currentThemingMode$: Observable<Themes>;
	public isProduction = false;

	constructor(private themingService: ThemingService, private configService: ConfigService) {
		this.isProduction = this.configService.getSettings('build', 'prod') === 'prod';
	}

	ngAfterViewInit(): void {
		this.currentThemingMode$ = this.themingService.theme$;
	}
}
