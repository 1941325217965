import { Injectable } from '@angular/core';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { BUNDESLIGA_SEASONNAMES, BUNDESLIGA_SEASONS } from '@nx-bundesliga/models';

@Injectable({ providedIn: 'root' })
export class DflDatalibraryService {
	public matchdays = {
		'0001K1': [
			'DFL-DAY-004BMT',
			'DFL-DAY-004BMU',
			'DFL-DAY-004BMV',
			'DFL-DAY-004BMW',
			'DFL-DAY-004BMX',
			'DFL-DAY-004BMY',
			'DFL-DAY-004BMZ',
			'DFL-DAY-004BN0',
			'DFL-DAY-004BN1',
			'DFL-DAY-004BN2',
			'DFL-DAY-004BN3',
			'DFL-DAY-004BN4',
			'DFL-DAY-004BN5',
			'DFL-DAY-004BN6',
			'DFL-DAY-004BN7',
			'DFL-DAY-004BN8',
			'DFL-DAY-004BN9',
			'DFL-DAY-004BNA',
			'DFL-DAY-004BNB',
			'DFL-DAY-004BNC',
			'DFL-DAY-004BND',
			'DFL-DAY-004BNE',
			'DFL-DAY-004BNF',
			'DFL-DAY-004BNG',
			'DFL-DAY-004BNH',
			'DFL-DAY-004BNI',
			'DFL-DAY-004BNJ',
			'DFL-DAY-004BNK',
			'DFL-DAY-004BNL',
			'DFL-DAY-004BNM',
			'DFL-DAY-004BNN',
			'DFL-DAY-004BNO',
			'DFL-DAY-004BNP',
			'DFL-DAY-004BNQ'
		],
		'0001K2': [
			'DFL-DAY-004BPL',
			'DFL-DAY-004BPM',
			'DFL-DAY-004BPN',
			'DFL-DAY-004BPO',
			'DFL-DAY-004BPP',
			'DFL-DAY-004BPQ',
			'DFL-DAY-004BPR',
			'DFL-DAY-004BPS',
			'DFL-DAY-004BPT',
			'DFL-DAY-004BPU',
			'DFL-DAY-004BPV',
			'DFL-DAY-004BPW',
			'DFL-DAY-004BPX',
			'DFL-DAY-004BPY',
			'DFL-DAY-004BPZ',
			'DFL-DAY-004BQ0',
			'DFL-DAY-004BQ1',
			'DFL-DAY-004BQ2',
			'DFL-DAY-004BQ3',
			'DFL-DAY-004BQ4',
			'DFL-DAY-004BQ5',
			'DFL-DAY-004BQ6',
			'DFL-DAY-004BQ7',
			'DFL-DAY-004BQ8',
			'DFL-DAY-004BQ9',
			'DFL-DAY-004BQA',
			'DFL-DAY-004BQB',
			'DFL-DAY-004BQC',
			'DFL-DAY-004BQD',
			'DFL-DAY-004BQE',
			'DFL-DAY-004BQF',
			'DFL-DAY-004BQG',
			'DFL-DAY-004BQH',
			'DFL-DAY-004BQI'
		],
		'0001K3': [
			'DFL-DAY-004BSD',
			'DFL-DAY-004BSE',
			'DFL-DAY-004BSF',
			'DFL-DAY-004BSG',
			'DFL-DAY-004BSH',
			'DFL-DAY-004BSI',
			'DFL-DAY-004BSJ',
			'DFL-DAY-004BSK',
			'DFL-DAY-004BSL',
			'DFL-DAY-004BSM',
			'DFL-DAY-004BSN',
			'DFL-DAY-004BSO',
			'DFL-DAY-004BSP',
			'DFL-DAY-004BSQ',
			'DFL-DAY-004BSR',
			'DFL-DAY-004BSS',
			'DFL-DAY-004BST',
			'DFL-DAY-004BSU',
			'DFL-DAY-004BSV',
			'DFL-DAY-004BSW',
			'DFL-DAY-004BSX',
			'DFL-DAY-004BSY',
			'DFL-DAY-004BSZ',
			'DFL-DAY-004BT0',
			'DFL-DAY-004BT1',
			'DFL-DAY-004BT2',
			'DFL-DAY-004BT3',
			'DFL-DAY-004BT4',
			'DFL-DAY-004BT5',
			'DFL-DAY-004BT6',
			'DFL-DAY-004BT7',
			'DFL-DAY-004BT8',
			'DFL-DAY-004BT9',
			'DFL-DAY-004BTA'
		],
		'0001K4': [
			'DFL-DAY-004BV5',
			'DFL-DAY-004BV6',
			'DFL-DAY-004BV7',
			'DFL-DAY-004BV8',
			'DFL-DAY-004BV9',
			'DFL-DAY-004BVA',
			'DFL-DAY-004BVB',
			'DFL-DAY-004BVC',
			'DFL-DAY-004BVD',
			'DFL-DAY-004BVE',
			'DFL-DAY-004BVF',
			'DFL-DAY-004BVG',
			'DFL-DAY-004BVH',
			'DFL-DAY-004BVI',
			'DFL-DAY-004BVJ',
			'DFL-DAY-004BVK',
			'DFL-DAY-004BVL',
			'DFL-DAY-004BVM',
			'DFL-DAY-004BVN',
			'DFL-DAY-004BVO',
			'DFL-DAY-004BVP',
			'DFL-DAY-004BVQ',
			'DFL-DAY-004BVR',
			'DFL-DAY-004BVS',
			'DFL-DAY-004BVT',
			'DFL-DAY-004BVU',
			'DFL-DAY-004BVV',
			'DFL-DAY-004BVW',
			'DFL-DAY-004BVX',
			'DFL-DAY-004BVY',
			'DFL-DAY-004BVZ',
			'DFL-DAY-004BW0',
			'DFL-DAY-004BW1',
			'DFL-DAY-004BW2'
		],
		'0001K5': [
			'DFL-DAY-004BXX',
			'DFL-DAY-004BXY',
			'DFL-DAY-004BXZ',
			'DFL-DAY-004BY0',
			'DFL-DAY-004BY1',
			'DFL-DAY-004BY2',
			'DFL-DAY-004BY3',
			'DFL-DAY-004BY4',
			'DFL-DAY-004BY5',
			'DFL-DAY-004BY6',
			'DFL-DAY-004BY7',
			'DFL-DAY-004BY8',
			'DFL-DAY-004BY9',
			'DFL-DAY-004BYA',
			'DFL-DAY-004BYB',
			'DFL-DAY-004BYC',
			'DFL-DAY-004BYD',
			'DFL-DAY-004BYE',
			'DFL-DAY-004BYF',
			'DFL-DAY-004BYG',
			'DFL-DAY-004BYH',
			'DFL-DAY-004BYI',
			'DFL-DAY-004BYJ',
			'DFL-DAY-004BYK',
			'DFL-DAY-004BYL',
			'DFL-DAY-004BYM',
			'DFL-DAY-004BYN',
			'DFL-DAY-004BYO',
			'DFL-DAY-004BYP',
			'DFL-DAY-004BYQ',
			'DFL-DAY-004BYR',
			'DFL-DAY-004BYS',
			'DFL-DAY-004BYT',
			'DFL-DAY-004BYU'
		],
		'0001K6': [
			'DFL-DAY-004C0P',
			'DFL-DAY-004C0Q',
			'DFL-DAY-004C0R',
			'DFL-DAY-004C0S',
			'DFL-DAY-004C0T',
			'DFL-DAY-004C0U',
			'DFL-DAY-004C0V',
			'DFL-DAY-004C0W',
			'DFL-DAY-004C0X',
			'DFL-DAY-004C0Y',
			'DFL-DAY-004C0Z',
			'DFL-DAY-004C10',
			'DFL-DAY-004C11',
			'DFL-DAY-004C12',
			'DFL-DAY-004C13',
			'DFL-DAY-004C14',
			'DFL-DAY-004C15',
			'DFL-DAY-004C16',
			'DFL-DAY-004C17',
			'DFL-DAY-004C18',
			'DFL-DAY-004C19',
			'DFL-DAY-004C1A',
			'DFL-DAY-004C1B',
			'DFL-DAY-004C1C',
			'DFL-DAY-004C1D',
			'DFL-DAY-004C1E',
			'DFL-DAY-004C1F',
			'DFL-DAY-004C1G',
			'DFL-DAY-004C1H',
			'DFL-DAY-004C1I',
			'DFL-DAY-004C1J',
			'DFL-DAY-004C1K',
			'DFL-DAY-004C1L',
			'DFL-DAY-004C1M'
		],
		'0001K7': [
			'DFL-DAY-004C3H',
			'DFL-DAY-004C3I',
			'DFL-DAY-004C3J',
			'DFL-DAY-004C3K',
			'DFL-DAY-004C3L',
			'DFL-DAY-004C3M',
			'DFL-DAY-004C3N',
			'DFL-DAY-004C3O',
			'DFL-DAY-004C3P',
			'DFL-DAY-004C3Q',
			'DFL-DAY-004C3R',
			'DFL-DAY-004C3S',
			'DFL-DAY-004C3T',
			'DFL-DAY-004C3U',
			'DFL-DAY-004C3V',
			'DFL-DAY-004C3W',
			'DFL-DAY-004C3X',
			'DFL-DAY-004C3Y',
			'DFL-DAY-004C3Z',
			'DFL-DAY-004C40',
			'DFL-DAY-004C41',
			'DFL-DAY-004C42',
			'DFL-DAY-004C43',
			'DFL-DAY-004C44',
			'DFL-DAY-004C45',
			'DFL-DAY-004C46',
			'DFL-DAY-004C47',
			'DFL-DAY-004C48',
			'DFL-DAY-004C49',
			'DFL-DAY-004C4A',
			'DFL-DAY-004C4B',
			'DFL-DAY-004C4C',
			'DFL-DAY-004C4D',
			'DFL-DAY-004C4E'
		],
		'0001K8': [
			'DFL-DAY-004C69',
			'DFL-DAY-004C6A',
			'DFL-DAY-004C6B',
			'DFL-DAY-004C6C',
			'DFL-DAY-004C6D',
			'DFL-DAY-004C6E',
			'DFL-DAY-004C6F',
			'DFL-DAY-004C6G',
			'DFL-DAY-004C6H',
			'DFL-DAY-004C6I',
			'DFL-DAY-004C6J',
			'DFL-DAY-004C6K',
			'DFL-DAY-004C6L',
			'DFL-DAY-004C6M',
			'DFL-DAY-004C6N',
			'DFL-DAY-004C6O',
			'DFL-DAY-004C6P',
			'DFL-DAY-004C6Q',
			'DFL-DAY-004C6R',
			'DFL-DAY-004C6S',
			'DFL-DAY-004C6T',
			'DFL-DAY-004C6U',
			'DFL-DAY-004C6V',
			'DFL-DAY-004C6W',
			'DFL-DAY-004C6X',
			'DFL-DAY-004C6Y',
			'DFL-DAY-004C6Z',
			'DFL-DAY-004C70',
			'DFL-DAY-004C71',
			'DFL-DAY-004C72',
			'DFL-DAY-004C73',
			'DFL-DAY-004C74',
			'DFL-DAY-004C75',
			'DFL-DAY-004C76'
		]
	};

	private seasonNames = {
		'DFL-SEA-0001K8': '2024/2025',
		'DFL-SEA-0001K7': '2023/2024',
		'DFL-SEA-0001K6': '2022/2023',
		'DFL-SEA-0001K5': '2021/2022',
		'DFL-SEA-0001K4': '2020/2021',
		'DFL-SEA-0001K3': '2019/2020',
		'DFL-SEA-0001K2': '2018/2019',
		'DFL-SEA-0001K1': '2017/2018',
		'DFL-SEA-0001K0': '2016/2017'
	};

	private seasonNamesForUrl = ['2024-2025', '2023-2024', '2022-2023', '2021-2022', '2020-2021', '2019-2020', '2018-2019', '2017-2018', '2016-2017'];

	private seasonsNamesforStats = ['2024-2025', '2023-2024', '2022-2023', '2021-2022', '2020-2021', '2019-2020'];

	private seasonsNamesforFixtures = ['2024-2025', '2023-2024', '2022-2023', '2021-2022', '2020-2021', '2019-2020', '2018-2019', '2017-2018'];

	constructor(public configService: ConfigService) {}

	public getSeasonByCompetitionId(competitonId: string): string {
		return BUNDESLIGA_SEASONS.CURRENT;
	}

	/**
	 * Returns the URL friendly name for a given DFL DataLibrary Season ID (i.e. DFL-SEA-0001K2 --> 2018/2019).
	 * If no valid ID is given the friendly name for the current season is returned.
	 *
	 * @param id
	 * @param friendly boolean if true replaces the dash with a hyphen to be url friendly.
	 */
	getSeasonNameById(id: string, friendly = false): string {
		// eslint-disable-next-line no-prototype-builtins
		if (this.seasonNames.hasOwnProperty(id)) {
			return friendly ? this.replaceSeasonNameSeperator(this.seasonNames[id]) : this.seasonNames[id];
		}
		return friendly ? this.replaceSeasonNameSeperator(BUNDESLIGA_SEASONNAMES.CURRENT) : BUNDESLIGA_SEASONNAMES.CURRENT;
	}

	/**
	 * Returns a list of seasons with minus as delimter.
	 *
	 * @param delimiter string
	 */
	public getSeasonsWithDelimiter(delimiter?: string): string[] {
		let seasonNames = this.seasonNamesForUrl;
		if (delimiter && delimiter !== '' && delimiter !== '-') {
			seasonNames = seasonNames.map(function (item, index) {
				return item.replace('-', delimiter);
			});
		}
		return seasonNames;
	}

	/**
	 * Returns a list of seasons.
	 *
	 * @param isFixture boolean
	 */
	public getSeasons(isFixture: boolean): string[] {
		const seasonNames = isFixture ? this.seasonsNamesforFixtures : this.seasonsNamesforStats;
		return seasonNames;
	}

	/**
	 * Returns an array of seasons with custom delimter and prefix.
	 *
	 * @param delimiter string
	 * @param prefix
	 * @param maxSeasons
	 * @param suffix
	 */
	public getSeasonsArray(maxSeasons = 99, delimiter = '/', prefix = '', suffix = ''): { seasonId: string; name: string }[] {
		return Object.entries(this.seasonNames)
			.slice(0, Math.min(maxSeasons, Object.keys(this.seasonNames).length))
			.map(([key, value]) => ({
				seasonId: key,
				name: prefix + value.replace('/', delimiter) + suffix
			}));
	}

	/**
	 * Returns the URL friendly name for a given DFL DataLibrary Season ID (i.e.  2018/2019 --> 2018-2019).
	 */
	replaceSeasonNameSeperator(seasonName: string): string {
		return seasonName.replace('/', '-');
	}

	/**
	 * Returns the DFL DataLibrary Season ID (i.e. DFL-SEA-0001K2).
	 * @param seasonName 2018-2019 or 2018/2019
	 */
	getSeasonIdBySeasonName(seasonName: string): string {
		for (const seasonId in this.seasonNames) {
			// eslint-disable-next-line no-prototype-builtins
			if (this.seasonNames.hasOwnProperty(seasonId) && this.replaceSeasonNameSeperator(this.seasonNames[seasonId]) === this.replaceSeasonNameSeperator(seasonName)) {
				return seasonId;
			}
		}
	}

	/**
	 * Returns if the season name has a valid pattern
	 */
	isValidSeasonName(season: string) {
		const regex = new RegExp('\\d{4}-\\d{4}$');
		return regex.test(season);
	}

	/**
	 * Returns if a club id is a valid sts club id
	 */
	isValidClubId(clubId: string): boolean {
		const regex = new RegExp(/^DFL-CLU-[0-9A-Za-z]{6}$/);
		return regex.test(clubId);
	}

	/**
	 * Returns if a club id is a valid sts club id
	 */
	isBundesligaCompetition(competitionId: string): boolean {
		const bundesligaCompetitionIds = ['DFL-COM-000001', 'DFL-COM-000002', 'DFL-COM-000003', 'DFL-COM-000004', 'DFL-COM-000005'];
		return bundesligaCompetitionIds.includes(competitionId);
	}

	/**
	 * Returns if a club id is a valid sts club id
	 */
	isNationalTeamCompetition(competitionId: string): boolean {
		const nationalTeamsCompetitionIds = ['DFL-COM-J0002E', 'DFL-COM-J00028'];
		return nationalTeamsCompetitionIds.includes(competitionId);
	}

	/**
	 *
	 * @param number
	 * @param season
	 */
	public getMatchdayIdByNumber(number: number, season = '0001K5') {
		season = season.replace('DFL-SEA-', ''); /* you'll never know...*/
		if (this.matchdays[season] && this.matchdays[season][number - 1]) {
			return this.matchdays[season][number - 1];
		}
		return this.matchdays[season][0];
	}

	public hasCurrentSeasonStarted(competitionId: string) {
		const seasonStarDate = this.configService.getSettings(`${competitionId}.season.firstMatchdayStart`, new Date());
		return new Date(seasonStarDate).getTime() < new Date().getTime();
	}
}
