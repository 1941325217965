// angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// libs
import { TranslateModule } from '@ngx-translate/core';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { DflNgxLazyLoadModule } from '@nx-bundesliga/shared/dfl-ngx-lazyload';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { DfpAdsModule } from '@nx-bundesliga/bundesliga-com/components/dfp-ads';

const maskConfig: Partial<IConfig> = {
	validation: false,
	dropSpecialCharacters: false,
	showMaskTyped: true
};

const FORM_FIELD_DEFAULT: MatFormFieldDefaultOptions = {
	subscriptSizing: 'dynamic'
};

const TOOLTIP_DEFAULT: MatTooltipDefaultOptions = {
	showDelay: 100,
	touchGestures: 'on',
	hideDelay: 0,
	touchendHideDelay: 0
};

@NgModule({
	imports: [NgxMaskDirective, NgxMaskPipe],
	exports: [CommonModule, FormsModule, TranslateModule, CommonsModule, RouterModule, MatFormFieldModule, MatButtonModule, MatSlideToggleModule, MatCheckboxModule, MatSelectModule, MatInputModule, DflNgxLazyLoadModule, DfpAdsModule, NgxMaskDirective, NgxMaskPipe],
	providers: [
		provideNgxMask(maskConfig),
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: FORM_FIELD_DEFAULT
		},
		{
			provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
			useValue: TOOLTIP_DEFAULT
		}
	]
})
export class SharedModule {}
