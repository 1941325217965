export interface PlayerStatsRankings<T> {
	assists?: T;
	cards?: T;
	cardsYellow?: T;
	crossesFromPlay?: T;
	distanceCovered?: T;
	foulsAgainstOpponent?: T;
	goalkeeperSaves?: T;
	intensiveRuns?: T;
	maximumSpeed?: T;
	ownGoals?: T;
	passesFromPlayRatio?: T;
	penalties?: T;
	penaltiesSuccessful?: T;
	shotsAtGoal?: T;
	shotsAtGoalSuccessful?: T;
	shotsAtGoalWoodWork?: T;
	sprints?: T;
	tacklingGamesAirWon?: T;
	tacklingGamesWon?: T;
}
export type PlayerStatsRankingsMetrics = keyof PlayerStatsRankings<StatsPlayerRankingsEntry[]>;

export interface StatsPlayerRankingEntries {
	[playerId: string]: StatsPlayerRankingsEntry;
}

export interface StatsPlayerRankingsEntry {
	club: {
		color: string;
		dflDatalibraryClubId: string;
		logoUrl: string;
		textColor: string;
	};
	dflDatalibraryObjectId: string;
	index: number;
	name: string;
	rank: number;
	imageUrl?: string;
	slug?: string;
	value: number;
}
