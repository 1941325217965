import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'embedPlatformName'
})
export class EmbedPlatformNamePipe implements PipeTransform {
	transform(value: string): any {
		if (value === 'twitter' || value === 'Twitter') {
			return 'x';
		}
		return value;
	}
}
