import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { logout } from '@nx-bundesliga/bundesliga-com/framework/store-actions';
import { getAuthenticated } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { ProfileUser } from '@nx-bundesliga/models';
import { Observable } from 'rxjs';

@Component({
	selector: 'header-mobilenavigation',
	templateUrl: './mobilenavigation.component.html',
	styleUrls: ['./mobilenavigation.component.scss']
})
export class MobilenavigationComponent implements OnInit {
	@Input() language = 'de';
	@Input() competition = 'bundesliga';
	@Input() naviStructureMain = [];
	@Input() naviStructureMeta = [];
	@Input() open = false;
	@Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>();
	public userAuthorized$: Observable<boolean>;
	public competitionUrlAccount = 'bundesliga';

	constructor(private pstore: Store<ProfileUser>) {}

	ngOnInit(): void {
		this.userAuthorized$ = this.pstore.pipe(select(getAuthenticated));

		// redirect euro etc to bundesliga
		this.competitionUrlAccount = this.competition.includes('bundesliga') ? this.competition : 'bundesliga';
	}

	public logout() {
		this.pstore.dispatch(logout());
	}

	trackByLabel(index, item) {
		return item.label;
	}

	public navigationClick() {
		this.openChange.emit(false);
	}
}
