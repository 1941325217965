import { ActionReducerMap } from '@ngrx/store';
import { I18NState, initialAppstateLanguage, CompetitionState, initialAppstateCompetition, ProfileState, initialAppstateProfile, ClubState, initialAppstateClub } from '@nx-bundesliga/models';
import { reducer as clubsReducers } from './club/club.reducer';
import { reducer as profileReducers } from './profile/profile.reducer';
import { reducer as languageReducers } from './language/language.reducer';
import { reducer as competitionReducers } from './competition/competition.reducer';

export interface AppState {
	i18n: I18NState;
	competition: CompetitionState;
	profile: ProfileState;
	clubs: ClubState;
}

export const appReducers: ActionReducerMap<AppState> = {
	i18n: languageReducers,
	competition: competitionReducers,
	profile: profileReducers,
	clubs: clubsReducers
};

export const INITIAL_APPSTATE: AppState = {
	i18n: initialAppstateLanguage,
	competition: initialAppstateCompetition,
	profile: initialAppstateProfile,
	clubs: initialAppstateClub
};
