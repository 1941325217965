import { Component, Input } from '@angular/core';

/**
 * article: hero element in editorial-detail
 * teaser: teaser/list-item used everywhere (e.g. all three-column-grids (load-more on homepage and tabs))
 * matchcenter: header of matchcenter
 * club-header: ...
 * club-tile: ...
 *
 */
export type DflLoaderTeaserLayouts = 'article' | 'teaser' | 'matchcenter' | 'club-tile' | 'club-header';

@Component({
	selector: 'dfl-loader-sharebar',
	templateUrl: './dfl-loader-sharebar.component.html',
	styleUrls: ['./dfl-loader-sharebar.component.scss']
})
export class DflLoaderSharebarComponent {
	@Input() layout: DflLoaderTeaserLayouts = 'teaser';
	@Input() webview = false;
	constructor() {}
}
