export interface Coordinates {
	lat: number;
	lng: number;
}

export interface BundesligaPlaceImage {
	src: string;
	copyright: string;
	caption?: string;
	type?: 'gallery' | 'header';
}

/**
 * Generic model used by the Bundesliga BarFinder.
 * Currently the only available places are therefore bars ;).
 */
export interface BundesligaPlace {
	id: string;
	name: string;

	city: string;
	state: string;
	address: string;
	zip: string;
	coordinates: Coordinates;

	openingHours: GooglePlacesOpeningHours;
	website?: string;
	phone?: string;
	email?: string;
	country: string;
	associatedWith?: BundesligaPlaceAssociation[];
	description?: string;
	images?: BundesligaPlaceImage[];
	teaser: string;
	header: string;

	socialMedia?: BundesligaPlaceSocialMedia[];
}

/**
 * Places (i.e. bars) can be associated with various clubs.
 *
 * Maybe it makes sense to allow associated players as well.
 * For example, Lukas Podolski owns a shitload of shops in cologne?!
 */
export interface BundesligaPlaceAssociation {
	id: string;
	name: string;
	type: 'club' | 'person';

	description?: string;
}

export type BundesLigaPlaceSocialMediaType = 'facebook' | 'instagram' | 'x' | 'googlemaps';

export interface BundesligaPlaceSocialMedia {
	type: BundesLigaPlaceSocialMediaType;
	url: string;
}

/**
 *
 */
export interface GooglePlacesOpeningHours {
	periods: GooglePlacesOpeningHourPeriod[];
}

export interface GooglePlacesOpeningHourPeriod {
	close: GooglePlacesOpeningHourPeriodDay;
	open: GooglePlacesOpeningHourPeriodDay;
}

export interface GooglePlacesOpeningHourPeriodDay {
	day: number;
	time: string;
}

/**
 *
 */
export interface BundesligaPlaceMarker {
	name: string;
	id: string;
	coordinates: Coordinates;
}

/**
 *
 */
export interface BundesligaPlacesCity {
	name: string;
	state?: string;
	coordinates: Coordinates;
}

/**
 * google maps
 */
export interface GoogleMapsUrlParameters {
	'api': number;
	'f': string /* Can be set to q (the standard layout), d (for directions) or l (for local). Defaults to q. */;
	'dirflg': string /* This controls the route that will be planned. Can be set to h for no motorways, r for public transport only or w for walking directions. */;
	'origin'?: string;
	'destination'?: string;
	'dir_action'?: string;
	'query'?: string;
}
