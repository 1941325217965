export interface BroadcasterLogo {
	'logoUrl': string;
	'name': string;
	'url': string;
	'country'?: string;
}

type BroadcasterLogoCategories = 'REGULAR' | 'WIDE';

export interface BroadcasterByIpResponse {
	broadcasters: BroadcasterByIp[];
	country: string;
}

export interface BroadcasterByIp {
	country: string;
	name: string;
	url: string;
	logo: BroadcasterByIpLogoVariant;
}

export interface BroadcasterByIpLogoVariant {
	light: string;
	dark: string;
}
