/*
  semi-common?!
 */
import { BundesligaEmbed, EditorialMetaData } from '../bundesliga/common.model';
import { EditorialTeaser } from './overview.model';
import { TeaserAbstract } from './wiki.model';

enum EmbedVendors {
	'apester',
	'x',
	'facebook',
	'instagram'
}

interface ImageSet {
	src: string;
	copyright: string;
	caption?: string;

	/*
	 * the following fields are returned by the API although we never requested them. So they're optional now.
	 */
	alt?: string;
	credit?: string;
	keywords?: string[];
}

/**
 *
 */
export interface EditorialHeadlines {
	defaultHeadline: string;
	shortHeadline?: string;
}

/**
 *
 */
export interface EditorialImage {
	type: 'image';
	desktop: ImageSet;
	mobile?: ImageSet;
}

/**
 *
 */
export interface EditorialLiveMatch {
	type: 'liveMatch';
	liveMatchId: string;
}

/**
 * @deprecated use common/BundesligaEmbed instead which has the same properties
 *
 */
/*export interface EditorialEmbed {
  type: 'embed';
  id: string;
  vendor: EmbedVendors;
  embedType?: string;
  headline?: string;
}*/

/**
 *
 */
export interface EditorialVideo {
	type: 'video';
	videoId: string;
	headline?: string;
	videoType?: string;
	skinId?: string;
	poster?: string;
	file?: string;
	text?: string;
	desc?: string;
	description?: string;
	published?: number;
	duration?: number;
}

/**
 *
 */
export interface EditorialParagraph {
	type?: 'paragraph';
	headline?: string;
	body: string[];
}

/**
 *
 */
export interface EditorialBase {
	uuid: string;
	cisId?: string;
	slug: string;
	speakingUrl?: string;
	author?: string;
	publishedAt: string;
	relatedArticles?: EditorialTeaser[];
	editorialAbstract: string;
	meta: EditorialMetaData;
	headlines: EditorialHeadlines;
	language: Languages;
	paragraphs: EditorialParagraph[];
	teaserAbstract?: TeaserAbstract;
	categories?: string[];
	players?: string[];
	clubs?: string[];
	media?: BundesligaEditorialMedia[];
	tracking?: any;
	isVideo?: boolean;
	fsId?: string;
	fsType?: string;
	// these properties are extracted by the editorialService from the media property and are not part of the API response!
	// remaining?: BundesligaEditorialMedia[]; // after each paragraph one media element should be displayed, everything else ends up here
	hero?: BundesligaEditorialMedia; // first image or video to be displayed in an article
	// shifted?: boolean;
}

/**
 * Type for all types of currently implemented editorial articles.
 */
export type ArticleTypes = 'news' | 'feature' | 'matchdayreport' | 'wiki' | 'live_match';

/**
 * Type for all languages this app supports.
 */
export type Languages = 'de' | 'en' | 'es' | 'fr' | 'ja' | 'cn';

/**
 *
 */
export type BundesligaEditorialMedia = EditorialImage | EditorialVideo | EditorialLiveMatch | BundesligaEmbed;
