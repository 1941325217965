export interface PlayerStats {
	assists: StatsPlayerEntry;
	ballActions: StatsPlayerEntry;
	cardsYellow: StatsPlayerEntry;
	cardsRed: StatsPlayerEntry;
	cardsYellowRed: StatsPlayerEntry;
	crossesFromPlay: StatsPlayerEntry;
	distanceCovered: StatsPlayerEntry;
	foulsAgainstOpponent: StatsPlayerEntry;
	goalkeeperSaves?: StatsPlayerEntry;
	matchesPlayed: StatsPlayerEntry;
	maximumSpeed: StatsPlayerEntry;
	passesFromPlayRatio: StatsPlayerEntry;
	playingTime: StatsPlayerEntry;
	shotsAtGoal: StatsPlayerEntry;
	shotsAtGoalSuccessful: StatsPlayerEntry;
	shotsOffTarget: StatsPlayerEntry;
	shotsOnTarget: StatsPlayerEntry;
	sprints: StatsPlayerEntry;
	tacklingGamesAirWon: StatsPlayerEntry;
	tacklingGamesRatio: StatsPlayerEntry;
	tacklingGamesWon: StatsPlayerEntry;
}

export interface StatsPlayerEntry {
	value: number;
}

export interface PlayerStatsExtended<T> {
	intensiveRuns?: T;
	ownGoals?: T;
	penalties?: T;
	penaltiesSuccessful?: T;
	shotsAtGoalWoodWork?: T;
}
