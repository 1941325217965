import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DflLazyLoadDirective } from './dfl-ngx-lazy-load.directive';

@NgModule({
	imports: [CommonModule],
	declarations: [DflLazyLoadDirective],
	providers: [DflLazyLoadDirective],
	exports: [DflLazyLoadDirective]
})
export class DflNgxLazyLoadModule {}
