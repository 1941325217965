import { Component, Input } from '@angular/core';

@Component({
	selector: 'rating-star',
	templateUrl: 'rating-star.component.html',
	styleUrls: ['rating-star.component.scss']
})
export class RatingStarComponent {
	@Input() public solid: any;
}
