// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.socials {
  justify-content: center;
}
.socials img {
  margin: 20px;
  cursor: pointer;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImZvb3Rlci1zb2NpYWxzLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0MsdUJBQUE7QUFDRDtBQUFDO0VBQ0MsWUFBQTtFQUNBLGVBQUE7QUFFRiIsImZpbGUiOiJmb290ZXItc29jaWFscy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5zb2NpYWxzIHtcblx0anVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG5cdGltZyB7XG5cdFx0bWFyZ2luOiAyMHB4O1xuXHRcdGN1cnNvcjogcG9pbnRlcjtcblx0fVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./apps/bundesliga-com/web/src/app/components/footer/component/footer-socials/footer-socials.component.scss"],"names":[],"mappings":"AAAA;EACC,uBAAA;AACD;AAAC;EACC,YAAA;EACA,eAAA;AAEF;AACA,wbAAwb","sourcesContent":[".socials {\n\tjustify-content: center;\n\timg {\n\t\tmargin: 20px;\n\t\tcursor: pointer;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
