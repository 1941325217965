import { isPlatformBrowser, Location } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { AutoUnsubscribe } from '@nx-bundesliga/ngx-auto-unsubscribe-decorator';
import { Subscription } from 'rxjs';
import { WINDOW } from '@nx-bundesliga/bundesliga-com/framework/window';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';

@Component({
	selector: 'language-switcher',
	templateUrl: './language-switcher.component.html',
	styleUrls: ['../style/switcher.component.scss']
})
export class LanguageSwitcherComponent implements OnInit {
	@Input() language = 'de';
	@Input() competition = 'bundesliga';
	@AutoUnsubscribe() private subscription: Subscription;

	public selectLanguage: any[];

	private readonly isBrowser: boolean;
	constructor(private router: Router, private config: ConfigService, private location: Location, @Inject(WINDOW) private window: Window, @Inject(PLATFORM_ID) platformId: Object) {
		const enabledLanguages = Object.entries(this.config.getSettings('i18n.language', {})).reduce((acc, [lang, val]: [string, any]) => {
			if (val.enabled) {
				acc.push(lang);
			}
			return acc;
		}, []);
		this.selectLanguage = this.config
			.getSettings('i18n.availableLanguages', [])
			.filter((l) => enabledLanguages.includes(l?.code))
			.concat([{ code: 'https://bundesliga.onet.pl', localName: 'Polski' }]);
		this.isBrowser = isPlatformBrowser(platformId);
	}

	// eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
	ngOnInit(): void {}
	public redirectToLanguage(e) {
		if (this.isBrowser) {
			if (e.value.startsWith('http')) {
				// this.window.location.href = e.value;
				this.window.open(e.value, '_blank');
			} else {
				const newPath = this.language + '/bundesliga';

				if (!this.location.isCurrentPathEqualTo(newPath)) {
					this.window.location.href = newPath;
				}
			}
		}
	}
}
