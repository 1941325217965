import { NgModule } from '@angular/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { AnalyticsOnDirective, AnalyticsOnModule } from '@nx-bundesliga/bundesliga-com/framework/analytics';
import { AnalyticsModule, AnalyticsService } from '@nx-bundesliga/bundesliga-com/framework/analytics';
import { SharedModule } from '@nx-bundesliga/bundesliga-com/framework/core';
import { MatchFixtureModule } from '../match-fixture/match-fixture.module';
import { MatchTeamModule } from '../match-team/match-team.module';
import { ScoreBugModule } from '../score-bug/score-bug.module';
import { EpgBroadcasterOverlayComponent } from './component/epg-broadcaster-overlay/epg-broadcaster-overlay.component';

@NgModule({
	imports: [SharedModule, AnalyticsModule, AnalyticsOnModule, MatDialogModule, MatBottomSheetModule, MatchTeamModule, ScoreBugModule, MatchFixtureModule],
	exports: [EpgBroadcasterOverlayComponent],
	declarations: [EpgBroadcasterOverlayComponent],
	providers: [AnalyticsOnDirective, AnalyticsService]
})
export class EpgBroadcasterOverlayModule {}
