import { isPlatformServer, Location } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ScriptLoaderService } from '../../../services/script-loader/script-loader.service';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { Language } from '@nx-bundesliga/models';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';

@Injectable()
export class OnetrustScriptsLoaderService {
	isBrowser: boolean;
	public isWebview = false;
	isAccount = this.urlContainsAccount();

	constructor(private loader: ScriptLoaderService, private route: ActivatedRoute, private configService: ConfigService, private router: Router, @Inject(PLATFORM_ID) private platformId: Object, private readonly lstore: Store<Language>, private location: Location) {
		this.isWebview = this.configService.getSettings('webview', false);
	}

	init() {
		const consentCategories = this.route.queryParamMap.pipe(map((params: ParamMap) => params.get('OptanonConsent')));
		this.lstore
			.pipe(
				switchMap(() => {
					this.loader.setConfigKey('onetrust.dataDomainScript', 'onetrustKey');
					return this.loader.load('onetrust')[0].pipe(take(1));
				}),
				switchMap(() => consentCategories),
				switchMap((consentGroupsFromUrl: string) => {
					if (isPlatformServer(this.platformId)) {
						return of(null);
					}
					let consentedScript = '';
					if (this.isWebview && consentGroupsFromUrl && consentGroupsFromUrl !== '') {
						const consentGroupsSplitted = consentGroupsFromUrl.split(',');
						const consentGroupsSplittedAndValidated = consentGroupsSplitted
							.map((onetrustCategory, index) => {
								const category = onetrustCategory.split(':');
								if (category[0] && category[0] !== '' && category[0].match(/C000\d/gi) && category[1] && category[1] !== '') {
									if (this._isValidOnetrustCategoryValue(category[1])) {
										return category[0] + ':' + category[1];
									}
									return '';
								} else {
									return '';
								}
							})
							.filter((e) => e); // remove empty / not valid categories
						consentedScript = consentGroupsSplittedAndValidated ? `var OTExternalConsent = {"USPrivacy":"1---","addtlString":"","consentedDate":"${new Date().toISOString()}","groups":"${consentGroupsSplittedAndValidated}","tcString":""};` : '';
					}
					const oneTrustBannerScript = {
						'nomodule': true,
						'name': 'onetrust-wrapper-banner',
						'body': `{function OptanonWrapper() {if (OneTrust.IsAlertBoxClosed() === false) {var banner = document.getElementById("onetrust-banner-sdk");banner.style = "display: block;";const tagsH2 = [...banner.getElementsByTagName('h2')];tagsH2.forEach(tag => otreplace(tag,"ot-h2"));const tagsH3 = [...banner.getElementsByTagName('h3')];tagsH3.forEach(tag => otreplace(tag,"ot-h3"));const tagsH4 = [...banner.getElementsByTagName('h4')];tagsH4.forEach(tag => otreplace(tag,"ot-h4"));}function otreplace(tag,otclass) {const parent = tag.parentNode;const newElement = document.createElement("div");if(tag.id != undefined) {newElement.setAttribute("id", ""+tag.id+"");}newElement.classList = tag.classList;newElement.classList.add(otclass);newElement.innerHTML = tag.innerHTML;parent.insertBefore(newElement,tag);parent.removeChild(tag);}}} ${consentedScript}`
					};
					return this.loader.load(oneTrustBannerScript)[0].pipe(take(1));
				})
			)
			.subscribe(
				() => {},
				(error) => {
					console.warn(`onetrust.loader.error: ${error}`);
				}
			);
	}

	/**
	 * _isValidOnetrustCategoryValue
	 * the onetrust category value is -1, 0 or 1
	 * @param categoryValue
	 * @private
	 */
	private _isValidOnetrustCategoryValue(categoryValue: string): boolean {
		const value = parseInt(categoryValue.substring(0, 1), 10);
		return value >= -1 && value <= 1;
	}

	private urlContainsAccount(): boolean {
		const currentUrl = this.location.path();
		if (currentUrl.includes('account')) {
			return true;
		}
		return false;
	}

	public urlContainsSuperCupVoting(): boolean {
		const currentUrl = this.location.path();

		if (currentUrl.includes('man-of-the-match-voting-supercup-2024')) {
			return true;
		} else return false;
	}
}
