import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FetchPriorityType, ImageType, LoadingType } from './models/dfl-image.type';

@Component({
	selector: 'dfl-image',
	templateUrl: './dfl-image.component.html',
	styleUrls: ['./dfl-image.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DflImageComponent implements OnInit {
	@Input() height = 0;
	@Input() width = 0;
	@Input() alt = '';
	@Input() dark = '';
	@Input() light = '';
	@Input() loading: LoadingType = 'eager';
	@Input() fetchPriority: FetchPriorityType = 'auto';

	loadingValue: string;
	heightValue: number | '100%';
	imageType: ImageType;

	constructor() {}

	ngOnInit(): void {
		this.imageType = this.determineImageType();
		this.loadingValue = this.determineLoadingValue();
		this.heightValue = this.determineHeight();
	}

	determineHeight(): number | '100%' {
		return this.height > 0 ? this.height : '100%';
	}

	determineHasSingleSrc(): boolean {
		return this.dark === '';
	}

	determineHasFixedSize(): boolean {
		return this.height > 0 && this.width > 0;
	}

	determineHasPriority(): boolean {
		return this.fetchPriority === 'high';
	}

	determineLoadingValue(): string {
		return this.fetchPriority ? null : this.loading ? this.loading : 'auto';
	}

	determineImageType(): ImageType {
		if (!this.determineHasFixedSize() && !this.determineHasSingleSrc()) return 'defaultImage';
		if (this.determineHasFixedSize() && this.determineHasPriority()) return 'fixedSizeWithPriorityImage';
		if (this.determineHasFixedSize() && !this.determineHasPriority()) return 'fixedSizeImage';
		if (!this.determineHasFixedSize() && this.determineHasSingleSrc()) return 'singleSrcImage';
		return 'defaultImage';
	}
}

/*
  public theme: Themes;
  constructor(private themingService: ThemingService, private changeDetectorRef: ChangeDetectorRef) {
    themingService.theme$.pipe(distinctUntilChanged()).subscribe(theme => {
      this.theme = theme;
      changeDetectorRef.markForCheck();
    });
  }
  */
