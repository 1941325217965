import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { WINDOW } from '@nx-bundesliga/bundesliga-com/framework/window';
import { ScriptLoaderService } from '@nx-bundesliga/bundesliga-com/services/script-loader';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { Location } from '@angular/common';

@Injectable()
export class AnalyticsScriptsLoaderService {
	private renderer: Renderer2;
	constructor(private readonly config: ConfigService, private loader: ScriptLoaderService, private rendererFactory: RendererFactory2, @Inject(WINDOW) private win: Window, @Inject(PLATFORM_ID) private readonly platformId: any, private location: Location) {
		let isWebview = false;
		if (this.config.getSettings('webview')) {
			isWebview = this.config.getSettings('webview');
		}

		const isAccountUrl = this.urlContainsAccount();
		if (isWebview !== true || (isWebview && isAccountUrl)) {
			let gtag = this.config.getSettings('analytics.gtag');
			if (isWebview && isAccountUrl) {
				gtag = this.config.getSettings('analytics.gtag-webview-accounts');
			}

			this.loader.setConfigKey(gtag, 'gtagId', false);

			// load script in head
			this.loader.load('gtag')[0].subscribe(
				() => {},
				(error) => {
					console.warn(`gtag.loader.error: ${error}`);
				}
			);

			// load noscript iframe for body
			if (isPlatformBrowser(this.platformId)) {
				this.renderer = rendererFactory.createRenderer(null, null);
				const gtagUrl = 'https://www.googletagmanager.com/ns.html?id=' + gtag;
				const gtagNoscript = this.renderer.createElement('noscript');
				const iframe = this.renderer.createElement('iframe');

				this.renderer.setProperty(iframe, 'height', '0');
				this.renderer.setProperty(iframe, 'width', '0');
				this.renderer.setStyle(iframe, 'display', 'none');
				this.renderer.setStyle(iframe, 'visibility', 'hidden');
				this.renderer.setProperty(iframe, 'src', gtagUrl);
				this.renderer.appendChild(gtagNoscript, iframe);

				win.document.body.appendChild(gtagNoscript);
			}
		}
	}

	/**
	 * load gtm on account webviews only - gtm should NOT be loaded for all other webviews like liveticker or news
	 * @private
	 */
	private urlContainsAccount(): boolean {
		const currentUrl = this.location.path();
		if (currentUrl.includes('account')) {
			return true;
		}
		return false;
	}
}
