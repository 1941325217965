export interface Broadcasts {
	broadcasts?: EpgBroadcaster[];
}

export interface EpgBroadcaster {
	logoCategory: EpgLogoCategory;
	link: EpgLink;
	broadcasterId?: string;
	broadcasterName: string;
	dflDatalibraryMatchId: string;
	logo: string;
}

export interface EpgLink {
	rel: string;
	href: string;
}

export interface EpgBroadcasterMatches {
	[dflDatalibraryMatchId: string]: EpgBroadcaster[];
}

export interface EpgBroadcasterByCountryResponse {
	broadcasters: EpgBroadcasterByCountry[];
	country: string;
}

export interface EpgBroadcasterByCountry {
	country: string;
	name: string;
	nameLocalized?: string;
	url: string;
	logos: EpgBroadcasterByCountryLogoVariant;
	rank: number;
}

export interface EpgBroadcasterByCountryLogoVariant {
	light: string;
	dark: string;
}

export interface LocalizedCountryList {
	'locale': string;
	'countries': {
		[countryCode: string]: string;
	};
}

export type EpgLogoCategory = 'wide' | 'compact' | 'landscape' | 'regular';
