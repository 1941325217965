import { NgModule } from '@angular/core';
import { SharedModule } from '@nx-bundesliga/bundesliga-com/framework/core';
import { LanguageSwitcherComponent } from './language/language-switcher.component';
import { ThemeSwitcherComponent } from './theme/theme-switcher.component';

@NgModule({
	declarations: [LanguageSwitcherComponent, ThemeSwitcherComponent],
	exports: [LanguageSwitcherComponent, ThemeSwitcherComponent],
	imports: [SharedModule]
})
export class SwitchersModule {}
