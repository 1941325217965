import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Match } from '@nx-bundesliga/models';

@Component({
	selector: 'match-fixture',
	templateUrl: './match-fixture.component.html',
	styleUrls: ['./match-fixture.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatchFixtureComponent {
	@Input() match: Match;

	constructor() {}
}
