export interface AnalyticsEvent extends PartialAnalyticsEvent {
	action: string;
}

export interface PartialAnalyticsEvent {
	category: string;
	action?: string;
	label: string;
	eventName: string;
}
