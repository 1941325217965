import { inject, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { provideSsrInitializer } from '@nx-bundesliga/shared/core/core-bootstrap';

import { ConfigLoader, ConfigStaticLoader } from './src/config.loader';
import { ConfigPipe } from './src/config.pipe';
import { ConfigService } from './src/config.service';

export * from './src/config.loader';
export * from './src/config.pipe';
export * from './src/config.service';

// for AoT compilation
// eslint-disable-next-line
export function configFactory(): ConfigLoader {
	return new ConfigStaticLoader();
}

// eslint-disable-next-line
export function initializerFactory(config = inject(ConfigService)): any {
	// workaround for AoT compilation
	return () => config.init();
}

@NgModule({
	declarations: [ConfigPipe],
	exports: [ConfigPipe]
})
/* eslint-disable */
export class ConfigModule {
	static forRoot(
		configuredProvider: any = {
			provide: ConfigLoader,
			useFactory: configFactory
		}
	): ModuleWithProviders<ConfigModule> {
		return {
			ngModule: ConfigModule,
			providers: [
				configuredProvider,
				ConfigService,
				provideSsrInitializer(initializerFactory)
				/* {
          provide: APP_INITIALIZER,
          useFactory: initializerFactory,
          multi: true
        } */
			]
		};
	}

	constructor(@Optional() @SkipSelf() parentModule?: ConfigModule) {
		if (parentModule) {
			throw new Error('ConfigModule already loaded; import in root module only.');
		}
	}
}
