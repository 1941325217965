export interface ConferenceSettingsSimple {
	enableCardEvents: boolean;
	enableGoalEvents: boolean;
	enableSubstitutionEvents: boolean;
	enableBasicEvents: boolean;
	enableMediaEvents: boolean;
	enableEmbedEvents: boolean;
	enableStatsEvents: boolean;
}

export interface ConferenceSettingsExpert {
	[matchId: string]: ConferenceSettingsSimple;
}
