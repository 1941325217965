// angular
import { NgModule } from '@angular/core';
import { OnetrustScriptsLoaderService } from '../../../framework/cookie/onetrust/onetrust-scripts-loader.service';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';

@NgModule({
	imports: [],
	exports: [],
	declarations: [],
	providers: [ConfigService, OnetrustScriptsLoaderService]
})
export class OnetrustModule {}
