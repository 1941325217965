import { Pipe, PipeTransform } from '@angular/core';
import { Playtime } from '@nx-bundesliga/models';

@Pipe({
	name: 'minute',
	pure: true
})
export class MinutePipe implements PipeTransform {
	transform(minute: Playtime, section: string): number {
		if (section === 'FIRST') {
			return minute.minute < 45 ? minute.minute : 45;
		}

		if (section === 'SECOND') {
			return minute.minute - 45 > 0 ? minute.minute - 45 : 0;
		}

		if (section === 'EXTRA_FIRST') {
			return minute.minute - 90 > 0 ? minute.minute - 90 : 0;
		}

		if (section === 'EXTRA_SECOND') {
			return minute.minute - 105 > 0 ? minute.minute - 105 : 0;
		}
	}
}
