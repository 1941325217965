// angular
import { NgModule } from '@angular/core';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { OnetrustScriptsLoaderService } from './onetrust-scripts-loader.service';

@NgModule({
	imports: [],
	exports: [],
	declarations: [],
	providers: [ConfigService, OnetrustScriptsLoaderService]
})
export class OnetrustModule {}
