export interface Language {
	code: string;
	name: string;
	localName: string;
	culture: string;
	ietf: string;
	direction?: string;
}

export const initialLanguage: Language = {
	code: '',
	name: '',
	localName: '',
	culture: '',
	ietf: '',
	direction: 'ltr'
};
