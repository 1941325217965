import { NgModule } from '@angular/core';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { MatchTeamComponent } from './match-team.component';
import { CommonModule, NgOptimizedImage } from '@angular/common';

@NgModule({
	declarations: [MatchTeamComponent],
	exports: [MatchTeamComponent],
	imports: [CommonModule, CommonsModule, NgOptimizedImage]
})
export class MatchTeamModule {}
