import { Pipe, PipeTransform } from '@angular/core';
import { MatchesService } from '@nx-bundesliga/bundesliga-com/services/matches';
import { Match } from '@nx-bundesliga/models';

@Pipe({
	name: 'fixturesMatchcenterLink',
	pure: true
})
export class FixturesMatchcenterLinkPipe implements PipeTransform {
	constructor(private matchesService: MatchesService) {}
	transform(match: Match, language): string[] {
		const linkArray = this.matchesService.getMatchCenterRouterLink(match, language);
		linkArray.push('liveticker');
		return linkArray;
	}
}
