import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { environment } from '@nx-bundesliga/bundesliga-com/environment';
import { Language } from '@nx-bundesliga/models';
import { getWorkingProfile } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { OktaAuthService } from './okta-auth.service';
import { ProfileAccount } from '@nx-bundesliga/models';

@Injectable({ providedIn: 'root' })
export class OktaAuthGuard {
	public isWebview = false;

	constructor(private okta: OktaAuthService, private router: Router, @Inject(PLATFORM_ID) private platformId: Object, private readonly config: ConfigService, private transferState: TransferState, private pstore: Store<ProfileAccount>, private readonly lstore: Store<Language>) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		if (isPlatformServer(this.platformId)) {
			return of(true);
		}
		const language = route.pathFromRoot.filter((el: ActivatedRouteSnapshot) => el.url.length > 0 && el.url[0].path !== '')[0].url[0].path;
		this.isWebview = environment.webview || false;
		const auth = route.data.hasOwnProperty('auth') ? route.data.auth : true;
		const path = route.url.length > 0 ? route.url[0].path : '';
		return this.pstore.pipe(
			select(getWorkingProfile),
			switchMap((user: ProfileAccount) => {
				const isAuthenticated = user.authorized;
				if (isAuthenticated) {
					if (user.registrationComplete === false || user.isVerified === false) {
						if (path !== 'register') {
							return this.navigateToPage(this.isWebview, language, 'register');
						}
						return of(true);
					}
					return of(true);
				} else {
					if (auth === true) {
						if (path !== 'login') {
							return this.navigateToPage(this.isWebview, language, 'login');
						}
						return of(true);
					}
					return of(true);
				}
			}),
			distinctUntilChanged(),
			catchError((error) => {
				return this.navigateToPage(this.isWebview, language, 'login').pipe(map(() => false));
			})
		);
	}

	private navigateToPage(isWebview = false, language: string, page: 'login' | 'register' = 'login') {
		const key: StateKey<string> = makeStateKey<string>('Account-Authorization');
		const authKeySet = this.transferState.get<string>(key, null);
		if (isWebview === true) {
			if (authKeySet !== null) {
				return of(true);
			} else {
				this.router.navigate([language, 'account', page], { replaceUrl: true });
			}
		} else {
			this.router.navigate([language, 'bundesliga', 'account', page], { replaceUrl: true });
		}
		return of(true);
	}
}
