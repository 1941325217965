import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Inject, Input, OnChanges, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
	selector: 'dfl-json-ld',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DflJsonLdComponent implements OnChanges {
	@Input() json: any;
	@HostBinding('innerHTML') ld: SafeHtml;
	public isBrowser = false;

	constructor(private sanitizer: DomSanitizer, @Inject(PLATFORM_ID) platformId: Object) {
		this.isBrowser = isPlatformBrowser(platformId);
	}

	ngOnChanges(changes: SimpleChanges) {
		this.ld = this.getSafeHtml(changes.json.currentValue);
	}

	/**
	 * Stringifies the given object, dumps it into a script tag and sanitizes the input.
	 * Then returns {SafeHtml} which in turn is bound to this components innerHTML prop.
	 *
	 * @param data
	 * @returns {SafeHtml}
	 */
	private getSafeHtml(data: any): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(`<script type="application/ld+json">${JSON.stringify(this.json)}</script>`);
	}
}
