// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  cursor: pointer;
  display: flex;
  justify-content: center;
  -webkit-user-select: none;
          user-select: none;
}

img.invert,
img.light {
  filter: invert(94%) sepia(94%) saturate(0%) hue-rotate(157deg) brightness(104%) contrast(108%);
}

:host-context(html[dark="1"]) img.auto {
  filter: invert(94%) sepia(94%) saturate(0%) hue-rotate(157deg) brightness(104%) contrast(108%);
}
:host-context(html[dark="1"]) img.invert, :host-context(html[dark="1"]) img.dark {
  filter: none;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRmbC1zaW1wbGUtaWNvbi5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNDLGVBQUE7RUFDQSxhQUFBO0VBQ0EsdUJBQUE7RUFDQSx5QkFBQTtVQUFBLGlCQUFBO0FBQ0Q7O0FBRUE7O0VBRUMsOEZBQUE7QUFDRDs7QUFHQztFQUNDLDhGQUFBO0FBQUY7QUFHQztFQUVDLFlBQUE7QUFGRiIsImZpbGUiOiJkZmwtc2ltcGxlLWljb24uY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG5cdGN1cnNvcjogcG9pbnRlcjtcblx0ZGlzcGxheTogZmxleDtcblx0anVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG5cdHVzZXItc2VsZWN0OiBub25lO1xufVxuXG5pbWcuaW52ZXJ0LFxuaW1nLmxpZ2h0IHtcblx0ZmlsdGVyOiBpbnZlcnQoOTQlKSBzZXBpYSg5NCUpIHNhdHVyYXRlKDAlKSBodWUtcm90YXRlKDE1N2RlZykgYnJpZ2h0bmVzcygxMDQlKSBjb250cmFzdCgxMDglKTtcbn1cblxuOmhvc3QtY29udGV4dChodG1sW2Rhcms9JzEnXSkgaW1nIHtcblx0Ji5hdXRvIHtcblx0XHRmaWx0ZXI6IGludmVydCg5NCUpIHNlcGlhKDk0JSkgc2F0dXJhdGUoMCUpIGh1ZS1yb3RhdGUoMTU3ZGVnKSBicmlnaHRuZXNzKDEwNCUpIGNvbnRyYXN0KDEwOCUpO1xuXHR9XG5cblx0Ji5pbnZlcnQsXG5cdCYuZGFyayB7XG5cdFx0ZmlsdGVyOiBub25lO1xuXHR9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/bundesliga-com/components/dfl-simple-icon/src/lib/dfl-simple-icon.component.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;EACA,aAAA;EACA,uBAAA;EACA,yBAAA;UAAA,iBAAA;AACD;;AAEA;;EAEC,8FAAA;AACD;;AAGC;EACC,8FAAA;AAAF;AAGC;EAEC,YAAA;AAFF;AAEA,4+BAA4+B","sourcesContent":[":host {\n\tcursor: pointer;\n\tdisplay: flex;\n\tjustify-content: center;\n\tuser-select: none;\n}\n\nimg.invert,\nimg.light {\n\tfilter: invert(94%) sepia(94%) saturate(0%) hue-rotate(157deg) brightness(104%) contrast(108%);\n}\n\n:host-context(html[dark='1']) img {\n\t&.auto {\n\t\tfilter: invert(94%) sepia(94%) saturate(0%) hue-rotate(157deg) brightness(104%) contrast(108%);\n\t}\n\n\t&.invert,\n\t&.dark {\n\t\tfilter: none;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
