export interface MatchLineupPerson {
	dflDatalibraryObjectId: string;
	imageUrl: string;
	name: string;
	role: string;
	shirtNumber?: number;
	slugifiedShortName: string;
}

export interface MatchLineupStartingelevenPerson {
	dflDatalibraryObjectId: string;
	imageUrl: string;
	name: string;
	position: {
		x: number;
		y: number;
	};
	role: string;
	shirtNumber: number;
	slugifiedShortName: string;
	tacticalName?: string;
}

export interface MatchLineup {
	away: {
		bench: {
			persons: MatchLineupPerson[];
		};
		coaches?: {
			persons: MatchLineupPerson[];
		};
		startingEleven: {
			isTactical: boolean;
			persons: MatchLineupStartingelevenPerson[];
			tacticalFormationName: string;
		};
	};
	home: {
		bench: {
			persons: MatchLineupPerson[];
		};
		coaches: {
			persons: MatchLineupPerson[];
		};
		startingEleven: {
			isTactical: boolean;
			persons: MatchLineupStartingelevenPerson[];
			tacticalFormationName: string;
		};
	};
	lastUpdateDateTime: string;
}
