import { NgModule } from '@angular/core';
import { ScoreBugComponent } from './score-bug.component';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { CommonModule } from '@angular/common';

@NgModule({
	declarations: [ScoreBugComponent],
	exports: [ScoreBugComponent],
	imports: [CommonModule, CommonsModule]
})
export class ScoreBugModule {}
