import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ClubPlayer } from '@nx-bundesliga/models';
import { PersonService } from '@nx-bundesliga/bundesliga-com/services/person';

@Pipe({
	name: 'toPerson',
	pure: true
})
export class PersonPipe implements PipeTransform {
	constructor(private person: PersonService) {}

	transform(personId: string): Observable<ClubPlayer> {
		return this.person.getPersonById(personId).pipe(catchError(() => of(null)));
	}
}
