import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { isPlatformServer } from '@angular/common';
import { AppBannerPlatform } from '../../models/app-banner/app-banner.model';
import { CookieService } from 'ngx-cookie-service';
import { map, take } from 'rxjs/operators';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { ConsentCategories, CookieConsentService } from '@nx-bundesliga/bundesliga-com/services/cookie-consent';

export const APP_BANNER_PROMOS = ['blweb-promo-matchcenter-banner', 'blweb-promo-fixtures-banner'];

@Injectable({
	providedIn: 'root'
})
export class AppBannerService {
	private static readonly APP_BANNER_COOKIE_NAME = {
		'blweb-smart-banner': 'AppBannerHidden',
		'blweb-promo-matchcenter-banner': 'AppBannerHiddenMatchcenter',
		'blweb-promo-fixtures-banner': 'AppBannerHiddenMatchcenter'
	};
	private static readonly APP_BANNER_HIDDEN_COUNTER_COOKIE_NAME = {
		'blweb-smart-banner': 'AppBannerHiddenCounter',
		'blweb-promo-matchcenter-banner': 'AppBannerHiddenCounterMatchcenter',
		'blweb-promo-fixtures-banner': 'AppBannerHiddenCounterMatchcenter'
	};

	private readonly isServer: boolean;

	constructor(@Inject(PLATFORM_ID) private readonly platformId: string, private readonly platform: Platform, private readonly cookieService: CookieService, private readonly cookieConsentService: CookieConsentService, private readonly config: ConfigService) {
		this.isServer = isPlatformServer(this.platformId);
	}

	public isAppBannerEnabled(promo?: string): boolean {
		if (promo) {
			return (this.platform.IOS || this.platform.ANDROID) && !this.isServer && !this.isAppBannerHidden(promo) && !this.isWebView();
		}
		const allPromosHidden = Object.values(AppBannerService.APP_BANNER_COOKIE_NAME).every((val) => this.isAppBannerHidden(val));
		return (this.platform.IOS || this.platform.ANDROID) && !this.isServer && !allPromosHidden && !this.isWebView();
	}

	private isWebView(): boolean {
		return this.config.getSettings('webview');
	}

	public getAppBannerPlatform(): AppBannerPlatform {
		if (this.platform.IOS) {
			return AppBannerPlatform.IOS;
		}
		if (this.platform.ANDROID) {
			return AppBannerPlatform.ANDROID;
		}
	}

	public hideAppBanner(promo: string, days: number): void {
		this.cookieConsentService.consents$
			.pipe(
				map((consents: ConsentCategories) => (consents.hasOwnProperty('C0002') ? consents['C0002'] : false)),
				take(1)
			)
			.subscribe((consented: boolean) => {
				if (!consented) {
					this.cookieService.delete(AppBannerService.APP_BANNER_COOKIE_NAME[promo]);
					this.cookieService.delete(AppBannerService.APP_BANNER_HIDDEN_COUNTER_COOKIE_NAME[promo]);
					return;
				}
				// hide logic as described in PLAT-1119
				let hiddenCounter = 0;
				if (this.cookieService.check(AppBannerService.APP_BANNER_HIDDEN_COUNTER_COOKIE_NAME[promo])) {
					hiddenCounter = +this.cookieService.get(AppBannerService.APP_BANNER_HIDDEN_COUNTER_COOKIE_NAME[promo]);
				}

				const expiryDate = days > 0 ? this.createFutureDate(Math.pow(2, hiddenCounter) * days) : 0;
				this.cookieService.set(AppBannerService.APP_BANNER_COOKIE_NAME[promo], '1', expiryDate, '/');

				hiddenCounter++;
				const hiddenCounterExpiryDate = this.createFutureDate(365);
				this.cookieService.set(AppBannerService.APP_BANNER_HIDDEN_COUNTER_COOKIE_NAME[promo], String(hiddenCounter), hiddenCounterExpiryDate, '/');
			});
	}

	private isAppBannerHidden(promo): boolean {
		return this.cookieService.check(AppBannerService.APP_BANNER_COOKIE_NAME[promo]);
	}

	private createFutureDate(days: number): Date {
		const date = new Date();
		date.setDate(date.getDate() + days);
		return date;
	}
}
