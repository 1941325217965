import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'includes',
	pure: true
})
export class IncludesPipe implements PipeTransform {
	transform(arr: string[], value): boolean {
		return Array.isArray(arr) && arr.includes(value);
	}
}
