import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AnalyticsService } from '@nx-bundesliga/bundesliga-com/framework/analytics';
import { WINDOW } from '@nx-bundesliga/bundesliga-com/framework/window';
import { AppBanner } from '../../../../models/app-banner/app-banner.model';

@Component({
	selector: 'app-banner',
	templateUrl: 'app-banner.component.html',
	styleUrls: ['app-banner.component.scss']
})
export class AppBannerComponent implements OnInit {
	testVersion: 'A' | 'B';
	constructor(@Inject('bannerModel') private bannerModel: AppBanner, @Inject(WINDOW) private window: Window, private analyticsService: AnalyticsService) {}

	public model: AppBanner;
	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() close: EventEmitter<{ days: number; promo: string }> = new EventEmitter<{ days: number; promo: string }>(true);

	ngOnInit(): void {
		this.model = this.bannerModel;
		this.testVersion = 'B';
	}

	public onBannerCloseClick(): void {
		this.analyticsService.eventTrack.next({
			event: 'interaction',
			category: 'Promo Banner APP',
			action: 'Promo Banner APP Click',
			label: 'header Close Stay-in-Browser'
		});
		this.close.emit({ days: 0, promo: this.bannerModel.promo });
	}

	public onBannerAppClick(): void {
		this.analyticsService.eventTrack.next({
			event: 'interaction',
			category: 'Promo Banner APP',
			action: 'Promo Banner APP Click',
			label: 'home banner Click APP',
			abTestVersion: this.testVersion
		});
		this.window.open(this.model.url, '_blank');
		this.close.emit({ days: 14, promo: this.bannerModel.promo });
	}

	public ratingRounded(): number {
		return Math.round(this.model.rating);
	}
}
