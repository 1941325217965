export interface AppBanner {
	promo: string;
	rating?: number;
	url?: string;
}

export enum AppBannerPlatform {
	IOS,
	ANDROID
}
