import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { DflNgxLazyLoadModule } from '@nx-bundesliga/shared/dfl-ngx-lazyload';
import { ClublogoComponent, DflButtonComponent, DflImageComponent, DflLoaderArticleComponent, DflLoaderComponent, DflLoaderModernComponent, DflLoaderSharebarComponent, DflLoaderTeaserComponent, LiveAnimationComponent, PageTitleComponent, PlayerImageComponent } from './component';
import { ImageServicePipe, LinkHttpPipe, ReplacePipe, ReversePipe, SafeHtmlPipe, SafeUrlPipe, SanitizeHtmlPipe, SlugifyPipe, StripHtmlPipe, TranslateRoutePipe } from './pipe';
import { RouterLink } from '@angular/router';
import { PlayerImagePipe } from './pipe';

@NgModule({
	imports: [CommonModule, TranslateModule.forChild(), DflNgxLazyLoadModule, MatProgressSpinnerModule, MatButtonModule, NgOptimizedImage, RouterLink],

	providers: [SlugifyPipe, SafeUrlPipe, ReversePipe, ImageServicePipe, StripHtmlPipe, ReplacePipe, LinkHttpPipe, TranslateRoutePipe, PlayerImagePipe],
	exports: [
		PageTitleComponent,
		ClublogoComponent,
		DflLoaderComponent,
		DflLoaderModernComponent,
		SlugifyPipe,
		SafeUrlPipe,
		SafeHtmlPipe,
		SanitizeHtmlPipe,
		ReversePipe,
		ImageServicePipe,
		PlayerImagePipe,
		StripHtmlPipe,
		ReplacePipe,
		LiveAnimationComponent,
		DflLoaderTeaserComponent,
		DflLoaderSharebarComponent,
		DflLoaderArticleComponent,
		DflImageComponent,
		LinkHttpPipe,
		PlayerImageComponent,
		TranslateRoutePipe,
		DflButtonComponent
	],

	declarations: [
		DflButtonComponent,
		PageTitleComponent,
		ClublogoComponent,
		DflLoaderComponent,
		DflLoaderModernComponent,
		SlugifyPipe,
		SafeUrlPipe,
		SafeHtmlPipe,
		SanitizeHtmlPipe,
		ReversePipe,
		ImageServicePipe,
		PlayerImagePipe,
		StripHtmlPipe,
		ReplacePipe,
		LiveAnimationComponent,
		DflLoaderTeaserComponent,
		DflLoaderSharebarComponent,
		DflLoaderArticleComponent,
		DflImageComponent,
		LinkHttpPipe,
		PlayerImageComponent,
		TranslateRoutePipe
	]
})
export class CommonsLibModule {}
