import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Language } from '@nx-bundesliga/models';
import { getWorkingLanguage } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { WINDOW } from '@nx-bundesliga/bundesliga-com/framework/window';
import { ConsentCategories, CookieConsentService } from '@nx-bundesliga/bundesliga-com/services/cookie-consent';
import { ScriptLoaderService, ScriptLoaderServiceStatus } from '@nx-bundesliga/bundesliga-com/services/script-loader';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { Observable, Subject, forkJoin, interval, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, take, takeWhile, tap } from 'rxjs/operators';

declare let googletag: any;

@Injectable({
	providedIn: 'root'
})
export class DfpAdsService {
	private isBrowser: boolean;
	public adConfig: boolean;
	private consented = false;
	public scriptLoadAttempted = false;
	public slotRendered: Subject<{
		slot: any;
		rendered: boolean;
		consented: boolean;
	}> = new Subject<{ slot: any; rendered: boolean; consented: boolean }>();

	constructor(private loader: ScriptLoaderService, private scriptLoader: ScriptLoaderService, public configService: ConfigService, private readonly languageStore: Store<Language>, private cookieConsent: CookieConsentService, @Inject(WINDOW) private window: Window, @Inject(PLATFORM_ID) private platformId: Object) {
		this.isBrowser = isPlatformBrowser(this.platformId);
		if (this.isBrowser) {
			(<any>(<any>this.window)).googletag = (<any>this.window).googletag || {};
			googletag.cmd = googletag.cmd || [];
		}
	}

	public load(): Observable<boolean> {
		let googletagCheck = of(false);
		if (this.isBrowser) {
			googletagCheck = interval(200)
				.pipe(
					map((val) => ((<any>this.window).googletag !== undefined && (<any>this.window).googletag.apiReady === true ? true : val)),
					filter((check) => check === true || (Number.isInteger(check) && (check as number) > 15))
				)
				.pipe(
					takeWhile((googleReady) => googleReady === true || (Number.isInteger(googleReady) && (googleReady as number) < 15), true),
					switchMap((val) => (val === true ? of(true) : of(false))),
					catchError(() => of(false))
				);
		}

		return of(false).pipe(
			tap((loaded: boolean) => {
				if (this.scriptLoadAttempted === false) {
					this.scriptLoadAttempted = true;
					forkJoin({
						lang: this.languageStore.pipe(
							select(getWorkingLanguage),
							filter((lang: Language) => lang.code !== ''),
							take(1)
						),
						sloaded: this.scriptLoader.load('dfp')[0],
						consented: this.cookieConsent.consents$.pipe(
							map((consents: ConsentCategories) => (consents.hasOwnProperty('C0004') ? consents['C0004'] : false)),
							take(1)
						)
					}).subscribe((gtagInit: { lang: Language; sloaded: ScriptLoaderServiceStatus; consented: boolean }) => {
						// set defaults after first successful load
						if (gtagInit.sloaded === 'loaded' || gtagInit.sloaded === 'alreadyloaded') {
							this.consented = gtagInit.consented;
							if (typeof googletag !== typeof undefined) {
								googletag.cmd.push(() => {
									// Infinite scroll requires SRA
									googletag.pubads().enableSingleRequest();

									// targeting cookies are disabled by default, but will be enabled as soon as user consents
									// Set to 0 to enable cookies, 1 to disable.
									googletag.pubads().setCookieOptions(gtagInit.consented === true ? 0 : 1);

									// Disable initial load, we will use refresh() to fetch ads.
									// Calling this function means that display() calls just
									// register the slot as ready, but do not fetch ads for it.
									googletag.pubads().disableInitialLoad();

									// collapse empty divs before they are actually loaded or not
									googletag.pubads().collapseEmptyDivs(true);

									// set targeting to current language
									googletag.pubads().setTargeting('language', gtagInit.lang.code.toUpperCase());

									// Enable services
									googletag.enableServices();

									// save slotRenderEnded function in public variable for individual slot render checking
									googletag.pubads().addEventListener('slotRenderEnded', this.slotRenderEndedFn);
								});
							}
						}
					});
				}
			}),
			switchMap((loaded: boolean) => (this.isBrowser ? googletagCheck : of(false))),
			take(1),
			catchError(() => of(false))
		);
	}

	public slotRenderEndedFn = (event) => {
		if (!event.isEmpty) {
			this.slotRendered.next({
				slot: event.slot,
				rendered: true,
				consented: this.consented
			});
		} else {
			this.slotRendered.next({
				slot: event.slot,
				rendered: false,
				consented: this.consented
			});
		}
	};

	public slotRenderEnded(definedSlot: any): Observable<{ rendered: boolean; consented: boolean }> {
		return this.slotRendered.pipe(
			filter((slot: { slot: any; rendered: boolean; consented: boolean }) => slot.slot === definedSlot),
			map((slot: { slot: any; rendered: boolean; consented: boolean }) => ({
				rendered: slot.rendered,
				consented: slot.consented
			})),
			distinctUntilChanged()
		);
	}
}
