// angular
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { appReducers, INITIAL_APPSTATE } from './appstate';
import { ClubsEffects } from './club';
import { LanguageEffects } from './language';
import { ProfileEffects } from './profile';

@NgModule({
	imports: [
		StoreModule.forRoot(appReducers, {
			initialState: INITIAL_APPSTATE,
			runtimeChecks: {
				strictStateImmutability: true,
				strictActionImmutability: true,
				strictStateSerializability: true,
				strictActionSerializability: true
			}
		}),
		EffectsModule.forRoot([LanguageEffects, ClubsEffects, ProfileEffects])
	],
	declarations: [],
	providers: [],
	exports: []
})
export class AppstoreModule {
	constructor() {}
}
