import { EditorialNews } from './news.model';
import { EditorialArticle } from './index';

/**
 * Interface for editorial landingpages
 */
export interface EditorialLandingPage {
	editorial: EditorialArticle;
	categories?: EditorialLandingPageCategory[];
}
export interface EditorialLandingPageCategory {
	publishedAt: string;
	editorialAbstract?: string;
	teaserAbstract: EditorialLandingPageTeaserAbstract;
	headlines: EditorialLandingPageHeadlines;
	categories: string[];
	cisId: string;
	type: string;
	slug: string;
	speakingUrl: string;
	teaser?: string;
	articles: EditorialArticle[];
}

export interface EditorialLandingPageTeaserAbstract {
	teaserAbstractDefault: string;
	teaserAbstractShort?: string;
}

export interface EditorialLandingPageHeadlines {
	defaultHeadline: string;
	shortHeadline?: string;
}
