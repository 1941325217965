import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'livetickerEventPlayername'
})
export class PlayernamePipe implements PipeTransform {
	transform(value: string, part: 'full' | 'first' | 'last' = 'full'): any {
		if (part === 'first') {
			return this.getPlayerAliasFirstName(value);
		}

		if (part === 'last') {
			return this.getPlayerAliasLastName(value);
		}

		return value;
	}

	public getPlayerAliasFirstName(name: string): string {
		if (!name || name.indexOf(' ') === -1) {
			return '';
		}

		return name.slice(0, name.indexOf(' '));
	}

	public getPlayerAliasLastName(name: string): string {
		if (name) {
			if (name.indexOf(' ') === -1) {
				return name;
			}

			return name.slice(name.indexOf(' '));
		}
		return '';
	}
}
