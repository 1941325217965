export interface Competition {
	name: string;
	id: string;
}

export const initialCompetition: Competition = {
	name: 'bundesliga',
	id: 'DFL-COM-000001'
};

export interface CompetitionActive {
	name: string;
	id: string;
	matchday?: {
		dflDatalibraryMatchdayId: string;
		matchdayId: string;
		matchdayNumber: string;
	};
	season?: {
		dflDatalibrarySeasonId: string;
		name: string;
		seasonId: string;
	};
}
