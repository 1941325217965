import { Component, Inject, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { EpgBroadcaster, Match } from '@nx-bundesliga/models';
import { AnalyticsService } from '@nx-bundesliga/bundesliga-com/framework/analytics';
import { WINDOW } from '@nx-bundesliga/bundesliga-com/framework/window';

@Component({
	selector: 'EpgBroadcasterOverlay',
	templateUrl: './epg-broadcaster-overlay.component.html',
	styleUrls: ['./epg-broadcaster-overlay.component.scss']
})
export class EpgBroadcasterOverlayComponent implements OnChanges {
	@Input() maxBroadcasters = 2;
	@Input() matchId: string;
	@Input() epgData: any;
	@Input() showHeadline = false;
	@Input() showScore = false;
	@Input() match: Match;
	@Input() isDarkMode = false;
	@ViewChild('overlay', { static: false }) overlay: TemplateRef<any>;

	constructor(public analyticsService: AnalyticsService, public dialog: MatDialog, public bottomSheet: MatBottomSheet, @Inject(WINDOW) private window: Window) {}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes && changes.hasOwnProperty('epgData')) {
			const matchId = changes && changes.hasOwnProperty('matchId') ? changes['matchId'].currentValue : this.matchId || 'none';
			if (Array.isArray(changes['epgData'].currentValue)) {
				this.epgData = {
					[matchId.toString()]: changes['epgData'].currentValue
				};
			}
			if (this.epgData[matchId.toString()] && this.epgData[matchId.toString()].length <= this.maxBroadcasters) {
				this.triggerOverlayImpressions(this.epgData[matchId.toString()]);
			}
		}
	}

	public openOverlay(event, epgData: any, matchId: string) {
		if (this.window && this.window.outerWidth && this.window.outerWidth < 768) {
			this.bottomSheet.open(this.overlay, {
				hasBackdrop: true,
				closeOnNavigation: true,
				disableClose: false,
				panelClass: 'panelDefault',
				data: { epgData, matchId, comp: 'bottomSheet' }
			});
		} else {
			this.dialog.open(this.overlay, {
				maxWidth: '60vw',
				minWidth: '480px',
				data: { epgData, matchId, comp: 'dialog' }
			});
		}
		this.triggerOverlayImpressions(epgData[matchId]);
		event.stopPropagation();
		event.preventDefault();
	}

	public triggerOverlayImpressions(broadcasters: EpgBroadcaster[]) {
		broadcasters.forEach((broadcaster: EpgBroadcaster, index: number) => {
			this.broadcasterImpression(broadcaster, index);
		});
	}

	public broadcasterImpression(broadcaster: EpgBroadcaster, index: number) {
		const epgImpressionTracking = {
			event: 'interaction',
			category: 'EPG',
			action: 'Impression',
			label: broadcaster.broadcasterName,
			custom: {
				epgSlot: (index + 1).toString()
			}
		};
		this.analyticsService.eventTrack.next(epgImpressionTracking);
	}

	public broadcasterClick(broadcaster: EpgBroadcaster, index: number, event: MouseEvent) {
		event.preventDefault();
		const epgClickTracking = {
			event: 'interaction',
			category: 'EPG',
			action: 'Click',
			label: broadcaster.broadcasterName,
			custom: {
				epgSlot: (index + 1).toString(10)
			}
		};
		this.analyticsService.eventTrack.next(epgClickTracking);
		this.window.open(broadcaster.link.href, '_blank');
	}

	public twoBroadcasterAllowed(epgData) {
		return !epgData.some((e) => e.logoCategory === 'landscape' || e.logoCategory === 'wide');
	}
}
