// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  max-width: 100%;
  object-fit: contain;
}

.dark {
  display: none;
}

:host-context(html[dark="1"]) .dark {
  display: initial;
}
:host-context(html[dark="1"]) .light {
  display: none;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRmbC1pbWFnZS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNDLGVBQUE7RUFDQSxtQkFBQTtBQUNEOztBQUNBO0VBQ0MsYUFBQTtBQUVEOztBQUVDO0VBQ0MsZ0JBQUE7QUFDRjtBQUVDO0VBQ0MsYUFBQTtBQUFGIiwiZmlsZSI6ImRmbC1pbWFnZS5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbImltZyB7XG5cdG1heC13aWR0aDogMTAwJTtcblx0b2JqZWN0LWZpdDogY29udGFpbjtcbn1cbi5kYXJrIHtcblx0ZGlzcGxheTogbm9uZTtcbn1cblxuOmhvc3QtY29udGV4dChodG1sW2Rhcms9JzEnXSkge1xuXHQuZGFyayB7XG5cdFx0ZGlzcGxheTogaW5pdGlhbDtcblx0fVxuXG5cdC5saWdodCB7XG5cdFx0ZGlzcGxheTogbm9uZTtcblx0fVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/commons/src/lib/component/dfl-image/dfl-image.component.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;EACA,mBAAA;AACD;;AACA;EACC,aAAA;AAED;;AAEC;EACC,gBAAA;AACF;AAEC;EACC,aAAA;AAAF;AACA,4mBAA4mB","sourcesContent":["img {\n\tmax-width: 100%;\n\tobject-fit: contain;\n}\n.dark {\n\tdisplay: none;\n}\n\n:host-context(html[dark='1']) {\n\t.dark {\n\t\tdisplay: initial;\n\t}\n\n\t.light {\n\t\tdisplay: none;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
