/**
 * BundesligaJsonLdImage
 */
interface BundesligaJsonLdImage {
	'@type': 'ImageObject';
	url: string;

	width?: string | number;
	height?: string | number;
}

/**
 * BundesligaJsonLdMainEntity
 */
interface BundesligaJsonLdMainEntity {
	'@type': 'WebPage';
	'@id': string;
}

/**
 * BundesligaJsonLdAuthor
 */
interface BundesligaJsonLdAuthor {
	'@type': 'Person';
	name: string;
}

/**
 * BundesligaJsonLdSportsTeam
 */
interface BundesligaJsonLdSportsTeam {
	'@type': 'SportsTeam';
	name: string;
	sameAs: string; // url of official website.
	logo?: string; // An associated logo.
}

/**
 * BundesligaJsonLdOffer
 */
interface BundesligaJsonLdOffer {
	'@type': 'Offer';
	category: string;
	url: string;
}

/**
 * BundesligaJsonLdLocation
 */
interface BundesligaJsonLdLocation {
	'@type': 'StadiumOrArena';
	name: string;
	address?: string;
	sameAs?: string;
	image?: string;
}

/**
 * BundesligaJsonLd
 */
export interface BundesligaJsonLd {
	'@context': 'https://schema.org';
	'@type': string;
	url: string;
	name: string;
	description: string;
	image?: BundesligaJsonLdImage;
	mainEntityOfPage?: BundesligaJsonLdMainEntity;
	[others: string]: any;
}

/**
 * BundesligaJsonLdArticle
 */
export interface BundesligaJsonLdArticle extends BundesligaJsonLd {
	'@type': 'Article';
	author?: BundesligaJsonLdAuthor;
	datePublished: string;
	dateModified?: string;
}

/**
 * BundesligaJsonLdSportEvent
 */
export interface BundesligaJsonLdSportEvent extends BundesligaJsonLd {
	'@type': 'SportsEvent';
	eventStatus?: string;
	startDate: string;
	location: BundesligaJsonLdLocation;
	offers: BundesligaJsonLdOffer;
	homeTeam: BundesligaJsonLdSportsTeam;
	awayTeam: BundesligaJsonLdSportsTeam;
}

/**
 * BundesligaJsonLdVideo
 */
export interface BundesligaJsonLdVideo extends BundesligaJsonLd {
	uploadDate: any;
	thumbnailUrl: string;
	author?: string;
	contentUrl?: string;
	duration?: string;
	embedUrl?: string;
}
