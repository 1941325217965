import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'toCompetition',
	pure: true
})
export class CompetitionPipe implements PipeTransform {
	constructor() {}

	transform(competitionId: string): string {
		return competitionId === 'DFL-COM-000002' ? '2bundesliga' : 'bundesliga';
	}
}
