export interface MatchStats {
	lastUpdateDateTime: string;
	ballPossessionRatio: {
		awayValue: number;
		homeValue: number;
		lastUpdateDateTime: string;
	};
	cornerKicks: {
		awayValue: number;
		homeValue: number;
		lastUpdateDateTime: string;
	};
	distanceCovered: {
		awayValue: number;
		homeValue: number;
		lastUpdateDateTime: string;
	};
	fouls: {
		awayValue: number;
		homeValue: number;
		lastUpdateDateTime: string;
	};
	offsides: {
		awayValue: number;
		homeValue: number;
		lastUpdateDateTime: string;
	};
	passAccuracy: {
		awayValue: number;
		homeValue: number;
		lastUpdateDateTime: string;
	};
	passes: {
		awayValue: number;
		homeValue: number;
		lastUpdateDateTime: string;
	};
	shotsOffTarget: {
		awayValue: number;
		homeValue: number;
		lastUpdateDateTime: string;
	};
	shotsOnTarget: {
		awayValue: number;
		homeValue: number;
		lastUpdateDateTime: string;
	};
	sprints: {
		awayValue: number;
		homeValue: number;
		lastUpdateDateTime: string;
	};
	tacklesWon: {
		awayValue: number;
		homeValue: number;
		lastUpdateDateTime: string;
	};

	playerRankings: {
		lastUpdateDateTime: string;
		challengesWon: StatsPlayerRanking;
		maximumSpeed: StatsPlayerRanking;
		shots: StatsPlayerRanking;
		totalDistanceCovered: StatsPlayerRanking;
	};

	fantasyManagerRanking: {
		home: StatsPlayerRankingEntry[];
		away: StatsPlayerRankingEntry[];
		lastUpdateDateTime: string;
	};

	xGoals: {
		homeValue: number;
		awayValue: number;
		lastUpdateDateTime: string;
	};

	passEfficiency: {
		home: StatsPlayerRankingEntry[];
		away: StatsPlayerRankingEntry[];
		lastUpdateDateTime: string;
	};
}
export interface StatsPlayerRanking {
	away: StatsPlayerRankingEntry;
	home: StatsPlayerRankingEntry;
	lastUpdateDateTime?: string;
}

export interface StatsPlayerRankingEntry {
	dflDatalibraryObjectId: string;
	playerImageUrl: string;
	playerName: string;
	playerSlug: string;
	value: number;
}

export interface StatsPlayerProgressRanking {
	away: StatsPlayerProgressRankingEntry[];
	home: StatsPlayerProgressRankingEntry[];
	lastUpdateDateTime?: string;
}

export interface StatsPlayerProgressRankingEntry {
	dflDatalibraryObjectId: string;
	playerImageUrl: string;
	playerName: string;
	value: number;
}
