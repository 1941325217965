export interface Profile {
	tokens?: ProfileTokens;
	user?: ProfileUser;
	newsletter?: ProfileNewsletter;
	socialProvider?: ProfileSocialProvider;
}

export interface ProfileUser {
	uid: string;
	email: string;
	login: string;
	firstName: string;
	lastName: string;
	country: string;
	gender: string;
	birthDate: string;
	locale: string;
	favorite: {
		teams: string[];
		players: string[];
	};
	crossAccountProfileOptout?: boolean;
}

export interface ProfileSocialProvider {
	socialLogin?: boolean;
	google?: boolean;
	facebook?: boolean;
	apple?: boolean;
	x?: boolean;
}

export interface ProfileNewsletter {
	bundesligaNewsletter: boolean;
	fantasyNewsletter: boolean;
	partnerNewsletter: boolean;
	predictor2BLNewsletter: boolean;
	predictorNewsletter: boolean;
	subscriptionSource: string;
}

export interface ProfileTokens {
	accessToken?: string;
	idToken?: string;
	refreshToken?: string;
}

export interface ProfileAccount extends Profile {
	authorized: boolean;
	pending: boolean;
	isVerified: boolean;
	registrationComplete: boolean;
	error?: any;
}

export const initialProfile: ProfileAccount = {
	authorized: false,
	pending: false,
	isVerified: false,
	registrationComplete: false,
	error: false
};
