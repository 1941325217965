import { Playtime } from './common.model';

/**
 * Current groups for EURO 2024
 */
export type GroupType = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H';

/**
 * To be extended for other competitions
 */
export type MatchType = 'Group Stage' | 'Round Of 16' | 'Quarter-finals' | 'Semi-finals' | 'Final';
export type GroupName = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H';
export type MatchDayOption = { matchType: MatchType; matchDay: number };
export type MatchDayOptionList = { matchType: MatchType; matchDays: number[] };
/**
 * Actual Match Interface
 */
export interface Match {
	matchId: string;
	dflDatalibraryCompetitionId: string;
	dflDatalibraryMatchId: string;
	dflDatalibraryMatchdayId: string;
	dflDatalibrarySeasonId: string;
	matchday: number;
	matchdayLabel?: string; // deprecated 2023-10-12
	matchStatus: BundesligaMatchStates;
	seasonOrder: number;
	kickOff?: string;
	plannedKickOff: string;
	matchdayRange: {
		start: string;
		end: string;
	};
	dateQuality?: MatchDateQuality;
	liveBlogUrl?: string; // deprecated 2023-10-12
	stadiumIconUrlWhite?: string; // deprecated 2023-10-12
	stadiumIconUrlBlack?: string; // deprecated 2023-10-12
	stadiumName?: string; // deprecated 2023-10-12
	minuteOfPlay?: Playtime;
	score?: MatchScores;
	teams: MatchTeams;
	slugs: {
		slugLong: string;
		slugShort?: string; // deprecated 2023-10-12
	} & {
		[key in SupportedLanguagesByFBMatchesNode]?: AttributesByLanguage;
	};
	group?: GroupType;
	matchType?: MatchType;
}

export type SupportedLanguagesByFBMatchesNode = 'en' | 'de' | 'es' | 'ja' | 'fr' | 'pt' | 'ar';

export type AttributesByLanguage = {
	highlightVideo?: MatchHighlightVideo;
};

/**
 * All possible states a bundesliga match can have.
 * PENALTY, PRE_EXTRA and so on can only be set in the relegation competition.
 */
export type BundesligaMatchStates = 'PRE_MATCH' | 'FIRST_HALF' | 'HALF' | 'SECOND_HALF' | 'PRE_EXTRA' | 'FIRST_HALF_EXTRA' | 'HALF_EXTRA' | 'SECOND_HALF_EXTRA' | 'FINAL_WHISTLE' | 'PRE_PENALTY' | 'PENALTY' | 'PREDICTED';

export interface MatchScore {
	halftime: number;
	live: number;
	final?: number;
}

/**
 * This model is different from the models/club/club.model in that it only contains a small subset needed for the editorial pages.
 */
export interface MatchClub {
	dflDatalibraryClubId: string;
	nameFull: string;
	nameShort?: string;
	threeLetterCode: string;
	logoUrl: string;
	boxOfficeUrl?: string;
	gradientStartColor: string;
	gradientEndColor: string;
	textColor: string;
}

export interface MatchHighlightVideo {
	videoId: string;
	headline?: string;
	text?: string;
	duration?: number;
}

export type MatchDateQuality = 'POSTPONED' | 'RESCHEDULED' | 'AWARDED_TO' | 'ABANDONED' | 'REPLAY' | 'PLAYED' | 'DECLARED_VOID' | 'SEASON_CANCELED' | 'CANCELED';

export interface MatchScores {
	home: MatchScore;
	away: MatchScore;
}

export interface MatchTeams {
	home: MatchClub;
	away: MatchClub;
}
