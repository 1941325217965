import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'FooterNavigationComponent',
	templateUrl: './footer-navigation.component.html',
	styleUrls: ['./footer-navigation.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterNavigationComponent {
	@Input() language = 'de';
	@Input() competition = 'bundesliga';
	@Input() naviStructure: any;

	constructor() {}

	trackByLabel(index, item) {
		return item.label;
	}
}
