// angular
import { NgModule } from '@angular/core';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { InstanaScriptsLoaderService } from './instana-scripts-loader.service';

@NgModule({
	imports: [],
	exports: [],
	declarations: [],
	providers: [ConfigService, InstanaScriptsLoaderService]
})
export class InstanaModule {}
