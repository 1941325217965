import { iso369to3166 } from '@nx-bundesliga/bundesliga-com/framework/common';
import { Script } from './script-loader.service';

export const ScriptLoaderStore: Script[] = [
	{
		name: 'twitter',
		consentCategory: 'C0005',
		src: 'https://platform.twitter.com/widgets.js',
		preconnect: ['https://platform.twitter.com']
	},
	{
		name: 'x',
		consentCategory: 'C0005',
		src: 'https://platform.x.com/widgets.js',
		preconnect: ['https://platform.x.com']
	},
	{
		name: 'jw-cloud-default',
		consentCategory: 'C0001',
		src: (data) => `https://content.jwplatform.com/libraries/${data.jwPlayerId}.js`,
		preconnect: ['https://content.jwplatform.com']
	},
	{
		name: 'jw-cloud-schedule-annoucement',
		consentCategory: 'C0001',
		src: (data) => `https://content.jwplatform.com/libraries/${data.jwPlayerId}.js`,
		preconnect: ['https://content.jwplatform.com']
	},
	{
		name: 'apester-legacy',
		consentCategory: 'C0003',
		nomodule: true,
		src: 'https://static.apester.com/js/sdk/latest/apester-javascript-sdk.min.js'
	},
	{
		name: 'apester',
		consentCategory: 'C0003',
		defer: true,
		src: 'https://sdk.apester.com/web-sdk.core.min.js',
		preconnect: ['https://sdk.apester.com']
	},
	{
		name: 'facebook',
		consentCategory: 'C0005',
		src: (data) => `https://connect.facebook.net/${iso369to3166(data.lang)}/sdk.js`,
		preconnect: ['https://connect.facebook.net']
	},
	{
		name: 'instagram',
		consentCategory: 'C0005',
		src: (data) => `https://platform.instagram.com/${iso369to3166(data.lang)}/embeds.js`,
		preconnect: ['https://platform.instagram.com']
	},
	{
		name: 'gtag',
		consentCategory: 'C0001',
		body: (data) =>
			`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','${data.gtag}');`,
		preconnect: ['https://www.googletagmanager.com']
	},
	{
		name: 'dfp',
		consentCategory: 'C0001', // C0004 is evaluated in dfp service itself
		src: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
		preconnect: ['https://securepubads.g.doubleclick.net'],
		defer: false,
		async: true
	},
	{
		name: 'instana',
		consentCategory: 'C0002',
		body: (data) => `(function(s,t,a,n){s[t]||(s[t]=a,n=s[a]=function(){n.q.push(arguments)},n.q=[],n.v=2,n.l=1*new Date)})(window,"InstanaEumObject","ineum");ineum('reportingUrl', 'https://eum-blue-saas.instana.io');ineum('key', '${data.instanaApikey}');ineum('trackSessions');`
	},
	{
		name: 'instana-sdk',
		consentCategory: 'C0002',
		src: 'https://eum.instana.io/eum.min.js',
		defer: true,
		crossorigin: 'anonymous'
	},
	{
		name: 'onetrust',
		consentCategory: 'C0001',
		charset: 'UTF-8',
		'data-document-language': 'true',
		'data-domain-script': (data) => `${data.onetrustKey}`,
		src: () => `https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`,
		body: () => `{function OptanonWrapper(){}}`,
		preconnect: ['https://cdn.cookielaw.org'],
		defer: false,
		async: true
	},
	{
		name: 'google-maps',
		consentCategory: 'C0003',
		src: (data) => `https://maps.googleapis.com/maps/api/js?key=${data.gmapsApiKey}`,
		preconnect: ['https://maps.googleapis.com']
	}
];
