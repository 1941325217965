import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { CommonsLibModule } from '@nx-bundesliga/commons';
import { DflNgxLazyLoadModule } from '@nx-bundesliga/shared/dfl-ngx-lazyload';
import { DflJsonLdComponent } from './component/dfl-json-ld/dfl-json-ld.component';
import { CompetitionClubsPipe, SlugToClubPipe, ToClubPipe } from './pipe/club.pipe';
import { CompetitionPipe } from './pipe/competition.pipe';
import { FixturesMatchJsonLdPipe } from './pipe/fixturesMatchJsonLd.pipe';
import { PersonPipe } from './pipe/player.pipe';
import { PlayernamePipe } from './pipe/playername.pipe';
import { TimeAgoPipe } from './pipe/time-ago.pipe';
import { TranslateRoutePipe } from './pipe/translateRoute.pipe';
import { EmbedPlatformNamePipe } from './pipe/embedPlatformName.pipe';
import { FixturesMatchcenterLinkPipe } from './pipe/translateMatchCenterLink.pipe';
import { FixturesKickoffLivePipe } from './pipe/fixturesKickOffLive.pipe';

@NgModule({
	imports: [CommonModule, TranslateModule.forChild(), DflNgxLazyLoadModule, MatProgressSpinnerModule, MatButtonModule, NgOptimizedImage, CommonsLibModule],

	providers: [TranslateRoutePipe, ToClubPipe, SlugToClubPipe, CompetitionClubsPipe, PersonPipe, PlayernamePipe, CompetitionPipe, EmbedPlatformNamePipe],
	exports: [CommonModule, CommonsLibModule, DflJsonLdComponent, TimeAgoPipe, TranslateRoutePipe, ToClubPipe, SlugToClubPipe, CompetitionClubsPipe, PersonPipe, PlayernamePipe, CompetitionPipe, FixturesMatchJsonLdPipe, EmbedPlatformNamePipe, FixturesMatchcenterLinkPipe, FixturesKickoffLivePipe],

	declarations: [DflJsonLdComponent, TimeAgoPipe, TranslateRoutePipe, ToClubPipe, SlugToClubPipe, CompetitionClubsPipe, PersonPipe, PlayernamePipe, CompetitionPipe, FixturesMatchJsonLdPipe, EmbedPlatformNamePipe, FixturesMatchcenterLinkPipe, FixturesKickoffLivePipe]
})
export class CommonsModule {}
