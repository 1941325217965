/* eslint-disable @nx/enforce-module-boundaries */
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { BrowserModule, TransferState } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader } from '@ngx-translate/core';
import { AnalyticsModule } from '@nx-bundesliga/bundesliga-com/framework/analytics';
import { APP_ENVIRONMENT, CoreModule as MigratedCoreModule, SharedModule, configFactory as migratedConfigFactory, metaFactory as migratedMetaFactory } from '@nx-bundesliga/bundesliga-com/framework/core';
import { I18NModule as MigratedI18NModule, factoryTranslateLoader as migratedFactoryTranslateLoader } from '@nx-bundesliga/bundesliga-com/framework/i18n';
import { MetaLoader as MigratedMetaLoader } from '@nx-bundesliga/bundesliga-com/framework/meta';
import { AppstoreModule } from '@nx-bundesliga/bundesliga-com/framework/store';
import { provideAnalyticLoaderService, provideAnalyticRouterTracking } from '@nx-bundesliga/shared/config/environment-config';
import { ConfigLoader as MigratedConfigLoader, ConfigService as MigratedConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { OnetrustModule } from '../../../web/src/app/framework/cookie/onetrust/onetrust.module';
import { HttpTimeoutModule } from '../../../web/src/app/framework/http/http-timeout/http-timeout.module';
import { TransferHttpCacheModule } from '../../../web/src/app/framework/http/transfer-http-cache/transfer-http-cache.module';
import { InstanaModule } from '../../../web/src/app/framework/monitoring/instana/instana.module';
import { WrapperPageModule } from '../../../web/src/app/pages/wrapper/wrapper.module';
import { ServerResponseService } from '../../../web/src/app/services/server-response/server-response.service';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		RouterModule.forRoot(appRoutes, {
			anchorScrolling: 'enabled',
			initialNavigation: 'enabledBlocking',
			paramsInheritanceStrategy: 'always',
			useHash: false,
			// relativeLinkResolution: 'corrected',
			enableTracing: false,
			// https://angular.io/api/router/ExtraOptions#scrollPositionRestoration
			scrollPositionRestoration: 'enabled'
		}),
		AppstoreModule,
		environment.build !== 'prod' ? StoreDevtoolsModule.instrument({ maxAge: 100 }) : [],
		BrowserAnimationsModule,
		WrapperPageModule,
		HttpClientModule,
		TransferHttpCacheModule,
		HttpTimeoutModule,
		SharedModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireDatabaseModule,
		MigratedCoreModule.forRoot([
			{
				provide: APP_ENVIRONMENT,
				useValue: environment
			},
			{
				provide: MigratedConfigLoader,
				useFactory: migratedConfigFactory,
				deps: [HttpClient, APP_ENVIRONMENT]
			},
			{
				provide: MigratedMetaLoader,
				useFactory: migratedMetaFactory,
				deps: [MigratedConfigService]
			}
		]),
		MigratedI18NModule.forRoot([
			{
				provide: TranslateLoader,
				useFactory: migratedFactoryTranslateLoader,
				deps: [HttpClient, TransferState]
			}
		]),
		AnalyticsModule.forRoot(),
		// global components
		InstanaModule,
		OnetrustModule
	],
	providers: [ServerResponseService, provideAnalyticLoaderService(), provideAnalyticRouterTracking()],
	bootstrap: [AppComponent]
})
export class AppModule {}
