import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AutoUnsubscribe } from '@nx-bundesliga/ngx-auto-unsubscribe-decorator';
import { Subscription } from 'rxjs';
import { BroadcasterLogo } from '@nx-bundesliga/models';
import { EpgService } from '../../../../services/epg/epg.service';

@Component({
	selector: 'broadcaster-logo',
	templateUrl: './broadcaster-logo.component.html',
	styleUrls: ['./broadcaster-logo.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BroadcasterLogoComponent implements OnInit {
	public broadcasterLogo: BroadcasterLogo;
	private isBrowser = false;
	@AutoUnsubscribe() private subscription: Subscription;

	constructor(public epgService: EpgService, private cdRef: ChangeDetectorRef, @Inject(PLATFORM_ID) platformId: Object) {
		this.isBrowser = isPlatformBrowser(platformId);
	}

	ngOnInit() {
		// exclude broadcasterLogo from ssr to avoid displaying broadcasterLogo based on server location instead of client location
		if (this.isBrowser) {
			this.subscription = this.epgService.getRandomBroadcaster().subscribe({
				next: (data: BroadcasterLogo) => {
					this.broadcasterLogo = data;
					this.cdRef.detectChanges();
				},
				error: (err) => {
					this.broadcasterLogo = null;
					this.cdRef.detectChanges();
				}
			});
		}
	}
}
