import { EditorialBreakingNews } from './breakingNews.model';
import { EditorialHomeTeaser, EditorialTeaser } from './overview.model';

/**
 * EditorialHomeTeaser also supports empty and custom-tile blocks
 */
export interface EditorialHome {
	featuredArticles: EditorialHomeTeaser[];
	newsAndFeatures: EditorialHomeTeaser[];
	mostShared: EditorialTeaser[];
	mostWatched: EditorialTeaser[];

	breakingNews?: EditorialBreakingNews;
}
