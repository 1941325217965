export interface Club {
	id: string;
	competition?: 'bundesliga' | '2bundesliga';
	externalClubIds: {
		dflDatalibraryClubId: string;
	};
	name: {
		full: string;
		slugifiedFull: string;
		slugifiedSmall: string;
		small: string;
		withFormOfCompany: string;
	};
	threeLetterCode: string;
	founded: string;
	contact: {
		email?: string;
		phone?: string;
		fax?: string;
		homepage: string;
		tickets: string;
		twitter?: string;
		x?: string;
		facebook?: string;
		instagram?: string;
		street?: string;
		houseNumber?: string;
		postalCode?: string;
		city?: string;
	};
	colors: {
		club: {
			primary: ClubColor;
			primaryText: ClubColor;
			secondary: ClubColor;
			secondaryText: ClubColor;
		};
		jersey: {
			home: {
				primary: ClubColor;
				secondary: ClubColor;
				number: ClubColor;
			};
			away: {
				primary: ClubColor;
				secondary: ClubColor;
				number: ClubColor;
			};
			alternative: {
				primary: ClubColor;
				secondary: ClubColor;
				number: ClubColor;
			};
		};
	};
	logos: ClubImage[];
	stadium?: {
		name?: string;
		mapsUrl?: string;
		capacity?: string;
		imageUrl?: string;
		stadiumIconUrlBlack?: string;
		stadiumIconUrlWhite?: string;
	};
	playedMatches?: [
		{
			'matchId': string;
		}
	];
}

interface ClubColor {
	hex: string;
	name: string;
}

interface ClubImage {
	id: string;
	uri: string;
}
