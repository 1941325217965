import { NgModule } from '@angular/core';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { MatchTeamModule } from '../match-team/match-team.module';
import { ScoreBugModule } from '../score-bug/score-bug.module';
import { MatchFixtureComponent } from './match-fixture.component';
import { CommonModule } from '@angular/common';

@NgModule({
	declarations: [MatchFixtureComponent],
	exports: [MatchFixtureComponent],
	imports: [CommonModule, CommonsModule, MatchTeamModule, ScoreBugModule]
})
export class MatchFixtureModule {}
