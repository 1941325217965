/* eslint-disable @nx/enforce-module-boundaries */
import { Route } from '@angular/router';
import { configureSsrInitializer } from '@nx-bundesliga/shared/core/core-bootstrap';
import { MatchdayPageModule } from '../../../web/src/app/pages/matchday/matchday.module';

export const appRoutes: Route[] = configureSsrInitializer([
	{
		path: 'account/callback',
		loadChildren: () => import('../../../web/src/app/pages/account/callback-page-routing.module').then((m) => m.AccountCallbackPageRoutingModule)
	},
	{
		path: 'en/account/callback',
		loadChildren: () => import('../../../web/src/app/pages/account/callback-page-routing.module').then((m) => m.AccountCallbackPageRoutingModule)
	},
	{
		path: 'de/account/callback',
		loadChildren: () => import('../../../web/src/app/pages/account/callback-page-routing.module').then((m) => m.AccountCallbackPageRoutingModule)
	},
	{
		path: 'es/account/callback',
		loadChildren: () => import('../../../web/src/app/pages/account/callback-page-routing.module').then((m) => m.AccountCallbackPageRoutingModule)
	},
	{
		path: 'jp/account/callback',
		loadChildren: () => import('../../../web/src/app/pages/account/callback-page-routing.module').then((m) => m.AccountCallbackPageRoutingModule)
	},
	{
		path: 'fr/account/callback',
		loadChildren: () => import('../../../web/src/app/pages/account/callback-page-routing.module').then((m) => m.AccountCallbackPageRoutingModule)
	},
	{
		path: 'pt/account/callback',
		loadChildren: () => import('../../../web/src/app/pages/account/callback-page-routing.module').then((m) => m.AccountCallbackPageRoutingModule)
	},
	{
		path: 'ar/account/callback',
		loadChildren: () => import('../../../web/src/app/pages/account/callback-page-routing.module').then((m) => m.AccountCallbackPageRoutingModule)
	},
	{
		path: 'de',
		children: [
			{
				path: 'editorial/:slug',
				loadChildren: () => import('../../../web/src/app/pages/news-detail/news-detail.module').then((m) => m.NewsDetailPageModule),
				data: { 'tracking': { 'waitForData': true } }
			},
			{
				path: 'info/broadcasters',
				loadChildren: () => import('../../../web/src/app/pages/broadcaster/broadcaster.module').then((m) => m.BroadcasterPageModule),
				data: { 'tracking': { 'path': 'More/Broadcaster' } }
			},
			{
				path: 'info',
				loadChildren: () => import('../../../web/src/app/pages/static-page/static-page.module').then((m) => m.StaticPageModule),
				data: { 'tracking': { 'waitForData': true } }
			},
			{
				path: 'liveticker',
				loadChildren: () => import('../../../web/src/app/pages/liveticker/liveticker.module').then((m) => m.LivetickerPageModule),
				data: { 'tracking': { 'doNotTrack': true } }
			},
			{
				path: 'account',
				loadChildren: () => import('../../../web/src/app/pages/account/account-page.module').then((m) => m.AccountPageModule),
				data: { 'tracking': { 'custom': { 'contentGroup': 'Profile' } } }
			},
			{
				path: 'matchcenter',
				loadChildren: () => import('../../../web/src/app/pages/matchday/matchday.module').then((m) => m.MatchdayPageModule),
				data: { 'tracking': { 'doNotTrack': true } }
			},
			{
				path: 'man-of-the-match-voting-supercup-2024',
				loadChildren: () => import('../../../web/src/app/pages/specialpage-voting/specialpage-voting.module').then((m) => m.SpecialpageVotingModule),
				data: { 'tracking': { 'custom': { 'contentGroup': 'Content Aggregator' } } }
			},
			{
				path: '**',
				loadChildren: () => import('./NotFound/NotFound.module').then((m) => m.NotFoundPageModule)
			}
		]
	},
	{
		path: 'en',
		children: [
			{
				path: 'editorial/:slug',
				loadChildren: () => import('../../../web/src/app/pages/news-detail/news-detail.module').then((m) => m.NewsDetailPageModule),
				data: { 'tracking': { 'waitForData': true } }
			},
			{
				path: 'info/broadcasters',
				loadChildren: () => import('../../../web/src/app/pages/broadcaster/broadcaster.module').then((m) => m.BroadcasterPageModule),
				data: { 'tracking': { 'path': 'More/Broadcaster' } }
			},
			{
				path: 'info',
				loadChildren: () => import('../../../web/src/app/pages/static-page/static-page.module').then((m) => m.StaticPageModule),
				data: { 'tracking': { 'waitForData': true } }
			},
			{
				path: 'liveticker',
				loadChildren: () => import('../../../web/src/app/pages/liveticker/liveticker.module').then((m) => m.LivetickerPageModule),
				data: { 'tracking': { 'doNotTrack': true } }
			},
			{
				path: 'account',
				loadChildren: () => import('../../../web/src/app/pages/account/account-page.module').then((m) => m.AccountPageModule),
				data: { 'tracking': { 'custom': { 'contentGroup': 'Profile' } } }
			},
			{
				path: 'matchcenter',
				loadChildren: () => import('../../../web/src/app/pages/matchday/matchday.module').then((m) => m.MatchdayPageModule),
				data: { 'tracking': { 'doNotTrack': true } }
			},
			{
				path: 'man-of-the-match-voting-supercup-2024',
				loadChildren: () => import('../../../web/src/app/pages/specialpage-voting/specialpage-voting.module').then((m) => m.SpecialpageVotingModule),
				data: { 'tracking': { 'custom': { 'contentGroup': 'Content Aggregator' } } }
			},
			{
				path: '**',
				loadChildren: () => import('./NotFound/NotFound.module').then((m) => m.NotFoundPageModule)
			}
		]
	},
	{
		path: 'es',
		children: [
			{
				path: 'editorial/:slug',
				loadChildren: () => import('../../../web/src/app/pages/news-detail/news-detail.module').then((m) => m.NewsDetailPageModule),
				data: { 'tracking': { 'waitForData': true } }
			},
			{
				path: 'info/broadcasters',
				loadChildren: () => import('../../../web/src/app/pages/broadcaster/broadcaster.module').then((m) => m.BroadcasterPageModule),
				data: { 'tracking': { 'path': 'More/Broadcaster' } }
			},
			{
				path: 'info',
				loadChildren: () => import('../../../web/src/app/pages/static-page/static-page.module').then((m) => m.StaticPageModule),
				data: { 'tracking': { 'waitForData': true } }
			},
			{
				path: 'liveticker',
				loadChildren: () => import('../../../web/src/app/pages/liveticker/liveticker.module').then((m) => m.LivetickerPageModule),
				data: { 'tracking': { 'doNotTrack': true } }
			},
			{
				path: 'account',
				loadChildren: () => import('../../../web/src/app/pages/account/account-page.module').then((m) => m.AccountPageModule),
				data: { 'tracking': { 'custom': { 'contentGroup': 'Profile' } } }
			},
			{
				path: 'matchcenter',
				loadChildren: () => import('../../../web/src/app/pages/matchday/matchday.module').then((m) => m.MatchdayPageModule),
				data: { 'tracking': { 'doNotTrack': true } }
			},
			{
				path: '**',
				loadChildren: () => import('./NotFound/NotFound.module').then((m) => m.NotFoundPageModule)
			}
		]
	},
	{
		path: 'jp',
		children: [
			{
				path: 'editorial/:slug',
				loadChildren: () => import('../../../web/src/app/pages/news-detail/news-detail.module').then((m) => m.NewsDetailPageModule),
				data: { 'tracking': { 'waitForData': true } }
			},
			{
				path: 'info/broadcasters',
				loadChildren: () => import('../../../web/src/app/pages/broadcaster/broadcaster.module').then((m) => m.BroadcasterPageModule),
				data: { 'tracking': { 'path': 'More/Broadcaster' } }
			},
			{
				path: 'info',
				loadChildren: () => import('../../../web/src/app/pages/static-page/static-page.module').then((m) => m.StaticPageModule),
				data: { 'tracking': { 'waitForData': true } }
			},
			{
				path: 'liveticker',
				loadChildren: () => import('../../../web/src/app/pages/liveticker/liveticker.module').then((m) => m.LivetickerPageModule),
				data: { 'tracking': { 'doNotTrack': true } }
			},
			{
				path: 'account',
				loadChildren: () => import('../../../web/src/app/pages/account/account-page.module').then((m) => m.AccountPageModule),
				data: { 'tracking': { 'custom': { 'contentGroup': 'Profile' } } }
			},
			{
				path: 'matchcenter',
				loadChildren: () => import('../../../web/src/app/pages/matchday/matchday.module').then((m) => m.MatchdayPageModule),
				data: { 'tracking': { 'doNotTrack': true } }
			},
			{
				path: '**',
				loadChildren: () => import('./NotFound/NotFound.module').then((m) => m.NotFoundPageModule)
			}
		]
	},
	{
		path: 'fr',
		children: [
			{
				path: 'editorial/:slug',
				loadChildren: () => import('../../../web/src/app/pages/news-detail/news-detail.module').then((m) => m.NewsDetailPageModule),
				data: { 'tracking': { 'waitForData': true } }
			},
			{
				path: 'info/broadcasters',
				loadChildren: () => import('../../../web/src/app/pages/broadcaster/broadcaster.module').then((m) => m.BroadcasterPageModule),
				data: { 'tracking': { 'path': 'More/Broadcaster' } }
			},
			{
				path: 'info',
				loadChildren: () => import('../../../web/src/app/pages/static-page/static-page.module').then((m) => m.StaticPageModule),
				data: { 'tracking': { 'waitForData': true } }
			},
			{
				path: 'liveticker',
				loadChildren: () => import('../../../web/src/app/pages/liveticker/liveticker.module').then((m) => m.LivetickerPageModule),
				data: { 'tracking': { 'doNotTrack': true } }
			},
			{
				path: 'account',
				loadChildren: () => import('../../../web/src/app/pages/account/account-page.module').then((m) => m.AccountPageModule),
				data: { 'tracking': { 'custom': { 'contentGroup': 'Profile' } } }
			},
			{
				path: 'matchcenter',
				loadChildren: () => import('../../../web/src/app/pages/matchday/matchday.module').then((m) => m.MatchdayPageModule),
				data: { 'tracking': { 'doNotTrack': true } }
			},
			{
				path: '**',
				loadChildren: () => import('./NotFound/NotFound.module').then((m) => m.NotFoundPageModule)
			}
		]
	},
	{
		path: 'pt',
		children: [
			{
				path: 'editorial/:slug',
				loadChildren: () => import('../../../web/src/app/pages/news-detail/news-detail.module').then((m) => m.NewsDetailPageModule),
				data: { 'tracking': { 'waitForData': true } }
			},
			{
				path: 'info/broadcasters',
				loadChildren: () => import('../../../web/src/app/pages/broadcaster/broadcaster.module').then((m) => m.BroadcasterPageModule),
				data: { 'tracking': { 'path': 'More/Broadcaster' } }
			},
			{
				path: 'info',
				loadChildren: () => import('../../../web/src/app/pages/static-page/static-page.module').then((m) => m.StaticPageModule),
				data: { 'tracking': { 'waitForData': true } }
			},
			{
				path: 'liveticker',
				loadChildren: () => import('../../../web/src/app/pages/liveticker/liveticker.module').then((m) => m.LivetickerPageModule),
				data: { 'tracking': { 'doNotTrack': true } }
			},
			{
				path: 'account',
				loadChildren: () => import('../../../web/src/app/pages/account/account-page.module').then((m) => m.AccountPageModule),
				data: { 'tracking': { 'custom': { 'contentGroup': 'Profile' } } }
			},
			{
				path: 'matchcenter',
				loadChildren: () => import('../../../web/src/app/pages/matchday/matchday.module').then((m) => m.MatchdayPageModule),
				data: { 'tracking': { 'doNotTrack': true } }
			},
			{
				path: '**',
				loadChildren: () => import('./NotFound/NotFound.module').then((m) => m.NotFoundPageModule)
			}
		]
	},
	{
		path: 'ar',
		children: [
			{
				path: 'editorial/:slug',
				loadChildren: () => import('../../../web/src/app/pages/news-detail/news-detail.module').then((m) => m.NewsDetailPageModule),
				data: { 'tracking': { 'waitForData': true } }
			},
			{
				path: 'info/broadcasters',
				loadChildren: () => import('../../../web/src/app/pages/broadcaster/broadcaster.module').then((m) => m.BroadcasterPageModule),
				data: { 'tracking': { 'path': 'More/Broadcaster' } }
			},
			{
				path: 'info',
				loadChildren: () => import('../../../web/src/app/pages/static-page/static-page.module').then((m) => m.StaticPageModule),
				data: { 'tracking': { 'waitForData': true } }
			},
			{
				path: 'liveticker',
				loadChildren: () => import('../../../web/src/app/pages/liveticker/liveticker.module').then((m) => m.LivetickerPageModule),
				data: { 'tracking': { 'doNotTrack': true } }
			},
			{
				path: 'account',
				loadChildren: () => import('../../../web/src/app/pages/account/account-page.module').then((m) => m.AccountPageModule),
				data: { 'tracking': { 'custom': { 'contentGroup': 'Profile' } } }
			},
			{
				path: 'matchcenter',
				loadChildren: () => import('../../../web/src/app/pages/matchday/matchday.module').then((m) => m.MatchdayPageModule),
				data: { 'tracking': { 'doNotTrack': true } }
			},
			{
				path: '**',
				loadChildren: () => import('./NotFound/NotFound.module').then((m) => m.NotFoundPageModule)
			}
		]
	},
	{
		path: '**',
		loadChildren: () => import('./NotFound/NotFound.module').then((m) => m.NotFoundPageModule)
	}
]);
