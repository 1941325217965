import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { logout } from '@nx-bundesliga/bundesliga-com/framework/store-actions';
import { getAuthenticated } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { ProfileUser } from '@nx-bundesliga/models';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { LocalStorageService } from '@nx-bundesliga/bundesliga-com/services/common';

@Component({
	selector: 'header-profilenavigation',
	templateUrl: './profilenavigation.component.html',
	styleUrls: ['./profilenavigation.component.scss']
})
export class ProfilenavigationComponent implements OnInit {
	@Input() language = 'de';
	@Input() competition = 'bundesliga';

	public competitionUrlAccount = 'bundesliga';

	public userAuthorized$: Observable<boolean>;
	constructor(private pstore: Store<ProfileUser>, private location: Location, private localStorageService: LocalStorageService) {}

	ngOnInit(): void {
		// redirect euro etc to bundesliga
		this.competitionUrlAccount = this.competition.includes('bundesliga') ? this.competition : 'bundesliga';

		this.userAuthorized$ = this.pstore.pipe(select(getAuthenticated));
	}

	public logout(): void {
		this.pstore.dispatch(logout());
	}

	public storeCurrentUrlInLocalStorage() {
		const currentUrl = this.location.path();
		this.localStorageService.saveData('targetUrlAfterSingIn', currentUrl);
	}
}
