/**
 * Reformat event.countdown string, because Safari can't handle our dateformat, since it's lacking a colon between the timezone values
 * i.e. "2018-12-14T20:30:00+0100" to "2018-12-14T20:30:00+01:00"
 *
 * @param date
 */
export function insertColonToTimezone(date: string): string {
	// some requests send a datetime with Z to indicate zero hour offset, however most scripts are expecting +0000
	if (date.endsWith('Z')) {
		return date.substr(0, date.length - 1) + '+00:00';
	}

	// @todo only apply if last 4000 chars are numerical
	return date.substr(-3, 1) === ':' ? date : date.substr(0, date.length - 2) + ':' + date.substr(date.length - 2);
}
