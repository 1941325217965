import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { initialLanguage, Language } from '@nx-bundesliga/models';
import { getWorkingLanguage } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';

interface BundesligaRestServiceHttpOptions {
	headers?: HttpHeaders;
	observe?: 'body';
	params?: HttpParams | { [param: string]: string | string[] };
	reportProgress?: boolean;
	responseType?: 'json';
	withCredentials?: boolean;
}

@Injectable({
	providedIn: 'root'
})
export abstract class BundesligaRestService {
	public defaults: any;
	public settings: any;

	/**
	 *
	 * @param data
	 */
	static handleError(data) {
		return throwError(data);
	}

	protected constructor(public httpClient: HttpClient, private configService: ConfigService, private readonly languageStore: Store<Language>) {}

	/**
	 * Returns a HttpHeaders object with the default headers required for Bundesliga.com API access, i.e.:
	 * - Content-Type: application/json
	 * - accept-language: current language in store
	 * - x-api-key (or whatever is defined in settings[header]): settings[key]
	 */
	protected getDefaultHeaders(overrides: any = {}): Observable<HttpHeaders> {
		return this.languageStore.pipe(
			select(getWorkingLanguage),
			filter((language: Language) => language !== initialLanguage),
			take(1),
			map((language: Language) => {
				let headers = new HttpHeaders()
					.append('Content-Type', 'application/json')
					.append('accept-language', overrides.hasOwnProperty('accept-language') ? overrides['accept-language'] : language.culture)
					.append(this.settings.header || this.defaults.header, this.settings.key || this.defaults.key);

				if (overrides.hasOwnProperty('country')) {
					headers = headers.append('country', overrides.country);
				}

				return headers;
			})
		);
	}

	/**
	 * Builds the final URL to query against
	 *
	 * @param relativePath
	 */
	protected buildRequestUrl(relativePath: string) {
		if (this.settings.uri) {
			return this.settings.uri;
		}

		const base = this.settings.base || this.defaults.base;
		const path = typeof this.settings === 'string' ? this.settings : this.settings.path;

		if (relativePath === '') {
			return `${base}/${path}`;
		} else if (relativePath.startsWith('?')) {
			return `${base}/${path}${relativePath}`;
		} else {
			return `${base}/${path}/${relativePath}`;
		}
	}

	/**
	 * Wraps the defaultHeaders in an object, ready to use for the Angular HttpClient.
	 */
	protected buildRequestOptions(options: any, headers?: any): Observable<BundesligaRestServiceHttpOptions> {
		return this.getDefaultHeaders(headers).pipe(
			take(1),
			map((defaultHeaders: HttpHeaders) => {
				return {
					headers: defaultHeaders as HttpHeaders,
					responseType: 'json',
					observe: 'body',
					...options
				} as BundesligaRestServiceHttpOptions;
			})
		);
	}

	/**
	 * Queries the BundesligaAPI
	 *
	 * @param path
	 * @param pathIsAbsolute
	 * @param options the options for the http GET request used in Angular HttpClient
	 * @param headers
	 */
	protected get<T>(path: string, pathIsAbsolute = false, options?: any, headers?: any): Observable<T> {
		if (!this.defaults) {
			this.defaults = this.configService.getSettings('endpoints.defaults');
		}

		const url = pathIsAbsolute ? path : this.buildRequestUrl(path);

		return this.buildRequestOptions(options, headers).pipe(
			switchMap((mergedOptions: BundesligaRestServiceHttpOptions) => {
				return this.httpClient.get<T>(url, mergedOptions).pipe(catchError((data) => BundesligaRestService.handleError(data)));
			})
		);
	}
}
