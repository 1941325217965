import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatchesService } from '@nx-bundesliga/bundesliga-com/services/matches';
import { Match, MatchClub, MatchScores } from '@nx-bundesliga/models';

@Component({
	selector: 'score-bug',
	templateUrl: './score-bug.component.html',
	styleUrls: ['./score-bug.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScoreBugComponent implements OnInit, OnChanges {
	@Input() match: Match;
	@Input() teamHome: MatchClub;
	@Input() teamAway: MatchClub;
	@Input() score: MatchScores;
	@Input() competitionId?: string;

	public isEuroCompetition: boolean;
	public isLiveMatch: boolean;

	constructor(private matchesService: MatchesService) {}

	ngOnInit(): void {
		this.determineCompetition();
	}

	ngOnChanges(): void {
		this.isLiveMatch = this.matchesService.isLiveMatch(this.match);
	}

	determineCompetition(): void {
		this.isEuroCompetition = this.competitionId === 'DFL-COM-J0002E';
	}
}
