import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';
import { AnalyticsService } from '@nx-bundesliga/bundesliga-com/framework/analytics';
import { AnalyticsOnDirective, AnalyticsOnModule } from '@nx-bundesliga/bundesliga-com/framework/analytics';
import { SharedModule } from '@nx-bundesliga/bundesliga-com/framework/core';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { EpgBroadcasterOverlayModule } from '../epg-broadcaster-overlay/epg-broadcaster-overlay.module';
import { MatchbarComponent, MatchbarMatchcenterLinkPipe, MatchbarMatchLivePipe } from './component/matchbar.component';
import { MinutePipe } from './pipe/minute.pipe';
import { IncludesPipe } from './pipe/includes.pipe';
import { MatchesService } from '@nx-bundesliga/bundesliga-com/services/matches';

@NgModule({
	imports: [SharedModule, TranslateModule.forChild(), CommonsModule, AnalyticsOnModule, EpgBroadcasterOverlayModule, SwiperModule],
	exports: [MatchbarComponent],
	providers: [MatchesService, AnalyticsOnDirective, AnalyticsService],
	declarations: [MatchbarComponent, MinutePipe, IncludesPipe, MatchbarMatchcenterLinkPipe, MatchbarMatchLivePipe]
})
export class MatchbarModule {}
