import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Subscription } from 'rxjs';
import { AnalyticsService } from '@nx-bundesliga/bundesliga-com/framework/analytics';
import { WINDOW } from '@nx-bundesliga/bundesliga-com/framework/window';
import { AppBanner } from '../../../../models/app-banner/app-banner.model';

@Component({
	selector: 'app-banner-bottom',
	templateUrl: 'app-banner-bottom.component.html',
	styleUrls: ['app-banner-bottom.component.scss']
})
export class AppBannerBottomComponent implements OnInit, OnDestroy {
	testVersion: string;
	constructor(@Inject('bannerModel') private bannerModel: AppBanner, @Inject(WINDOW) private window: Window, private analyticsService: AnalyticsService, public bottomSheet: MatBottomSheet) {}
	@ViewChild('bottomSheetContainer', { static: true })
	overlay: TemplateRef<any>;
	public bottomSheetReference: MatBottomSheetRef;
	private bottomSheetReferenceSubscription: Subscription;
	public model: AppBanner;

	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() close: EventEmitter<{ days: number; promo: string }> = new EventEmitter<{ days: number; promo: string }>(true);

	ngOnInit(): void {
		this.model = this.bannerModel;
		// setting the test version for the AB test
		//this.testVersion = Math.random() < 0.5 ? 'A' : 'B';
		this.testVersion = 'B';
		this.bottomSheetReference = this.bottomSheet.open(this.overlay, {
			hasBackdrop: true,
			closeOnNavigation: true,
			disableClose: false,
			panelClass: 'panelWhite',
			data: this.model
		});
		this.bottomSheetReferenceSubscription = this.bottomSheetReference.afterDismissed().subscribe((appClicked) => {
			this.analyticsService.eventTrack.next({
				event: 'interaction',
				category: 'Promo Banner APP',
				action: 'Promo Banner APP Click',
				label: appClicked ? 'footer Click APP' : 'footer Close Stay-in-Browser',
				abTestVersion: this.testVersion
			});
			this.close.emit({
				days: appClicked ? 14 : 0,
				promo: this.bannerModel.promo
			});
		});
	}

	public onBottomSheetAppClick() {
		if (this.bottomSheetReference) {
			this.bottomSheetReference.dismiss(true);
		}
		if (this.model) {
			this.window.open(this.model.url, '_blank');
		}
	}

	public onBottomSheetCloseClick() {
		if (this.bottomSheetReference) {
			this.bottomSheetReference.dismiss(false);
		}
	}

	ngOnDestroy(): void {
		if (this.bottomSheetReferenceSubscription) {
			this.bottomSheetReferenceSubscription.unsubscribe();
		}
	}
}
