export interface BroadcasterRegion {
	[key: string]: {
		'countries': BroadcasterRegionCountry[];
		'name': string;
	};
	/*
  'ASIA': {
    'countries': BroadcasterRegionCountry[]
    'name': string;
  };
  'EUROPE': {
    'countries': BroadcasterRegionCountry[]
    'name': string;
  };
  'NORTH_CENTRAL_AMERICA': {
    'countries': BroadcasterRegionCountry[]
    'name': string;
  };
  'OCEANIA': {
    'countries': BroadcasterRegionCountry[]
    'name': string;
  };
  'SOUTH_AMERICA': {
    'countries': BroadcasterRegionCountry[]
    'name': string;
  };*/
}

export interface BroadcasterRegionCountry {
	'broadcasters': BroadcasterRegionCountryBroadcaster[];
	'countryCode': string;
	'id': string;
	'name': string;
	'nameLocalized'?: string;
	continent?: string;
}

export interface BroadcasterRegionCountryBroadcaster {
	'id': string;
	'logos': BroadcasterRegionCountryBroadcasterLogo;
	'name': string;
	'url': string;
}

interface BroadcasterRegionCountryBroadcasterLogo {
	'default': string;
	'dark'?: string;
}
