import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { EMPTY, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class HeaderService {
	public headerOffset$ = new ReplaySubject<number>(1);

	constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

	public getOffset(): Observable<number> {
		if (isPlatformBrowser(this.platformId)) {
			return this.headerOffset$.asObservable().pipe(distinctUntilChanged());
		}
		return EMPTY;
	}

	public setOffset(height: number): void {
		this.headerOffset$.next(height);
	}
}
