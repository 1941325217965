import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { WINDOW } from '@nx-bundesliga/bundesliga-com/framework/window';

export const AVAILABLE_THEMES = ['light', 'dark'] as const;
export type Themes = (typeof AVAILABLE_THEMES)[number];

export const AVAILABLE_MODES = [...AVAILABLE_THEMES, 'auto'] as const;
export type Modes = (typeof AVAILABLE_MODES)[number];

@Injectable({
	providedIn: 'root'
})
export class ThemingService {
	private readonly localStorageKey = 'bl-force-theme';
	private readonly mediaQuery = '(prefers-color-scheme: dark)';
	private preferredColorScheme: Themes;

	public theme$ = new ReplaySubject<Themes>(1);
	public mode$ = new ReplaySubject<Modes>(1);

	/**
	 *
	 * @param platformId
	 * @param document
	 * @param window
	 */
	constructor(@Inject(PLATFORM_ID) private platformId, @Inject(DOCUMENT) private document, @Inject(WINDOW) private window) {
		if (isPlatformBrowser(platformId)) {
			// LOL. But the getter below actually retrieves the value from localstorage (or defaults to auto)
			// And the setter then emits the initial value. A bit backwards, but it works :')
			// eslint-disable-next-line no-self-assign
			this.mode = this.mode;
			this.setPreferredColorScheme(this.window.matchMedia(this.mediaQuery).matches);

			this.window.matchMedia(this.mediaQuery).addEventListener('change', (e) => {
				this.setPreferredColorScheme(e.matches);
			});
		}
	}

	/**
	 *
	 * @param prefersDarkMode
	 * @private
	 */
	private setPreferredColorScheme(prefersDarkMode: boolean) {
		this.preferredColorScheme = prefersDarkMode ? 'dark' : 'light';
		this.shouldApplyDarkTheme();
	}

	/**
	 *
	 * @param mode
	 */
	public setMode(mode: Modes) {
		this.mode = mode;
		this.shouldApplyDarkTheme();
	}

	/**
	 *
	 * @private
	 */
	private shouldApplyDarkTheme() {
		if (this.mode === 'auto') {
			this.theme = this.preferredColorScheme;
		} else {
			this.theme = this.mode;
		}
	}

	/**
	 *
	 * @param mode
	 */
	set mode(mode: Modes) {
		this.window.localStorage.setItem(this.localStorageKey, mode);
		this.mode$.next(mode);
	}

	/**
	 *
	 */
	get mode() {
		return this.window.localStorage.getItem(this.localStorageKey) || 'auto';
	}

	/**
	 *
	 * @param theme
	 */
	set theme(theme: Themes) {
		if (theme === 'dark') {
			this.document.documentElement.setAttribute('dark', '1');
		} else {
			this.document.documentElement.removeAttribute('dark');
		}

		this.window.dataLayer?.push({
			event: 'mode_info',
			mode: `${theme}_${this.mode === 'auto' ? 'system' : 'manual'}`
		});

		this.theme$.next(theme);
	}
}
