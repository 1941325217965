import { Pipe, PipeTransform } from '@angular/core';
import { MatchesService } from '@nx-bundesliga/bundesliga-com/services/matches';
import { Match } from '@nx-bundesliga/models';

@Pipe({
	name: 'fixturesKickoffLive',
	pure: true
})
export class FixturesKickoffLivePipe implements PipeTransform {
	constructor(private matchesService: MatchesService) {}
	transform(matches: Match[], plannedKickoff: string): boolean {
		if (!matches || !plannedKickoff || !(matches?.length > 0)) {
			return false;
		}
		return matches.filter((match: Match) => match.plannedKickOff === plannedKickoff).reduce((acc: boolean, thematch: Match) => this.matchesService.isLiveMatch(thematch) || acc, false);
	}
}
