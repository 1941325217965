export interface ClubStatsRankings<T> {
	ballPossessionRatio?: T;
	cards?: T;
	cardsYellow?: T;
	crossesFromPlay?: T;
	distanceCovered?: T;
	foulsAgainstOpponent?: T;
	goals?: T;
	intensiveRuns?: T;
	ownGoals?: T;
	passesFromPlayRatio?: T;
	penalties?: T;
	penaltiesSuccessful?: T;
	shotsAtGoal?: T;
	shotsAtGoalWoodWork?: T;
	sprints?: T;
	tacklingGamesAirWon?: T;
	tacklingGamesWon?: T;
}
export type ClubStatsRankingsMetrics = keyof ClubStatsRankings<StatsClubRankingsEntry[]>;

export interface StatsClubRankingEntries {
	[clubId: string]: StatsClubRankingsEntry;
}

export interface StatsClubRankingsEntry {
	color: string;
	dflDatalibraryObjectId: string;
	index: number;
	logoUrl: string;
	name: string;
	rank: number;
	textColor: string;
	value: number;
}
