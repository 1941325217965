import { Inject, Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Injectable()
@Pipe({
	name: 'translateRoute',
	pure: false // required to update the value when the promise is resolved
})
export class TranslateRoutePipe implements PipeTransform {
	private subscription: Subscription;
	private prefix = 'ROUTES.';
	// language: string;
	// competition: string;

	/**
	 * CTOR
	 * @param translate
	 */
	constructor(@Inject(TranslateService) private translate: TranslateService) {}

	// TODO: use current language and competition to prepend routes with
	/*
              private readonly cstore: Store<Competition>,
              private readonly lstore: Store<Language>) {
    this.subscription = combineLatest(
      this.lstore.pipe(select(getWorkingLanguage), filter((lang: Language) => lang.code !== '')),
      this.cstore.pipe(select(getWorkingCompetition))
    ).subscribe(
      (data: [Language, Competition]) => {
        this.language = data[0].code;
        this.competition = data[1].name;
        // this.transform(this.lastKey);
      }
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }*/

	/**
	 * Transform current url to localized one
	 * @param path
	 * @returns {string | any[]}
	 */
	transform(path: string | any[]): string | any[] {
		// path is null (e.g. resetting auxiliary outlet)
		if (!path) {
			return path;
		}
		// it's a string
		if (typeof path === 'string') {
			return this.translateRoute(path);
			// TODO: Ehance route completeion by automatically prefixing with language and competition
			/*const url = this.translateRoute(path);
      return !path.indexOf('/') ? `/${this.urlPrefix}/${this.urlPrefix}${url}` : url;*/
		}
		// it's an array
		const result: any[] = [];
		(path as Array<any>).forEach((segment: any, index: number) => {
			if (typeof segment === 'string') {
				const res = this.translateRoute(segment);
				result.push(res);
				// TODO : Ehance route completeion by automatically prefixing with language and competition
				/*if (!index && !segment.indexOf('/')) {
          result.push(`/${this.urlPrefix}/${this.urlPrefix}${res}`);
        } else {
          result.push(res);
        }*/
			} else {
				// translate router outlets block
				if (segment && segment.outlets) {
					const outlets: any = {};
					for (const key in segment.outlets) {
						if (segment.outlets.hasOwnProperty(key)) {
							outlets[key] = this.translateRoute(segment.outlets[key]);
						}
					}
					result.push({ ...segment, outlets: outlets });
				} else {
					result.push(segment);
				}
			}
		});
		return result;
	}

	/**
	 * Translate route and return observable
	 * @param path
	 * @returns {string}
	 */
	translateRoute(path: string): string {
		const queryParts = path.split('?');
		if (queryParts.length > 2) {
			throw new Error('There should be only one query parameter block in the URL');
		}
		const pathSegments = queryParts[0].split('/');

		/** collect observables  */
		return pathSegments.map((part: string) => (part.length ? this.translateText(part) : part)).join('/') + (queryParts.length > 1 ? `?${queryParts[1]}` : '');
	}

	/**
	 * Get translated value
	 * @param key
	 * @returns {any}
	 */
	private translateText(key: string): string {
		const prefixedKey = this.prefix + key;
		const res = this.translate.instant(prefixedKey);

		// if (key === 'route-matchday') {
		//   console.log('translateroute', prefixedKey, key, res);
		// }

		// ignore non-translated text
		if (res === prefixedKey) {
			return key;
		}

		return res || key;
	}
}
