import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { getWorkingClubStateLoaded } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { ClubService } from '@nx-bundesliga/bundesliga-com/services/club';
import { ClubState } from '@nx-bundesliga/models';
import { exhaustMap, of } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { getClubs, getClubsError, getClubsSuccess } from '@nx-bundesliga/bundesliga-com/framework/store-actions';

@Injectable()
export class ClubsEffects {
	constructor(private actions$: Actions, private store$: Store<ClubState>, private clubService: ClubService) {}
	public getClubs$ = createEffect(() =>
		this.actions$.pipe(
			ofType(getClubs),
			mergeMap((action: ReturnType<typeof getClubs>) => of(action).pipe(withLatestFrom(this.store$.pipe(select(getWorkingClubStateLoaded(action.season)))))),
			filter(([action, loaded]: [ReturnType<typeof getClubs>, boolean]) => loaded === false),
			exhaustMap(([action, loaded]: [ReturnType<typeof getClubs>, boolean]) => {
				return this.clubService.getAllClubs(action['season']).pipe(
					map((clubs) =>
						getClubsSuccess({
							clubs: clubs,
							season: action['season']
						})
					),
					catchError((err: Error) =>
						of(
							getClubsError({
								errorMessage: err.message,
								season: action['season']
							})
						)
					)
				);
			})
		)
	);
}
