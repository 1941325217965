import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@nx-bundesliga/bundesliga-com/framework/core';
import { AppBannerModule } from '../app-banner/app-banner.module';
import { SwitchersModule } from '../switchers/switchers.module';
import { BroadcasterLogoComponent } from './component/broadcasterlogo/broadcaster-logo.component';
import { CompetitionnavigationComponent } from './component/competitionnavigation/competitionnavigation.component';
import { HeaderComponent } from './component/header/header.component';

import { HeaderlogoComponent } from './component/headerlogo/headerlogo.component';
import { HeaderMetanavigationComponent } from './component/metanavigation/header-metanavigation.component';
import { MobilenavigationComponent } from './component/mobilenavigation/mobilenavigation.component';
import { HeaderNavigationComponent } from './component/navigation/header-navigation.component';
import { ProfilenavigationComponent } from './component/profilenavigation/profilenavigation.component';
import { DflSimpleIconModule } from '@nx-bundesliga/bundesliga-com/components/dfl-simple-icon';

@NgModule({
	imports: [SharedModule, FormsModule, RouterModule, TranslateModule.forChild(), MatIconModule, DflSimpleIconModule, SwitchersModule, AppBannerModule],
	exports: [HeaderComponent],
	providers: [],
	declarations: [HeaderComponent, HeaderNavigationComponent, HeaderlogoComponent, HeaderMetanavigationComponent, BroadcasterLogoComponent, CompetitionnavigationComponent, ProfilenavigationComponent, MobilenavigationComponent]
})
export class HeaderModule {}
