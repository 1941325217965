import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { WINDOW } from '@nx-bundesliga/bundesliga-com/framework/window';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class LocalStorageService {
	public isBrowser = false;

	constructor(@Inject(PLATFORM_ID) private platformId: Object, @Inject(WINDOW) private window) {
		this.isBrowser = isPlatformBrowser(platformId);
	}

	public saveData(key: string, value: string) {
		if (this.isBrowser === true && this.window) {
			this.window.localStorage.setItem(key, value);
		}
	}

	public getData(key: string) {
		if (this.isBrowser === true && this.window) {
			return this.window.localStorage.getItem(key);
		}
	}

	public removeData(key: string) {
		if (this.isBrowser === true && this.window) {
			this.window.localStorage.removeItem(key);
		}
	}

	public clearData() {
		if (this.isBrowser === true && this.window) {
			this.window.localStorage.clear();
		}
	}
}
