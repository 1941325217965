import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'FooterPartnerlogoComponent',
	templateUrl: './footer-partnerlogo.component.html',
	styleUrls: ['./footer-partnerlogo.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterPartnerlogoComponent {
	@Input() language = 'de';
	@Input() competition = 'bundesliga';
	@Input() naviStructure: any;

	public readonly errorImage = '/assets/logos/default_partnerlogo.svg';
	public readonly defaultImage = '/assets/logos/default_partnerlogo.svg';

	constructor() {}

	trackByLabel(index, item) {
		return item.label;
	}
}
