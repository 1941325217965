import { NgModule } from '@angular/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { AppBannerBottomComponent } from './component/app-banner-bottom/app-banner-bottom.component';
import { AppBannerComponent } from './component/app-banner/app-banner.component';
import { AppBannerService } from '../../services/app-banner/app-banner.service';
import { PlatformModule } from '@angular/cdk/platform';
import { AppBannerWrapperComponent } from './component/app-banner-wrapper/app-banner-wrapper.component';
import { RatingStarComponent } from './component/rating-star/rating-star.component';
import { DflSimpleIconModule } from '@nx-bundesliga/bundesliga-com/components/dfl-simple-icon';
import { CommonsLibModule } from '@nx-bundesliga/commons';

@NgModule({
	imports: [BrowserModule, PlatformModule, MatBottomSheetModule, TranslateModule, DflSimpleIconModule, CommonsLibModule],
	exports: [AppBannerWrapperComponent],
	providers: [AppBannerService],
	declarations: [AppBannerWrapperComponent, AppBannerComponent, AppBannerBottomComponent, RatingStarComponent]
})
export class AppBannerModule {}
