/* eslint-disable @typescript-eslint/ban-types */
import { EMPTY, from as fromObservable, merge, Observable, onErrorResumeNext, throwError } from 'rxjs';
import { filter, isEmpty, mergeMap, reduce, share } from 'rxjs/operators';
import { ConfigLoader, ConfigStaticLoader } from '../../core';

const mergeDeep = (target, source) => {
	const isObject = (item) => {
		return item && typeof item === 'object' && !Array.isArray(item) && item !== null;
	};
	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!target[key]) {
					Object.assign(target, { [key]: {} });
				}
				mergeDeep(target[key], source[key]);
			} else {
				Object.assign(target, { [key]: source[key] });
			}
		}
	}
	return target;
};

/* eslint-disable */
const errorIfEmpty = (source: Observable<any>) =>
	source.pipe(
		isEmpty(),
		mergeMap((empty: boolean) => (empty ? throwError(new Error('No setting found at the specified loader!')) : EMPTY))
	);

const mergeSeries = async (merged: any, current: Promise<any>) => current.then((resolvedSettings) => mergeDeep(merged, resolvedSettings));

export class ConfigMergeLoader implements ConfigLoader {
	private nextLoader: Function;

	constructor(private readonly loaders: Array<ConfigLoader> = []) {}

	loadSettings(): any {
		if (typeof this.nextLoader === 'function') {
			return this.mergeParallel().then(async (res: any) => mergeSeries(res, this.nextLoader(res).loadSettings()));
		}

		return this.mergeParallel();
	}

	next(loader: Function): any {
		this.nextLoader = loader;

		return this;
	}

	private async mergeParallel(): Promise<any> {
		const loaders: Array<ConfigLoader> = [new ConfigStaticLoader(), ...this.loaders];

		const mergedSettings = onErrorResumeNext(loaders.map((loader: ConfigLoader) => fromObservable(loader.loadSettings()))).pipe(
			filter((res: any) => res),
			share()
		);

		return new Promise((resolve, reject: Function) => {
			merge(mergedSettings, errorIfEmpty(mergedSettings), mergedSettings)
				.pipe(reduce((merged: any, current: any) => mergeDeep(merged, current), {}))
				.subscribe(resolve, () => reject('Loaders unreachable!'));
		});
	}
}
