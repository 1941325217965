import { EditorialBase } from '../editorial/editorial.model';

export enum ComponentLoadingState {
	ERROR = 0,
	LOADING = 1,
	LOADED = 2,
	PENDING = 3
}

export interface JWPlayer {
	on(label: string, callback: (data: any) => void): void;
	off(label: string): void;

	addButton(icon: string, label: string, handler: () => void, id: string): void;
	getBuffer(): number;
	getCaptionsList(): any[];
	getControls(): boolean;
	getCurrentCaptions(): number;
	getCurrentQuality(): number;
	getDuration(): number;
	getHeight(): number;
	getFullscreen(): boolean;
	getMute(): boolean;
	getPlaylist(): any[];
	getPlaylistIndex(): number;
	getPlaylistItem(index: number): any;
	getPosition(): number;
	getQualityLevels(): any[];
	getRenderingMode(): string;
	getSafeRegion(): any[];
	getState(): string;
	getVolume(): number;
	getWidth(): number;
	load(playlist: any | string): void;
	onBeforePlay(callback: () => void): void;
	onBuffer(callback: () => void): void;
	onBufferChange(callback: () => void): void;
	onCaptionsChange(callback: () => void): void;
	onCaptionsList(callback: () => void): void;
	onComplete(callback: () => void): void;
	onControls(callback: () => void): void;
	onDisplayClick(callback: () => void): void;
	onError(callback: () => void): void;
	onFullscreen(callback: () => void): void;
	onIdle(callback: () => void): void;
	onMeta(callback: () => void): void;
	onMute(callback: () => void): void;
	onPlay(callback: () => void): void;
	onPlaylist(callback: () => void): void;
	onPlaylistItem(callback: () => void): void;
	onPlaylistComplete(callback: () => void): void;
	onReady(callback: () => void): void;
	onResize(callback: () => void): void;
	onQualityChange(callback: () => void): void;
	onQualityLevels(callback: () => void): void;
	onSeek(callback: () => void): void;
	onTime(callback: () => void): void;
	onVolume(callback: () => void): void;
	pause(): void;
	play(): void;
	playlistItem(index: number): void;
	registerPlugin(id: string, target: string, jsPlugin: () => void, swfURL?: string): void;
	remove(): void;
	removeButton(id: string): void;
	resize(width: number, height: number): void;
	seek(position: number): void;
	setControls(controls: boolean): void;
	setCurrentCaptions(index: number): void;
	setCurrentQuality(index: number): void;
	setMute(state: boolean): void;
	setup(options: any): JWPlayer;
	setVolume(volume: number): void;
	stop(): void;
}

interface JWSource {
	type: string;
	file: string;
	width?: number;
	height?: number;
	label?: string;
}

export interface JWPlaylist {
	mediaid: string;
	title: string;
	image: string;
	sources: JWSource[];
}

interface JWImages {
	src: string;
	width: string;
	type: string;
}

export interface JWVideo {
	mediaid: string;
	title: string;
	image: string;
	sources: JWSource[];
	images: JWImages[];
	description: string;
	feedid: string;
	duration: number;
	pubdate: string;
	link: string;
	tags: string;
}

export interface JWGoalVideo extends JWVideo {
	matchDayName: string;
}

export interface JWManifest {
	feed_instance_id: string;
	description: string;
	title: string;
	kind: string;
	playlist: JWVideo[] | JWPlaylist[];
	priority?: string;
	feedid?: string;
	duration?: string;
	DFL_playlist_meta_description?: string;
}

export interface JWAdConfig {
	[key: string]: JWAdTagConfig[];
}

interface JWAdTagConfig {
	adTag: string;
	adType?: string;
	offset?: string;
}

export interface JWAdSchedule {
	adscheduleid: string;
	client: string;
	preloadAds: boolean;
	rules: {
		startOnSeek: string;
		timeBetweenAds: number;
	};
	schedule: {
		offset?: string;
		tag?: string;
		type?: string;
	};
	vpaidcontrols: boolean;
	vpaidmode: string;
}
