import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { BroadcasterLogo, EpgBroadcasterByCountryResponse, Language, LocalizedCountryList } from '@nx-bundesliga/models';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { Broadcasts, EpgBroadcaster } from '@nx-bundesliga/models';
import { BundesligaRestService } from '@nx-bundesliga/bundesliga-com/services/common';
import { ThemingService } from '../theming-service/theming.service';

@Injectable({
	providedIn: 'root'
})
export class EpgService extends BundesligaRestService {
	// A bug in angular requires us to overwrite ngInjectableDef in all child services, because this sevice inherits ngInjectableDef from
	// BundesligaRestService and therefore Angular can't construct the child service. See:
	// - https://github.com/angular/angular/pull/25736
	// - https://stackoverflow.com/questions/50263722/angular-6-services-and-class-inheritance
	// static ngInjectableDef = undefined;

	constructor(private http: HttpClient, private config: ConfigService, private readonly langStore: Store<Language>, private readonly themingService: ThemingService) {
		super(http, config, langStore);
		this.settings = config.getSettings('endpoints.broadcasts');
	}

	/**
	 * Returns the location-aware EPGBroadcaster data for the given DflDatalibraryClubId
	 * @param DflDatalibraryClubId string the Id of a club
	 */
	public getEpgByClub(DflDatalibraryClubId: string): Observable<EpgBroadcaster[]> {
		this.settings = this.config.getSettings('endpoints.broadcasts');
		return this.get<Broadcasts>(`club/${DflDatalibraryClubId}`).pipe(
			catchError(() => null),
			map((data: Broadcasts) => (data.hasOwnProperty('broadcasts') ? data.broadcasts : []))
		);
	}

	/**
	 * Returns the location-aware EPG data based on the client IP for a given matchday
	 * @param DflDatalibraryCompetitionId string the Id of the Competition
	 * @param DflDatalibraryMatchdayId string the Id of the Matchday
	 */
	public getEpg(DflDatalibraryCompetitionId: string, DflDatalibraryMatchdayId: string): Observable<EpgBroadcaster[]> {
		this.settings = this.config.getSettings('endpoints.broadcasts');
		return this.get<Broadcasts>(`${DflDatalibraryCompetitionId}/${DflDatalibraryMatchdayId}`).pipe(
			catchError(() => null),
			map((data: Broadcasts) => (data.hasOwnProperty('broadcasts') ? data.broadcasts : []))
		);
	}

	public getEpgForMatchId(DflDatalibraryCompetitionId: string, DflDatalibraryMatchdayId: string, DflDatalibraryMatchId: string) {
		return this.getEpg(DflDatalibraryCompetitionId, DflDatalibraryMatchdayId).pipe(
			map((data: EpgBroadcaster[]) => data.filter((item) => item.dflDatalibraryMatchId === DflDatalibraryMatchId)),
			map((data: EpgBroadcaster[]) => data.sort((a, b) => (a.broadcasterName == b.broadcasterName ? 0 : +(a.broadcasterName > b.broadcasterName) || -1)))
		);
	}

	/**
	 * Returns the location-aware EPGBroadcaster data
	 * @param cacheEnabled default false for broadcaster overview
	 * @param promoteInHeader add rank to each brodcaster
	 * @param countryCode string the country code is set as country header
	 */
	public getEpgByCDNEdgeCountry(cacheEnabled = false, promoteInHeader = false, countryCode?: string): Observable<EpgBroadcasterByCountryResponse> {
		this.settings = this.config.getSettings('endpoints.broadcasters');
		let epgPath = `${promoteInHeader ? '?promoteInHeader=true' : ''}`;
		epgPath += `${!cacheEnabled ? '?donotcache' : ''}`;
		return this.get<EpgBroadcasterByCountryResponse>(epgPath, false, {}, countryCode ? { country: countryCode.toUpperCase() } : {}).pipe(
			map((response) => {
				response.broadcasters = response.broadcasters.sort((a, b) => (a.rank !== b.rank ? a.rank - b.rank : a.name == b.name ? 0 : +(a.name > b.name) || -1));
				return response;
			})
		);
	}

	public getRandomBroadcaster(): Observable<BroadcasterLogo> {
		return this.themingService.theme$.pipe(
			mergeMap((theme) => {
				return this.getEpgByCDNEdgeCountry(true, true).pipe(
					map((response) => {
						const broadcaster = response.broadcasters[Math.floor(Math.random() * response.broadcasters.length)];
						return {
							logoUrl: theme === 'dark' ? broadcaster.logos.dark : broadcaster.logos.light,
							name: broadcaster.name,
							url: broadcaster.url
						};
					})
				);
			})
		);
	}

	/**
	 * Returns a localized country list
	 * localized files are generated in angluar.json from i18n-iso-countries module
	 */
	public getLocalizedCountryList(languageIsoCode): Observable<LocalizedCountryList> {
		if (languageIsoCode && languageIsoCode !== '') {
			return this.httpClient.get<LocalizedCountryList>('/assets/i18n-iso-countries/' + languageIsoCode + '.json');
		}
	}
}
