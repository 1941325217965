// angular
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Injector } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { languageInit, useLanguage, useLanguageSuccess, useLanguageUnsupported } from '@nx-bundesliga/bundesliga-com/framework/store-actions';
import { de, enUS, es, ja, fr } from 'date-fns/locale';
// libs
import { map, switchMap, tap } from 'rxjs/operators';
// module
import { I18NService } from '@nx-bundesliga/bundesliga-com/framework/i18n';
import { Language } from '@nx-bundesliga/models';
import { MetaService } from '@nx-bundesliga/bundesliga-com/framework/meta';
import { environment } from '@nx-bundesliga/bundesliga-com/environment';

@Injectable()
export class LanguageEffects {
	init$ = createEffect(
		() =>
			this.actions.pipe(
				ofType(languageInit),
				tap((res) => this.i18n.init(res))
			),
		{ dispatch: false }
	);

	useLanguage$ = createEffect(() =>
		this.actions.pipe(
			ofType(useLanguage),
			map((cur) => cur.language),
			switchMap((res) => this.i18n.getLanguageByCode(res)),
			map((cur: Language) => {
				if (cur.name && environment.i18n.language[cur.code]?.enabled) {
					return useLanguageSuccess(cur);
				} else {
					return useLanguageUnsupported();
				}
			})
		)
	);

	useLanguageSuccess$ = createEffect(
		() =>
			this.actions.pipe(
				ofType(useLanguageSuccess),
				tap((lang: Language) => {
					this.translate.setDefaultLang(lang.code);
					// set material locales
					switch (lang.code) {
						case 'de':
							this.dateAdapter.setLocale(de);
							break;
						case 'es':
							this.dateAdapter.setLocale(es);
							break;
						case 'fr':
							this.dateAdapter.setLocale(fr);
							break;
						case 'jp':
							this.dateAdapter.setLocale(ja);
							break;
						default:
							this.dateAdapter.setLocale(enUS);
							break;
					}
					// set og:locale
					const meta = this.injector.get(MetaService);
					meta.setTag('og:locale', lang.ietf);
					// set lang attribute of Html-Element
					this.document.documentElement.lang = lang.code === 'jp' ? 'ja' : lang.code;
				}),
				switchMap((lang: Language) => this.translate.use(lang.code))
			),
		{ dispatch: false }
	);

	constructor(private readonly actions: Actions, private readonly i18n: I18NService, private readonly translate: TranslateService, private dateAdapter: DateAdapter<any>, private readonly injector: Injector, @Inject(DOCUMENT) private document) {}
}
