import { Observable, of } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { IntersectionStatus } from './intersection-observer';

import { addCssClassName, hasCssClassName, isVisible } from './utils';
import { cssClassNames } from './constants';

export function lazyLoadEmbed(element: HTMLElement, offset: number, scrollContainer?: HTMLElement) {
	return (scrollObservable: Observable<Event>) => {
		return scrollObservable.pipe(
			filter(() => !hasCssClassName(element, cssClassNames.loaded)),
			filter(() => isVisible(element, offset, window, scrollContainer)),
			tap(() => addCssClassName(element, cssClassNames.loaded)),
			map(() => true),
			catchError(() => {
				addCssClassName(element, cssClassNames.failed);
				return of(false);
			})
		);
	};
}

export function lazyLoadEmbedIntersection(element: HTMLElement) {
	return (intersectionObservable: Observable<IntersectionStatus>) => {
		return intersectionObservable.pipe(
			filter(() => !hasCssClassName(element, cssClassNames.loaded)),
			filter((intersectionStatus) => intersectionStatus === IntersectionStatus.Visible),
			tap(() => addCssClassName(element, cssClassNames.loaded)),
			map(() => true),
			catchError(() => {
				addCssClassName(element, cssClassNames.failed);
				return of(false);
			})
		);
	};
}
