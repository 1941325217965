import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { insertColonToTimezone } from '@nx-bundesliga/bundesliga-com/framework/common';

@Pipe({ name: 'timeAgo' })
/**
 * Takes a properly formed date format as string and transforms it into a "time-ago-style" string (i.e. "a few seconds ago").
 */
export class TimeAgoPipe implements PipeTransform {
	constructor(public translate: TranslateService) {}

	transform(input: string): string {
		const now = new Date();
		const date = new Date(insertColonToTimezone(input));

		const seconds = Math.round(Math.abs((now.getTime() - date.getTime()) / 1000));
		if (Number.isNaN(seconds)) {
			// return early since something went completely wrong. sad panda.
			return input;
		}

		const minutes = Math.round(seconds / 60);
		if (minutes === 1) {
			return this.translate.instant('TIME_AGO.minute');
		} else if (minutes <= 59) {
			// return `${minutes} minutes ago`;
			return this.translate.instant('TIME_AGO.minutes', { time: minutes });
		}

		const hours = Math.round(minutes / 60);
		if (hours === 1) {
			return this.translate.instant('TIME_AGO.hour');
		} else if (hours <= 23) {
			// return `${hours} hours ago`;
			return this.translate.instant('TIME_AGO.hours', { time: hours });
		}

		const days = Math.round(hours / 24);
		if (days === 1) {
			return this.translate.instant('TIME_AGO.day');
		} else if (days <= 6) {
			// return `${days} days ago`;
			return this.translate.instant('TIME_AGO.days', { time: days });
		}

		const weeks = Math.round(days / 7);
		if (weeks === 1) {
			return this.translate.instant('TIME_AGO.week');
		} else if (weeks <= 51) {
			// return `${weeks} weeks ago`;
			return this.translate.instant('TIME_AGO.weeks', { time: weeks });
		}

		const years = Math.round(days / 365);
		if (years > 1) {
			// return `${years} years ago`;
			return this.translate.instant('TIME_AGO.years', { time: years });
		} else {
			// return 'a year ago';
			return this.translate.instant('TIME_AGO.year');
		}
	}
}
