export type QualificationId = string;
export type QualificationColor = string;

export const enum TableType {
	'table',
	'live',
	'home',
	'away',
	'first',
	'second',
	'form'
}

export interface Table {
	competition: TableCompetition;
	creationDateTime: string;
	entries: TableEntry[];
	matchday: TableMatchday;
	qualifications: TableQualification[];
	season: TableSeason;
	tableType: TableType;
}

export interface GroupTables {
	[key: string]: GroupTable;
}

export interface GroupTable {
	competition?: TableCompetition;
	creationDateTime?: string;
	entries: GroupTableEntry[];
	groupName: string;
	matchday?: TableMatchday;
	qualifications: TableQualification[];
	season?: TableSeason;
}

export interface BaseTableEntry {
	club: TableEntryClub;
	draws: number;
	gamesPlayed: number;
	goalDifference: number;
	goalsAgainst: number;
	goalsScored: number;
	losses: number;
	points: number;
	wins: number;
	rank: number;
}

export interface GroupTableEntry extends BaseTableEntry {
	qualification?: QualificationId;
	qualificationColor?: QualificationColor;
	tendency?: string;
}

export interface TableEntry extends BaseTableEntry {
	qualification?: QualificationId;
	// qualificationColor is manually mapped from TableQualification node
	qualificationColor?: QualificationColor;
	subRank: number;
	tendency: string;
	isPlaying?: boolean;
	minute?: number;
}

export interface TableQualification {
	color: QualificationColor;
	id: QualificationId;
	title: string;
}

export interface TableEntryClub {
	id: string;
	logoUrl: string;
	nameFull: string;
	nameShort: string;
	slugifiedFull: string;
	slugifiedSmall: string;
	threeLetterCode: string;
}

export interface TableCompetition {
	id: string;
	name: string;
}

export interface TableMatchday {
	id: string;
	name: string;
}

export interface TableSeason {
	id: string;
	name: string;
}
