import { Action, createReducer, on } from '@ngrx/store';
import { languageInit, useLanguage, useLanguageSuccess, useLanguageUnsupported } from '@nx-bundesliga/bundesliga-com/framework/store-actions';
import { I18NState, initialAppstateLanguage } from '@nx-bundesliga/models';

const featureReducer = createReducer(
	initialAppstateLanguage,
	on(languageInit, (state) => initialAppstateLanguage),
	on(useLanguage, (state) => state),
	on(useLanguageSuccess, (state, language) => ({
		...state,
		language
	})),
	on(useLanguageUnsupported, (state) => state)
);

export function reducer(state: I18NState | undefined, action: Action) {
	return featureReducer(state, action);
}
