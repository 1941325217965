import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'FooterCloserComponent',
	templateUrl: './footer-closer.component.html',
	styleUrls: ['./footer-closer.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default
})
export class FooterCloserComponent {
	@Input() language = 'de';
	@Input() competition = 'bundesliga';

	constructor() {}
}
