import { HttpClient } from '@angular/common/http';
import { forwardRef, Inject, resolveForwardRef } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ConfigLoader } from '../../core';

/* eslint-disable */
export class ConfigHttpLoader implements ConfigLoader {
	constructor(
		@Inject(forwardRef(() => HttpClient)) private readonly http: HttpClient,
		private readonly endpoint: string = 'https://wapp.bapi.bundesliga.com/config/configNode.json',
		private readonly fallbackEndpoint: string = 'https://bundesliga-web-prod.europe-west1.firebasedatabase.app/config.json',
		private readonly staticEndpoint: string = 'assets/config/firebase-config-fallback.json'
	) {}

	loadSettings(): any {
		return new Promise((resolve, reject) => {
			const http = resolveForwardRef(this.http);
			http
				.get(this.endpoint)
				.pipe(
					tap((item) => {
						if (!(item && typeof item === 'object' && item !== null)) {
							throw new Error('Valid configuration not received');
						}
					})
				)
				.subscribe(
					(data) => {
						resolve(data);
					},
					() => {
						if (this.fallbackEndpoint !== '') {
							http.get(this.fallbackEndpoint).subscribe(
								(data) => {
									resolve(data);
								},
								() => {
									if (this.staticEndpoint !== '') {
										http.get(this.staticEndpoint).subscribe(
											(data) => {
												resolve(data);
											},
											() => {
												reject('Fallback static Endpoint ' + this.staticEndpoint + ' unreachable!');
											}
										);
									} else {
										reject('Fallback Endpoint ' + this.fallbackEndpoint + ' unreachable!');
									}
								}
							);
						} else {
							reject('Endpoint ' + this.endpoint + ' unreachable!');
						}
					}
				);
		});
	}
}
