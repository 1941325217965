import { inject, Injectable, InjectionToken, Provider } from '@angular/core';
import { forkJoin, from, Observable, of, take, map } from 'rxjs';
import { Routes } from '@angular/router';

export type SsrInitializerFn = () => Observable<unknown> | Promise<unknown> | void;

export const SSR_INITIALIZER = new InjectionToken<ReadonlyArray<SsrInitializerFn>>('SSR_INITIALIZER');

@Injectable({
	providedIn: 'root'
})
export class SsrInitializerManager {
	#initFns = inject(SSR_INITIALIZER, { optional: true }) || [];
	ssrInitStream$ = this.#combineStream();

	#combineStream(): Observable<unknown> {
		const initStreams$ = this.#initFns.map((initFn) => {
			const initLogic = initFn();
			const obsStream$ = initLogic instanceof Promise ? from(initLogic) : initLogic instanceof Observable ? initLogic : of(initLogic);
			return obsStream$.pipe(take(1));
		});
		return forkJoin(initStreams$);
	}
}

export function SsrInitGuard() {
	return inject(SsrInitializerManager, { optional: true }).ssrInitStream$.pipe(map(() => true)) || true;
}

export function configureSsrInitializer(routes: Routes): Routes {
	return [
		{
			path: '',
			canMatch: [SsrInitGuard],
			children: routes
		}
	];
}

export function provideSsrInitializer(initFn: SsrInitializerFn): Provider {
	return {
		provide: SSR_INITIALIZER,
		useFactory: initFn,
		multi: true
	};
}
