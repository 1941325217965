import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, Pipe, PipeTransform, PLATFORM_ID, SimpleChanges, ViewChild } from '@angular/core';
import { AnalyticsService } from '@nx-bundesliga/bundesliga-com/framework/analytics';
import { BUNDESLIGA_COMPETITIONS, BUNDESLIGA_SEASONS, EpgBroadcaster, Match } from '@nx-bundesliga/models';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { combineLatest, from, Observable, of, Subject, Subscription, switchMap } from 'rxjs';
import { catchError, map, mergeMap, startWith, tap, toArray } from 'rxjs/operators';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { SwiperEvents } from 'swiper/types';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { MatchesService } from '@nx-bundesliga/bundesliga-com/services/matches';
import { ThemingService } from '@nx-bundesliga/bundesliga-com/services/theming';
import { EpgService } from '../../../services/epg/epg.service';

@Component({
	selector: 'matchbar',
	templateUrl: './matchbar.component.html',
	styleUrls: ['./matchbar.component.scss']
	// changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatchbarComponent implements OnInit, OnChanges, OnDestroy {
	@Input() competitionId: string = BUNDESLIGA_COMPETITIONS.BUNDESLIGA;
	competitionId$ = new Subject<string>();
	@Input() seasonId: string = BUNDESLIGA_SEASONS.CURRENT;
	seasonId$ = new Subject<string>();
	@Input() matchdayNumber: number;
	matchdayNumber$ = new Subject<number>();
	@Input() language: string;
	language$ = new Subject<string>();
	@Input() mode: 'default' | 'matchcenter' = 'default';
	@Input() enableEpg = true;
	@Input() selectedMatchIds: any = {};

	@ViewChild(SwiperComponent, { static: false }) public swiperComp: SwiperComponent;

	// eslint-disable-next-line
	public type: 'regular' | 'one' | 'two' | 'four' = 'regular';
	public loading = true;
	public seasonRunning = true;
	public matches: Match[] = [];
	public currentMatchIndex = 0;
	public currentMatchdayNumber: number;
	public currentSeasonId: string;
	public epgData: any = null;
	public matchesUnfinished = true;
	public swiperConfig: { [key: string]: SwiperOptions } = {
		regular: {
			observer: true,
			slidesPerView: 'auto',
			centerInsufficientSlides: false
		},
		one: {
			observer: true,
			slidesPerView: 1,
			centerInsufficientSlides: false,
			breakpoints: {}
		},

		two: {
			observer: true,
			slidesPerView: 2,
			centerInsufficientSlides: false,
			breakpoints: {}
		},

		four: {
			observer: true,
			slidesPerView: 4,
			centerInsufficientSlides: false,
			breakpoints: {
				414: { slidesPerView: 2 }
			}
		}
	};

	public isBrowser = false;
	private matchSubscription: Subscription;

	constructor(public themingService: ThemingService, public matchesService: MatchesService, private epgService: EpgService, @Inject(PLATFORM_ID) platformId: Object, private analyticsService: AnalyticsService, private readonly config: ConfigService) {
		this.isBrowser = isPlatformBrowser(platformId);
	}

	ngOnDestroy() {
		if (this.matchSubscription) {
			this.matchSubscription.unsubscribe();
		}
	}

	ngOnInit() {
		if (!this.matchdayNumber) {
			this.matchdayNumber = this.config.getSettings(this.competitionId + '.matchday.matchdayNumber', 1);
		}
		this.fetchData();
		this.competitionId$.next(this.competitionId);
		this.seasonId$.next(this.seasonId);
		this.matchdayNumber$.next(this.matchdayNumber);
		this.language$.next(this.language);
	}

	/**
	 *
	 */
	ngOnChanges(changes: SimpleChanges) {
		if (!this.matchdayNumber) {
			this.matchdayNumber = this.config.getSettings(`${this.competitionId || BUNDESLIGA_COMPETITIONS.BUNDESLIGA}.matchday.matchdayNumber`, 1);
		}
		if (!this.seasonId) {
			this.seasonId = BUNDESLIGA_SEASONS.CURRENT;
		}
		if (this.competitionId) {
			this.currentSeasonId = this.config.getSettings(`${this.competitionId}.season.dflDatalibrarySeasonId`, BUNDESLIGA_SEASONS.CURRENT);
			this.currentMatchdayNumber = this.config.getSettings(`${this.competitionId}.matchday.matchdayNumber`, 1);
			this.seasonRunning = this.config.getSettings(`${this.competitionId}.seasonState`, 'running').toLowerCase() === 'running';
		}
		this.competitionId$.next(this.competitionId);
		this.seasonId$.next(this.seasonId);
		this.matchdayNumber$.next(this.matchdayNumber);
		this.language$.next(this.language);
	}

	/**
	 *
	 */
	private fetchData(): void {
		this.loading = true;
		if (this.matchSubscription) {
			this.matchSubscription.unsubscribe();
		}
		this.matchSubscription = combineLatest([this.competitionId$, this.seasonId$, this.matchdayNumber$, this.language$])
			.pipe(
				tap(() => {
					this.loading = true;
				}),
				switchMap(([competitionId, seasonId, matchdayNumber, language]: [string, string, number, string]) => {
					const seasonState = this.config.getSettings(competitionId + '.seasonState', 'running').toLowerCase();
					return this.mode === 'default' && ['pre', 'final'].includes(seasonState)
						? this.matchesService.getFlatBasicMatchinfosByCompetions(this.config.getSettings(`${this.competitionId}.${seasonState === 'final' ? 'post' : 'pre'}`, []), seasonId)
						: this.matchesService.getBasicMatchinfosByMatchday(language, matchdayNumber, seasonId, competitionId);
				}),
				map((matches: Match[]) => {
					return matches.sort((a, b) => {
						const useSeasonOrder = a.hasOwnProperty('seasonOrder') && b.hasOwnProperty('seasonOrder');
						const aprop = useSeasonOrder ? a.seasonOrder : a.hasOwnProperty('plannedKickOff') ? a.plannedKickOff : a.matchdayRange.end;
						const bprop = useSeasonOrder ? b.seasonOrder : b.hasOwnProperty('plannedKickOff') ? b.plannedKickOff : b.matchdayRange.end;
						return aprop > bprop ? 1 : bprop > aprop ? -1 : 0;
					});
				}),
				switchMap((matches: Match[]) => (this.isBrowser ? combineLatest([of(matches), this.fetchEpgByMatches(matches).pipe(startWith(null))]) : of([matches, null])))
			)
			.subscribe(
				([matches, epgData]: [Match[], any]) => {
					this.matches = matches || [];
					this.epgData = epgData;
					this.loading = false;
					const matchLength = this.matches.length;
					if (matchLength === 1) {
						this.type = 'one';
					} else if (matchLength === 2) {
						this.type = 'two';
					} else if (matchLength > 2 && matchLength < 5) {
						this.type = 'four';
					} else {
						this.type = 'regular';
					}
					this.matchesUnfinished = this.matches.reduce((acc: boolean, cur: Match) => {
						acc = this.matchesService.isLiveMatch(cur) || this.matchesService.matchIsPreMatchState(cur) ? true : acc;
						return acc;
					}, false);

					if (this.type === 'regular') {
						this.slideToActiveMatch();
					}
				},
				(error) => {
					this.matchesUnfinished = false;
					this.loading = false;
				},
				() => {
					this.loading = false;
				}
			);
	}

	private fetchEpgByMatches(matches: Match[]): Observable<any> {
		if (this.isBrowser) {
			const matchCalls = [];
			matches.forEach((match) => {
				matchCalls.push({
					competitionId: match.dflDatalibraryCompetitionId,
					seasonId: match.dflDatalibrarySeasonId,
					matchdayId: match.dflDatalibraryMatchdayId
				});
			});
			const uniq = new Set(matchCalls.map((e) => JSON.stringify(e)));
			const epgCalls = Array.from(uniq).map((e) => JSON.parse(e));
			return from(epgCalls).pipe(
				mergeMap((epgCall) => this.epgService.getEpg(epgCall.competitionId, epgCall.matchdayId).pipe(catchError(() => of({})))),
				toArray(),
				map((items: EpgBroadcaster[][]) =>
					[].concat(...items).reduce((acc, cur) => {
						acc.hasOwnProperty(cur.dflDatalibraryMatchId) ? acc[cur.dflDatalibraryMatchId].push(cur) : (acc[cur.dflDatalibraryMatchId] = [cur]);
						return acc;
					}, {})
				),
				map((epg) => (Object.keys(epg).length > 0 ? epg : {}))
			);
		}
		return of(null);
	}

	/**
	 *
	 */
	public slideToActiveMatch(): void {
		const matchLiveIndex = this.matches.findIndex((match: Match) => match.hasOwnProperty('plannedKickOff') && this.matchesService.isLiveMatch(match));
		/* scroll to first livematch if availible */
		if (this.swiperComp && this.swiperComp.swiperRef && matchLiveIndex >= 0) {
			this.currentMatchIndex = matchLiveIndex;
			this.swiperComp.swiperRef.slideTo(this.currentMatchIndex, undefined, true);
		}
	}

	public handleIndexChange([swiper]: Parameters<SwiperEvents['activeIndexChange']>) {
		this.currentMatchIndex = swiper.activeIndex;
	}

	/**
	 *
	 */
	public onScrollEvent(): void {
		const scrollEventProperties = {
			event: 'interaction',
			category: 'Match Bar',
			action: 'Slide',
			label: 'runEnded'
		};
		this.analyticsService.eventTrack.next(scrollEventProperties);
	}

	/**
	 *
	 * @param index
	 * @param item
	 */
	public trackByMatchId(index, item: Match): string {
		return item.dflDatalibraryMatchId;
	}

	public isActiveMatch(matchId: string): boolean {
		if (this.mode === 'default') {
			return true;
		}

		return this.selectedMatchIds && this.selectedMatchIds.hasOwnProperty(matchId) && this.selectedMatchIds[matchId] === true;
	}
}

@Pipe({
	name: 'matchbarMatchLive',
	pure: true
})
export class MatchbarMatchLivePipe implements PipeTransform {
	constructor(private matchesService: MatchesService) {}
	transform(match: Match): boolean {
		return match && this.matchesService.isLiveMatch(match);
	}
}

@Pipe({
	name: 'matchbarMatchcenterLink',
	pure: true
})
export class MatchbarMatchcenterLinkPipe implements PipeTransform {
	constructor(private matchesService: MatchesService) {}
	transform(match: Match, language): string[] {
		const linkArray = this.matchesService.getMatchCenterRouterLink(match, language);
		linkArray.push('liveticker');
		return linkArray;
	}
}
