import { EditorialHeadlines } from './editorial.model';

interface AssociatedWith {
	uuid: string;
	dflId: string;
}

interface TeaserAbstract {
	teaserAbstractDefault: string;
	teaserAbstractShort?: string;
}

/**
 * Interface for editorial teasers. Used to link to editorial content.
 * @todo I don't think we need to use <MatchClub, MatchPlayer> here and can jut rely on string arrays filled with DFL DL IDs.
 */
export interface EditorialTeaser {
	uuid: string;
	publishedAt: string;
	type: string;
	slug: string;
	teaser: string;
	isVideo?: boolean;
	categories?: string[];

	associatedWith?: {
		clubs?: AssociatedWith[];
		players?: AssociatedWith[];
		matches?: AssociatedWith[];
	};

	headlines: EditorialHeadlines;
	/**
	 * @deprecated only here by mistake, should've been teaserAbstract.
	 */
	editorialAbstract?: string;
	teaserAbstract?: TeaserAbstract;
}

/**
 *
 */
export interface EditorialCustomTile {
	type: 'custom_tile';
	url: string;
	headline: string;
	editorialAbstract: string; // @deprecated only here by mistake, should've been teaserAbstract
	teaser: string;

	target?: '_self' | '_blank';
	relationships?: string[];
	isVideo?: boolean;
}

/**
 *
 */
export interface EditorialEmbedTile {
	type: 'embed_tile';
	embedPlatform: 'youtube' | 'video';
	embedId: string;
	headline: string;
	editorialAbstract: string; // @deprecated only here by mistake, should've been teaserAbstract
	teaser?: string;
	autoplay?: boolean;
	target?: '_self' | '_blank';
	relationships?: string[];
	isVideo?: boolean;
}

/**
 * Extends the model for EditorialTeaser with an empty article, for broken/invalid responses from BundesMaster.
 */
export type EditorialHomeTeaser = EditorialTeaser | EditorialCustomTile | EditorialEmbedTile;

export interface EditorialPages<T> {
	elements: T[];

	totalElementCount: number;
	totalPageCount: number;
	currentPageNumber: number;
	elementsPerPage: number;
	elementsOnPage: number;
}
