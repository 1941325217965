export interface ClubPersons {
	players: {
		'DEFENSE': ClubPersonsPlayer[];
		'MIDFIELD': ClubPersonsPlayer[];
		'ATTACK': ClubPersonsPlayer[];
		'GOALKEEPER': ClubPersonsPlayer[];
	};
	roles: {
		'DL_HEAD_COACH': ClubPersonsPerson[];
		'DL_ASSISTANT_HEAD_COACH': ClubPersonsPerson[];
		'DL_OTHER_OFFICIAL': ClubPersonsPerson[];
	};
}

export interface ClubPlayers {
	[clubId: string]: {
		DEFENSE: ClubPersonsPlayer[];
		MIDFIELD: ClubPersonsPlayer[];
		ATTACK: ClubPersonsPlayer[];
		GOALKEEPER: ClubPersonsPlayer[];
	};
}

export interface PersonNationality {
	'firstNationality': string;
	'secondNationality': string;
	'thirdNationality': string;
	'firstNationalityCode': string;
	'secondNationalityCode': string;
	'thirdNationalityCode': string;
}

// from player detail page
export interface ClubPlayer {
	'id': string;
	'modelType': 'PLAYER';
	'nationality': PersonNationality;
	'names': {
		'full': string;
		'shortName': string;
		'first': string;
		'last': string;
		'alias': string;
		'slugifiedShort': string;
		'slugifiedFull': string;
	};
	'birth': {
		'countryCode'?: string;
		'country'?: string;
		'date': string;
		'place'?: string;
	};
	'bio': {
		'height': {
			'height': number;
			'unit': string;
		};
		'weight': {
			'weight': number;
			'unit': string;
		};
		'sex': 'MALE' | 'FEMALE';
	};
	'externalPersonIds': {
		'dflDatalibraryPersonId': string;
	};
	lastUpdate?: string;
	position?: ClubPlayerPosition;
	role?: ClubPlayerRole;
	shirtNumber?: string;
	'playertext': ClubPlayerVita[];
	'playertextGenerationDate': string;
	'singlePlayerImage': string;
	'apprentice': string;
	'canceled': boolean;
	'club': {
		'externalClubIds': {
			'dflDatalibraryClubId': string;
		};
	};
}

// from squad page, player overview page
export interface ClubPersonsPlayerName {
	'full'?: string;
	'shortName'?: string;
	'first'?: string;
	'last'?: string;
	'alias'?: string;
	'slugifiedShort'?: string;
	'slugifiedFull'?: string;
}

// from squad page, player overview page
export interface ClubPersonsPlayer {
	'id': string;
	'name': ClubPersonsPlayerName;
	'externalPersonIds': {
		'dflDatalibraryPersonId': string;
	};
	shirtNumber?: string;
	nationality: PersonNationality;
	playerImages?: {
		PORTRAIT_XXS?: string;
		PORTRAIT_XS?: string;
		PORTRAIT_S?: string;
		PORTRAIT_M?: string;
		PORTRAIT_L?: string;
		FACE_CIRCLE?: string;
	};
}

export interface ClubPersonsPerson {
	'id': string;
	'name': {
		'full': string;
		'shortName': string;
		'first': string;
		'last': string;
		'alias': string;
		'slugifiedShort': string;
		'slugifiedFull': string;
	};
	'externalPersonIds': {
		'dflDatalibraryPersonId': string;
	};
}

export interface ClubPlayerVita {
	'heading'?: string;
	'paragraphs': string[];
}

export type ClubPlayerPosition = 'COACH' | 'DEFENSE' | 'MIDFIELD' | 'ATTACK' | 'GOALKEEPER';

export type ClubPlayerRole = 'DL_HEAD_COACH' | 'DL_ASSISTANT_HEAD_COACH' | 'DL_OTHER_OFFICIAL';
