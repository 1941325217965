/*
  Courtesy of https://stackoverflow.com/questions/45938931/angular-httpclient-default-and-specific-request-timeout/45986060
  Edited logging and name
 */
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class HttpTimeoutInterceptor implements HttpInterceptor {
	constructor(@Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const timeoutValue = Number(req.headers.get('timeout')) || this.defaultTimeout;

		return next.handle(req).pipe(timeout(timeoutValue));
	}
}

@NgModule({
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpTimeoutInterceptor,
			multi: true
		},
		{ provide: DEFAULT_TIMEOUT, useValue: 60000 }
	]
})
export class HttpTimeoutModule {}
