type EventBarTypes = 'off' | 'custom' | 'next_match';

/**
 *
 */
export interface EventBarConfig {
	// always set
	type: EventBarTypes;
	countdown?: string;
	lastUpdateDateTime?: string;

	// only for type "next_match"
	matches?: EventBarConfigMatch[];

	// only for type "custom"
	textBefore?: string;
	textAfter?: string;
	url?: string;
	target?: string;
	relationships?: string[];
}

/**
 *
 */
interface EventBarConfigMatch {
	competition: {
		name: string;
		dflDatalibraryCompetitionId: string;
	};

	season: {
		name: string;
		dflDatalibrarySeasonId: string;
	};

	matchday: {
		matchdayNumber: number;
		dflDatalibraryMatchdayId: string;
	};

	match: {
		slugs: MatchSlugs;
		dflDatalibraryMatchId: string;
	};
}

/**
 *
 */
export interface MatchSlugs {
	slugLong: string;
	slugShort?: string; // deprecated 2023-10-12
}

export interface Countdown {
	days?: number | string;
	hours: number | string;
	minutes: number | string;
	seconds?: number | string;
}
